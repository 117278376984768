import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from '../../../contexts/AuthContext'
import axios from "axios";
import { Table, Button, Row, Col, ButtonGroup, Input, Label,  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter} from "reactstrap";
import Header from "../../../layout/Header/Header";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel"
import { encode } from "base64-arraybuffer";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { TableHeader, Pagination, Search } from "../../../DataTable"
import useFullPageLoader from "../../../hooks/useFullPageLoader"

function ListadoGastos() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_GASTOS = process.env.REACT_APP_URL_GASTOS;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_PERIODOS_GASTOS = process.env.REACT_APP_URL_PERIODOS_GASTOS;
  const URL_PRESUPUESTOS = process.env.REACT_APP_URL_PRESUPUESTOS;
  const URL_DEPARTAMENTOS = process.env.REACT_APP_URL_DEPARTAMENTOS;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const URL_CICLOS = process.env.REACT_APP_URL_CICLOS;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const [gastos, setGastos] = useState([]);
  const [proveedores, setProveedores] = useState([]);
  const [selectedProveedor, setSelectedProveedor] = useState("");
  const [periodos, setPeriodos] = useState([]);
  const [selectedPeriodo, setSelectedPeriodo] = useState("");
  const [departamentos, setDepartamentos] = useState([]);
  const [selectedDepartamento, setSelectedDepartamento] = useState("");
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");
  const [ciclos, setCiclos] = useState([]);
  const [selectedCiclo, setSelectedCiclo] = useState("");
  const [selectedFechaInicio, setSelectedFechaInicio] = useState("");
  const [selectedFechaFin, setSelectedFechaFin] = useState("");
  const [pagado, setPagado] = useState("");
  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState('');

  const [modalDetalle, setModalDetalle] = useState(false);
  const toggleDetalle = () => setModalDetalle(!modalDetalle);
  const [fechaDetalle, setFechaDetalle] = useState("");
  const [proveedorDetalle, setProveedorDetalle] = useState("");
  const [descripcionDetalle, setDescripcionDetalle] = useState("");
  const [subtotalDetalle, setSubtotalDetalle] = useState("");
  const [ivaDetalle, setIvaDetalle] = useState("");
  const [totalDetalle, setTotalDetalle] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);
  const [idEdit, setIdEdit] = useState();
  const [fecha, setFecha] = useState();
  const [subtotal, setSubtotal] = useState();
  const [iva, setIva] = useState();
  const [descripcion, setDescripcion] = useState("");
  const [totalEdit, setTotalEdit] = useState();
  const [selectedProveedorEdit, setSelectedProveedorEdit] = useState();
  const [presupuestos, setPresupuestos] = useState([]);
  const [selectedPresupuesto, setSelectedPresupuesto] = useState();
  const [nuevoDisponible, setNuevoDisponible] = useState("");
  const [nuevoUtilizado, setNuevoUtilizado] = useState("");
  const [disponible, setDisponible] = useState();
  const [utilizado, setUtilizado] = useState("");


  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const [pagadoAutorizado, setPagadoAutorizado] = useState("");

  const ITEMS_PER_PAGE = 50

  useEffect(() => {
    axios
      .get(URL_GASTOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allGastos = response.data;
        let arrayTabla = allGastos
        .sort((a, b) => (a.fecha < b.fecha ? 1 : -1))
        .map((a) => {
          return {
            _id:a._id,
            fecha:a.fecha,
            total:a.total,
            pagado:a.pagado,
            descripcion:a.descripcion,
            subtotal:a.subtotal,
            iva:a.iva,
            idPresupuesto:a.presupuestos[0]._id,
            is_activePresupuesto:a.presupuestos[0].is_active,
            disponible:a.presupuestos[0].disponible,
            utilizado:a.presupuestos[0].utilizado,
            autorizado:a.presupuestos[0].autorizado,
            importe:a.presupuestos[0].importe,
            proveedor: a.proveedores[0].nombre_comercial + " " + a.proveedores[0].razon_social,
            idProveedor: a.proveedores[0]._id,
            area: a.presupuestos[0].areas[0].name,
            idArea: a.presupuestos[0].areas[0]._id,
            departamento: a.presupuestos[0].departamentos[0].name,
            idDepartamento: a.presupuestos[0].departamentos[0]._id,
            ciclo: a.presupuestos[0].periodosGastos[0].ciclos[0].name,
            idCiclos: a.presupuestos[0].periodosGastos[0].ciclos[0]._id,
            periodoGasto: a.presupuestos[0].periodosGastos[0].name,
            idPeriodosGastos: a.presupuestos[0].periodosGastos[0]._id,
          };
        }).filter(function (el) {
          return el != null;
        });

        let dataFinal = Object.values(arrayTabla);
        setComments(dataFinal)
        setGastos(allGastos);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_PERIODOS_GASTOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allPeriodos = res.data;
        setPeriodos(allPeriodos);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_DEPARTAMENTOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allDepartamentos = res.data;
        setDepartamentos(allDepartamentos);
      })
      .catch((err) => {
        console.log(err);
      });
  
    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_PROVEEDORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allProveedores = res.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_CICLOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allCiclos = res.data;
        setCiclos(allCiclos);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_PRESUPUESTOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allPresupuestos = res.data;
        setPresupuestos(allPresupuestos);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  let total = 0;

  let totalTabla = 0

  function PDFTabla() {
    const logo = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    total = 0;
    const data = gastos.map((c) => {
      if (
        ( user.areas == AREAS_GENERAL) &&

        (selectedDepartamento == 0 ||
          selectedDepartamento ==
            c.presupuestos[0].departamentos[0]._id) &&
        (selectedProveedor == 0 ||
          selectedProveedor == c.proveedores[0]._id) &&
        (selectedArea == 0 ||
          selectedArea == c.presupuestos[0].areas[0]._id) &&
        (selectedPeriodo == 0 ||
          selectedPeriodo ==
            c.presupuestos[0].periodosGastos[0]._id) &&
        (selectedFechaInicio == 0 ||
          selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 ||
          selectedFechaFin >= c.fecha) &&
        (selectedCiclo == 0 || selectedCiclo == c.presupuestos[0].periodosGastos[0].ciclos[0]._id)
        && (pagadoAutorizado == 0 || pagadoAutorizado == c.pagado)
      ) {
        total = total + c.total;
        const importePDF = "$" + new Intl.NumberFormat("en-US").format(c.total);
        return [
          c.fecha,
          c.proveedores[0].nombre_comercial + " " + c.proveedores[0].razon_social,
          c.presupuestos[0].areas[0].name,
          c.presupuestos[0].departamentos[0].name,
          c.presupuestos[0].periodosGastos[0].name,
          importePDF,
          c.pagado
        ];
      } else if(
        ( c.presupuestos[0].areas[0]._id == user.areas) &&
        (selectedDepartamento == 0 ||
          selectedDepartamento ==
            c.presupuestos[0].departamentos[0]._id) &&
        (selectedProveedor == 0 ||
          selectedProveedor == c.proveedores[0]._id) &&
        (selectedPeriodo == 0 ||
          selectedPeriodo ==
            c.presupuestos[0].periodosGastos[0]._id) &&
        (selectedFechaInicio == 0 ||
          selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 ||
          selectedFechaFin >= c.fecha) &&
        (selectedCiclo == 0 || selectedCiclo == c.presupuestos[0].periodosGastos[0].ciclos[0]._id)
        && (pagadoAutorizado == 0 || pagadoAutorizado == c.pagado)
      ) {
        total = total + c.total;
        const importePDF = "$" + new Intl.NumberFormat("en-US").format(c.total);
        return [
          c.fecha,
          c.proveedores[0].nombre_comercial + " " + c.proveedores[0].razon_social,
          c.presupuestos[0].areas[0].name,
          c.presupuestos[0].departamentos[0].name,
          c.presupuestos[0].periodosGastos[0].name,
          importePDF,
          c.pagado
        ];
      }
    })
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF('landscape');
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 15, 45, 15);
    doc.text("Detalle de Gastos", 20, 25);
    doc.autoTable({
      head: [
        [
          "Fecha",
          "Proveedor",
          "Area",
          "Departamento",
          "Periodo",
          "Importe",
          "Pagado"
        ],
      ],
      body: dataPDFLimpia,
      startY: 30,
      foot: [
        [
          "",
          "",
          "",
          "",
          "Total",
          "$" + new Intl.NumberFormat("en-US").format(total),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save("DetalleGastos.pdf");
  }

  function excel(){
    const dataExcel = gastos.map((c) => {
      if (
        ( user.areas == AREAS_GENERAL) &&
       
        (selectedDepartamento == 0 ||
          selectedDepartamento ==
            c.presupuestos[0].departamentos[0]._id) &&
        (selectedProveedor == 0 ||
          selectedProveedor == c.proveedores[0]._id) &&
        (selectedArea == 0 ||
          selectedArea == c.presupuestos[0].areas[0]._id) &&
        (selectedPeriodo == 0 ||
          selectedPeriodo ==
            c.presupuestos[0].periodosGastos[0]._id) &&
        (selectedFechaInicio == 0 ||
          selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 ||
          selectedFechaFin >= c.fecha) &&
        (selectedCiclo == 0 || selectedCiclo == c.presupuestos[0].periodosGastos[0].ciclos[0]._id)
        && (pagadoAutorizado == 0 || pagadoAutorizado == c.pagado)
      ) {
      return { 
        Fecha: c.fecha,
        Proveedor: c.proveedores[0].nombre_comercial + " " + c.proveedores[0].razon_social,
        Area: c.presupuestos[0].areas[0].name,
        Departamento: c.presupuestos[0].departamentos[0].name,
        Periodo: c.presupuestos[0].periodosGastos[0].name,
        Importe: c.total,
        Pagado: c.pagado
      }
    } else if(
      ( c.presupuestos[0].areas[0]._id == user.areas) &&
      (selectedDepartamento == 0 ||
        selectedDepartamento ==
          c.presupuestos[0].departamentos[0]._id) &&
      (selectedProveedor == 0 ||
        selectedProveedor == c.proveedores[0]._id) &&
      (selectedPeriodo == 0 ||
        selectedPeriodo ==
          c.presupuestos[0].periodosGastos[0]._id) &&
      (selectedFechaInicio == 0 ||
        selectedFechaInicio <= c.fecha) &&
      (selectedFechaFin == 0 ||
        selectedFechaFin >= c.fecha) &&
      (selectedCiclo == 0 || selectedCiclo == c.presupuestos[0].periodosGastos[0].ciclos[0]._id)
      && (pagadoAutorizado == 0 || pagadoAutorizado == c.pagado)
    ){
      return { 
        Fecha: c.fecha,
        Proveedor: c.proveedores[0].nombre_comercial + " " + c.proveedores[0].razon_social,
        Area: c.presupuestos[0].areas[0].name,
        Departamento: c.presupuestos[0].departamentos[0].name,
        Periodo: c.presupuestos[0].periodosGastos[0].name,
        Importe: c.total,
        Pagado: c.pagado
      }
    }
  })
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};
 
option.fileName = `DetalleGastos-${selectedFechaInicio}-${selectedFechaFin}`;
 
option.datas = [
  {
    sheetData: dataExcelLimpia,
    sheetName: `DetalleGastos`,
    sheetFilter: [
          "Fecha",
          "Proveedor",
          "Area",
          "Departamento",
          "Periodo",
          "Importe",
          "Pagado"
        ],
    sheetHeader: [
          "Fecha",
          "Proveedor",
          "Area",
          "Departamento",
          "Periodo",
          "Importe",
          "Pagado"
        ],
  },
];
 
var toExcel = new ExportJsonExcel(option); 
toExcel.saveExcel(); 
  }

function enviaMail(){
  const logo = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    total = 0;
    const data = gastos.map((c) => {
      if (
        ( user.areas == AREAS_GENERAL) &&
        (selectedDepartamento == 0 ||
          selectedDepartamento ==
            c.presupuestos[0].departamentos[0]._id) &&
        (selectedProveedor == 0 ||
          selectedProveedor == c.proveedores[0]._id) &&
        (selectedArea == 0 ||
          selectedArea == c.presupuestos[0].areas[0]._id) &&
        (selectedPeriodo == 0 ||
          selectedPeriodo ==
            c.presupuestos[0].periodosGastos[0]._id) &&
        (selectedFechaInicio == 0 ||
          selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 ||
          selectedFechaFin >= c.fecha) &&
        (selectedCiclo == 0 || selectedCiclo == c.presupuestos[0].periodosGastos[0].ciclos[0]._id)
        && (pagadoAutorizado == 0 || pagadoAutorizado == c.pagado)
      ) {
        total = total + c.total;
        const importePDF = "$" + new Intl.NumberFormat("en-US").format(c.total);
        return [
          c.fecha,
          c.proveedores[0].nombre_comercial + " " + c.proveedores[0].razon_social,
          c.presupuestos[0].areas[0].name,
          c.presupuestos[0].departamentos[0].name,
          c.presupuestos[0].periodosGastos[0].name,
          importePDF,
          c.pagado
        ];
      } else if(
        ( c.presupuestos[0].areas[0]._id == user.areas) &&
        (selectedDepartamento == 0 ||
          selectedDepartamento ==
            c.presupuestos[0].departamentos[0]._id) &&
        (selectedProveedor == 0 ||
          selectedProveedor == c.proveedores[0]._id) &&
        (selectedPeriodo == 0 ||
          selectedPeriodo ==
            c.presupuestos[0].periodosGastos[0]._id) &&
        (selectedFechaInicio == 0 ||
          selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 ||
          selectedFechaFin >= c.fecha) &&
        (selectedCiclo == 0 || selectedCiclo == c.presupuestos[0].periodosGastos[0].ciclos[0]._id)
        && (pagadoAutorizado == 0 || pagadoAutorizado == c.pagado)
      ) {
        total = total + c.total;
        const importePDF = "$" + new Intl.NumberFormat("en-US").format(c.total);
        return [
          c.fecha,
          c.proveedores[0].nombre_comercial + " " + c.proveedores[0].razon_social,
          c.presupuestos[0].areas[0].name,
          c.presupuestos[0].departamentos[0].name,
          c.presupuestos[0].periodosGastos[0].name,
          importePDF,
          c.pagado
        ];
      }
    })
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF('landscape');
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 15, 45, 15);
    doc.text("Detalle de Gastos", 20, 25);
    doc.autoTable({
      head: [
        [
          "Fecha",
          "Proveedor",
          "Area",
          "Departamento",
          "Periodo",
          "Importe",
          "Pagado"
        ],
      ],
      body: dataPDFLimpia,
      startY: 30,
      foot: [
        [
          "",
          "",
          "",
          "",
          "Total",
          "$" + new Intl.NumberFormat("en-US").format(total),
        ],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output('arraybuffer');
    var base64File = encode.encode(att, 'base64')

     // Envia el Mail
     axios
     .post(
       URL_SEND_MAIL_ATT,
       {
         subject:'Lista de Gastos',
         email: mailTo,
         fileName: 'ListaGastos.pdf',
         att: base64File,
         text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras la lista de Gastos.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`
       },
       {
         headers: {
           Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
         },
       }
     )
     .then(() => {
      toggleMail()
       Swal.fire("Good job!", "Enviado con exito", "success");
     })
     .catch((error) => {
       Swal.fire({
         icon: "error",
         title: "Oops...",
         text: "Something went wrong!",
         footer: `${error.response.data}`,
       });
       console.log(error);
     })
}

function jalaDetalle(fecha, proveedor, descripcion, subtotal, iva, total){
  setFechaDetalle(fecha)
  setProveedorDetalle(proveedor)
  setDescripcionDetalle(descripcion)
  setSubtotalDetalle(subtotal)
  setIvaDetalle(iva)
  setTotalDetalle(total)
  toggleDetalle()
}

const clearSaldos = () => {
  setDisponible("");
  setUtilizado("");
  setNuevoDisponible("");
  setNuevoUtilizado("");
};

const botonSeguir = (e) => {
  setSelectedPresupuesto(e.target.value);
  presupuestos.map((c) => {
    if (selectedPresupuesto == c._id) {
      setDisponible(c.disponible);
      setUtilizado(c.utilizado);
    }
  });
};


const calcula = (e) => {
  setSubtotal(e.target.value);
  setTotalEdit(parseFloat(subtotal) + parseFloat(iva))
};

const calculaIVA = (e) => {
  setIva(e.target.value);
  setTotalEdit(parseFloat(subtotal) + parseFloat(iva))
};

function jalaEdit(idEdit, fecha, presupuesto, proveedor, descripcion, subtotal, iva, total, disponible, utilizado){
setIdEdit(idEdit)
setFecha(fecha)
setSelectedPresupuesto(presupuesto)
setSelectedProveedorEdit(proveedor)
setDescripcion(descripcion)
setSubtotal(subtotal)
setIva(iva)
setTotalEdit(total)
setDisponible(disponible)
setUtilizado(utilizado)
toggleEdit()
}



const editGasto = (event) => {
  const URL_GASTOS_EDIT = `${process.env.REACT_APP_URL_GASTOS}/${idEdit}`;
  event.preventDefault();
  if (disponible >= totalEdit) {
    axios
      .all([
        axios.patch(
          URL_GASTOS_EDIT,
          {
            fecha,
            subtotal,
            iva,
            total:totalEdit,
            descripcion,
            proveedores: selectedProveedorEdit,
            presupuestos: selectedPresupuesto,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        ),
      ])
    .then(() => {
      axios
      .post(
        URL_LOGS,
        {
          tipo:'Edit Gasto',
          detalle: `Fecha: ${fecha} / Descripcion: ${descripcion} / Proveedor: ${selectedProveedorEdit} / Presupuesto: ${selectedPresupuesto}`,
          user: user.id
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      toggleEdit();
      Swal.fire("Good job!", "Actualizado con exito", "success");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    })
     .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    });
} else {
  clearSaldos();
  Swal.fire({
    icon: "error",
    title: "Oops...",
    text: "El importe excede el presupuesto establecido!",
  });
}
};

function autorizarGasto(_id, disponible1, total, is_active, autorizado, idPresupuesto, periodo, departamento, fechaAutorizado) {
  const URL_AUTORIZAR = `${process.env.REACT_APP_URL_GASTOS}/${_id}`;
  const URL_PRE=`${process.env.REACT_APP_URL_PRESUPUESTOS}/${idPresupuesto}`
  if(is_active == "Si" && autorizado =="Si"){
  if (disponible1 >= total) {
    Swal.fire({
      title: "Estas seguro?",
      text: "No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, quiero Autorizar!",
    }).then((result) => {
      if (result.isConfirmed) {
            // Jalo el disponible y utilizado del presupuesto
            axios
            .get(URL_PRE, {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            })
            .then((res) => {
              const presupuestoAutorizar = res.data;
              let utilizado = res.data.utilizado
              let disponible2 = res.data.disponible
              
              let NU = parseFloat(utilizado) + parseFloat(total)
              let ND = parseFloat(disponible2) - parseFloat(total)

                      axios
          .all([
              axios.patch(
                URL_AUTORIZAR,
                {
                  pagado: "Si",
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              ),
              axios.patch(
                URL_PRE,
                {
                  utilizado: NU,
                  disponible: ND,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )  
            ])
              .then(() => {
                axios
                .post(
                  URL_LOGS,
                  {
                    tipo:'Autorizo Gasto',
                    detalle: `Gasto: ${_id} / Periodo:${periodo} / Departamento:${departamento} / Fecha:${fechaAutorizado} / Total:${total}`,
                    user: user.id
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                    },
                  }
                )
                  Swal.fire("Good job!", "Creado con exito", "success");
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                }
              )
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                });
              });
          
            })
            .catch((err) => {
              console.log(err);
            });

          //then de confirmed

      } //cierra el if de confirmado
    }); // cierra el then 
  } //Cierra el if 
  else {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "El importe excede el presupuesto establecido!",
    });
  } //cierra el else
}else{
  Swal.fire({
    icon: "error",
    title: "Oops...",
    text: "El Presupuesto no esta autorizado",
  });
}
} //cierra la funcion


const headers = [
  { name: "Fecha", field: "fecha", sortable: false },
  { name: "Proveedor", field: "proveedor", sortable: true },
  { name: "Area", field: "area", sortable: true },
  { name: "Departamento", field: "departamento", sortable: true },
  { name: "Ciclo", field: "ciclo", sortable: true },
  { name: "Periodo", field: "periodo", sortable: false },
  { name: "Importe", field: "importe", sortable: false },
  { name: "Pagado", field: "pagado", sortable: true },
  { name: "Acciones", field: "acciones", sortable: false },
];

const commentsData = useMemo(() => {
  let computedComments = comments

  if (search) {
      computedComments = computedComments.filter(
          comment =>
              comment.proveedor.toLowerCase().includes(search.toLowerCase()) ||
              comment.area.toLowerCase().includes(search.toLowerCase())||
              comment.departamento.toLowerCase().includes(search.toLowerCase()) ||
              comment.ciclo.toLowerCase().includes(search.toLowerCase()) ||
              comment.pagado.toLowerCase().includes(search.toLowerCase())
      );
  }



  if(user.areas != AREAS_GENERAL){
    computedComments = computedComments.filter((e)=>
    e.idArea.includes(user.areas)
    ) 
  } 
  


  if(selectedArea){
    computedComments = computedComments.filter((e)=>
    e.idArea.includes(selectedArea)
    ) 
  }
  if(selectedProveedor){
    computedComments = computedComments.filter((e)=>
    e.idProveedor.includes(selectedProveedor)
    ) 
  } 

  if(selectedDepartamento){
    computedComments = computedComments.filter((e)=>
    e.idDepartamento.includes(selectedDepartamento)
    ) 
  }
  if(selectedCiclo){
    computedComments = computedComments.filter((e)=>
    e.idCiclos.includes(selectedCiclo)
    ) 
  } 

  if(selectedPeriodo){
    computedComments = computedComments.filter((e)=>
    e.idPeriodosGastos.includes(selectedPeriodo)
    ) 
  }

  if(pagadoAutorizado){
    computedComments = computedComments.filter((e)=>
    e.pagado.includes(pagadoAutorizado)
    ) 
  }

  if(selectedFechaInicio){
    computedComments = computedComments.filter((e)=>
    e.fecha >= selectedFechaInicio
    ) 
  }

  if(selectedFechaFin){
    computedComments = computedComments.filter((e)=>
    e.fecha <= selectedFechaFin
    ) 
  }

  setTotalItems(computedComments.length);

  //Sorting comments
  if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
          (a, b) =>
              reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
  }

  //Current Page slice
  return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
  );
}, [comments, currentPage, search, sorting, selectedArea, selectedProveedor, selectedDepartamento, selectedCiclo, selectedPeriodo, pagadoAutorizado, selectedFechaInicio, selectedFechaFin]);

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_gastos  ?(
        <div className="row">
            <div className="col-md-12">
              <div className="card style={{ width: 'auto' }}">
                <div className="card-body">
                  <Row>
                    <Col md={7}>
                    {user.gastos_create  ?(
                      <Button
                      size="sm"
                        href="/GastosCreate"
                        className="btn btn-success"
                        id="botonListado"
                      >
                        Crear Gasto
                      </Button>
                    ):(
                      <Button
                      size="sm"
                        disabled
                        className="btn btn-success"
                        id="botonListado"
                      >
                        Crear Gasto
                      </Button>
                    )}
                      <Button
                      size="sm"
                        href="/MenuGastos"
                        className="btn btn-danger"
                        id="botonListado"
                      >
                        Regresar
                      </Button>
                    </Col>
                    <Col md={5}>
                      <ButtonGroup id="logoutBoton">
                        <Button
                          size="sm"
                          className="btn"
                          color="danger"
                          onClick={PDFTabla}
                        >
                          PDF <i class="far fa-file-pdf"></i>
                        </Button>
                        <Button size="sm" 
                        className="btn" 
                        color="info"
                        onClick={toggleMail}>
                          eMail <i class="fas fa-at"></i>
                        </Button>
                        <Button
                          size="sm"
                          className="btn"
                          color="primary"
                          onClick={excel}
                        >
                          Excel <i class="far fa-file-excel"></i>
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                  <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
                      <ModalHeader toggle={toggleMail}><h4>Enviar Lista de Gastos</h4></ModalHeader>
                      <ModalBody>
                        <Label className="mr-sm-2">Email</Label>
                        <Input
                          className="col-sm-12"
                          type="text"
                          value={mailTo}
                          required
                          onChange={(e) => {
                            setMailTo(e.target.value);
                          }}
                        />
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          size="sm"
                          color="success"
                          onClick={enviaMail}
                        >
                          Enviar
                        </Button>
                      </ModalFooter>
                    </Modal>
                  <br />
                  <h3 align="center">Gastos</h3>
                  <Row>
                    <Col md={2}>
                      <Label>Fecha Inicio</Label>
                      <Input
                        bsSize="sm"
                        type="date"
                        value={selectedFechaInicio}
                        onChange={(e) => {
                          setSelectedFechaInicio(e.target.value);
                        }}
                      />
                    </Col>
                    <Col md={2}>
                      <Label>Fecha Fin</Label>
                      <Input
                        bsSize="sm"
                        type="date"
                        value={selectedFechaFin}
                        onChange={(e) => {
                          setSelectedFechaFin(e.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                  <br/>
                  <div className="row">
                        <div className="col-md-6">
                            <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
                        <div className="col-md-6 d-flex flex-row-reverse">
                            <Search
                                onSearch={value => {
                                    setSearch(value);
                                    setCurrentPage(1);
                                }}
                            />
                        </div>
                    </div>
            <Table size="sm" striped borderless className="table-responsive-xl">
                <TableHeader
                            headers={headers}
                            onSorting={(field, order) =>
                                setSorting({ field, order })
                            }
                        />
                <tr>
                        <td></td>
                        <td style={{ paddingTop: "0px" }}>
                          <Input
                            bsSize="sm"
                            type="select"
                            value={selectedProveedor}
                            required
                            onChange={(e) =>
                              {setSelectedProveedor(e.target.value)
                              setCurrentPage(1)}
                            }
                          >
                            <option value="">Selecciona</option>
                            {proveedores
                              .sort((a, b) => (a.nombre_comercial > b.nombre_comercial ? 1 : -1))
                              .map((a) => {
                                return <option value={a._id}>{a.nombre_comercial} {a.razon_social}</option>;
                              })}
                          </Input>
                        </td>
                      
                  {user.areas == AREAS_GENERAL ? (
                    <td style={{ paddingTop: "0px" }}>
                      <Input
                        bsSize="sm"
                        type="select"
                        value={selectedArea}
                        onChange={(e) => {setSelectedArea(e.target.value)
                          setCurrentPage(1)}}
                      >
                        <option value="">Selecciona</option>
                        {areas
                          .sort((a, b) => a.name - b.name)
                          .map((a) => {
                            if (a._id != AREAS_GENERAL) {
                              return <option value={a._id}>{a.name}</option>;
                            }
                          })}
                      </Input>
                    </td>
                  ) : (
                    <td></td>
                  )}
                        <td style={{ paddingTop: "0px" }}>
                          <Input
                            bsSize="sm"
                            type="select"
                            value={selectedDepartamento}
                            required
                            onChange={(e) => {
                              setSelectedDepartamento(e.target.value)
                              setCurrentPage(1)
                            }}
                          >
                            <option value="">Selecciona</option>
                            {departamentos
                              .sort((a, b) => (a.name > b.name ? 1 : -1))
                              .map((a) => {
                                return <option value={a._id}>{a.name}</option>;
                              })}
                          </Input>
                        </td>
                        <td style={{ paddingTop: "0px" }}>
                          <Input
                            bsSize="sm"
                            type="select"
                            value={selectedCiclo}
                            required
                            onChange={(e) => {
                              setSelectedCiclo(e.target.value)
                              setCurrentPage(1)
                            }}
                          >
                            <option value="">Selecciona</option>
                            {ciclos
                              .sort((a, b) => (a.name < b.name ? 1 : -1))
                              .map((a) => {
                                return <option value={a._id}>{a.name}</option>;
                              })}
                          </Input>
                        </td>
                        <td style={{ paddingTop: "0px" }}>
                          <Input
                            bsSize="sm"
                            type="select"
                            value={selectedPeriodo}
                            required
                            onChange={(e) => {
                              setSelectedPeriodo(e.target.value)
                              setCurrentPage(1)
                            }}
                          >
                            <option value="">Selecciona</option>
                            {periodos
                              .sort((a, b) => (a.fecha_inicio < b.fecha_inicio ? 1 : -1))
                              .map((a) => {
                                return <option value={a._id}>{a.name}</option>;
                              })}
                          </Input>
                        </td>
                        <td></td>
                        <td style={{ paddingTop: "0px" }}>
                          <Input
                            bsSize="sm"
                            type="select"
                            value={pagadoAutorizado}
                            onChange={(e) => {
                              setPagadoAutorizado(e.target.value)
                              setCurrentPage(1)
                            }}
                          >
                            <option value="">Selecciona</option>
                            <option value="Si">Si</option>
                            <option value="No">No</option>
                          </Input>
                        </td>
                        <td style={{ paddingTop: "0px" }}></td>
                      </tr>
                    <tbody>
                      {commentsData
                        .map((c) => {
                        
                            totalTabla = totalTabla + c.total;
                            return (
                              <tr>
                                 <td>{c.fecha}</td>
                                  <td>{c.proveedor}</td>
                                  <td>{c.area}</td>
                                  <td>
                                    {c.departamento} 
                                    </td>
                                    <td>
                                    {c.ciclo}
                                  </td>
                                    <td>
                                    {c.periodoGasto}
                                  </td>
                                  <td align="center">
                                    {"$" + new Intl.NumberFormat("en-US").format(c.total)}
                                  </td>
                                  <td align="center">{c.pagado}</td>
                                  <td align="center">
                                     {user.gastos_autorizar && c.pagado == "No"  ?(
                                          <Button color="primary" size="sm" id="Autorizar" onClick={(e)=>autorizarGasto(c._id, c.disponible, c.total, c.is_activePresupuesto, c.autorizado, c.idPresupuesto, c.periodoGasto, c.departamento, c.fecha)}>
                                            <i class="fas fa-dollar-sign"></i>
                                          </Button>
                                          ):undefined}
                                     <Button
                                        color="dany"
                                        size="sm"
                                        onClick={(e)=>jalaDetalle(c.fecha, c.proveedor, c.descripcion, c.subtotal, c.iva, c.total)}
                                        id="Detalle"
                                      >
                                        <i class="fas fa-file-alt"></i>
                                      </Button>

                                      {c.pagado == "No" ? (
                                          <span>
                                            {user.gastos_create  ?(
                                                <Button color="info" id="Editar" size="sm" onClick={(e)=>jalaEdit(c._id, c.fecha, c.idPresupuesto, c.idProveedores, c.descripcion, c.subtotal, c.iva, c.total, c.disponible, c.utilizado)}>
                                            <i class="far fa-edit"></i>
                                          </Button>
                                            ):(
                                              <Button color="info" id="Editar" size="sm" disabled>
                                            <i class="far fa-edit"></i>
                                          </Button>
                                            )}
                                          </span>
                                        ) : undefined}
                                    </td>
                              </tr>
                            );
                          
                        })
                        }
                        <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="negrita" align="center">
                          TOTAL
                        </td>
                        <td className="negrita">
                          {"$" + new Intl.NumberFormat("en-US").format(totalTabla)}
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </Table>
                  <div className="col-md-6">
                            <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
                </div>
              </div>
            </div>
            <Modal size="sm" isOpen={modalDetalle} toggle={toggleDetalle}>
          <ModalHeader toggle={toggleDetalle}>Descripción del Gasto</ModalHeader>
          <ModalBody>
          <div className="content">
        <div className="card container">
           <Table size="sm" borderless>
             <tbody>
             <tr><td>Fecha</td><td>{fechaDetalle}</td></tr>
             <tr><td>Proveedor</td><td>{proveedorDetalle}</td></tr>
             <tr><td>Descripcion</td><td>{descripcionDetalle}</td></tr>
             <tr><td>Subtotal</td><td>{"$" + new Intl.NumberFormat("en-US").format(subtotalDetalle)}</td></tr>       
             <tr><td>IVA</td><td>{"$" + new Intl.NumberFormat("en-US").format(ivaDetalle)}</td></tr>       
             <tr><td>Total</td><td>{"$" + new Intl.NumberFormat("en-US").format(totalDetalle)}</td></tr>     
             
            </tbody> 
                </Table>
            </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal size="lg" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>
          Editar Gasto
        </ModalHeader>
        <ModalBody>
              <Label for="exampleEmail" className="mr-sm-2">
                Fecha
              </Label>
              <Input
                type="date"
                placeholder="Fecha"
                value={fecha}
                required
                onChange={(e) => {
                  setFecha(e.target.value);
                }}
              />
              {user.areas == AREAS_GENERAL && user.areas == AREAS_GENERAL ? (
                <div>
              <Label className="mr-sm-2">Presupuesto</Label>
              <Input
                type="select"
                value={selectedPresupuesto}
                required
                onBlur={botonSeguir}
                onChange={botonSeguir}
              >
                <option value="0">Selecciona un Presupuesto</option>
                {presupuestos
                  // .sort((a, b) => a.name > b.name ? 1 : -1)
                  .map((c) => {
                    return (
                      <option value={c._id}>
                        {c.periodosGastos[0].name} {c.areas[0].name}{" "}
                        {c.departamentos[0].name}
                      </option>
                    );
                  })}
              </Input>
              </div>
              ) : (
                <div>
                  <Label className="mr-sm-2">Presupuesto</Label>
                  <Input
                    type="select"
                    value={selectedPresupuesto}
                    onChange={(e) => setSelectedPresupuesto(e.target.value)}
                  >
                    <option value="0">Selecciona un Presupuesto </option>
                    {presupuestos
                      .sort((a, b) => a.name - b.name)
                      .map((c) => {
                        if (c.areas[0]._id == user.areas) {
                          return (
                            <option value={c._id}>
                              {c.periodosGastos[0].name} {c.areas[0].name}{" "}
                              {c.departamentos[0].name}
                            </option>
                          );
                        }
                      })}
                  </Input>
                </div>
              )}
              <Label className="mr-sm-2">Proveedores</Label>
              <Input
                type="select"
                value={selectedProveedorEdit}
                required
                onChange={(e) => {
                  setSelectedProveedorEdit(e.target.value);
                }}
              >
                <option value="0">Selecciona un Proveedor</option>
                {proveedores
                  .sort((a, b) => a.name > b.name ? 1 : -1)
                  .map((a) => {
                    return (
                      <option value={a._id}>
                        {a.nombre_comercial} {a.razon_social}
                      </option>
                    );
                  })}
              </Input>

              <Label for="exampleEmail" className="mr-sm-2">
                Descripcion
              </Label>
              <Input
                type="textarea"
                placeholder="Descripcion"
                value={descripcion}
                required
                onChange={(e) => {
                  setDescripcion(e.target.value);
                }}
              />
              <Label for="exampleEmail" className="mr-sm-2">
                Subtotal
              </Label>
              <Input
                type="number"
                placeholder="Subtotal"
                value={subtotal}
                required
                onBlur={calcula}
                onChange={calcula}
              />

              <Label for="exampleEmail" className="mr-sm-2">
                IVA
              </Label>
              <Input
                type="number"
                placeholder="IVA"
                value={iva}
                required
                onBlur={calculaIVA}
                onChange={calculaIVA}
              />
              <Label for="exampleEmail" className="mr-sm-2">
                Total
              </Label>
              <Input
                type="number"
                placeholder={totalEdit}
                disabled
              />
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editGasto}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>
        </div>
      ): undefined } 
      {loader}
    </>
  );
}

export default ListadoGastos;
