import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useLayoutEffect,
  useMemo,
} from "react";
import { AuthContext } from "../../contexts/AuthContext";
import axios from "axios";
import Header from "../../layout/Header/Header";

import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Form,
  Card,
  CardHeader,
  CardText,
  CardBody,
} from "reactstrap";
import moment from "moment";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import * as am5percent from "@amcharts/amcharts5/percent";

function DashboardCompras() {
  const { user } = useContext(AuthContext);
  const URL_DASHBOARDS = process.env.REACT_APP_URL_DASHBOARDS;

  let hoy = new Date();
  let inicio = new Date();
  inicio.setDate(inicio.getDate() - 90);

  let endDate = moment(hoy).format("YYYY-MM-DD");
  let startDate = moment(inicio).format("YYYY-MM-DD");


  const [info, setInfo] = useState([]);
  const [graficasColores, setGraficasColores] = useState([]);
  const [graficasArticulos, setGraficasArticulos] = useState([]);
  const [graficasTiposTelas, setGraficasTiposTelas] = useState([]);
  const [graficasMeses, setGraficasMeses] = useState([]);
  const [graficasProveedores, setGraficasProveedores] = useState([]);
  const [valorComprado, setValorComprado] = useState(0);
  const [cantidadComprado, setcantidadComprado] = useState(0);

  const [colores, setColores] = useState([]);
  const [articulos, setArticulos] = useState([]);
  const [tiposTelas, setTiposTelas] = useState([]);
  const [meses, setMeses] = useState([]);
  const [proveedores, setProveedores] = useState([]);
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedArticulo, setSelectedArticulo] = useState("");
  const [selectedTiposTela, setSelectedTiposTela] = useState("");
  const [selectedMes, setSelectedMes] = useState("");
  const [selectedProveedor, setSelectedProveedor] = useState("");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  const [cargando, setCargando] = useState(true);

  const Toast = Swal.mixin({
    toast: true,
    position: "center",
    showConfirmButton: false,
    // timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  if (cargando) {
    Toast.fire({
      icon: "success",
      title: "Danos unos segundos....",
    });
  }

useEffect(()=>{
  axios
  .get(`${URL_DASHBOARDS}Compras/${selectedFechaInicio}/${selectedFechaFin}`, {
    headers: {
      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    },
  })
  .then((response) => {
    let allDisponible = response.data;

      setInfo(allDisponible)

      let unicosCol = allDisponible.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.idColor === value.idColor)
      );
      setColores(unicosCol);

      let unicosArt = allDisponible.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.idArticulo === value.idArticulo)
      );
      setArticulos(unicosArt);

      let unicosLin = allDisponible.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.idTiposTela === value.idTiposTela)
      );
      setTiposTelas(unicosLin);

      let unicosProv = allDisponible.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.idProveedor === value.idProveedor)
      );
      setProveedores(unicosProv);

      let unicosMes = allDisponible.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.Mes === value.Mes)
      );
      setMeses(unicosMes);
      
      setCargando(false);
      Toast.close();
    })
    .catch((err) => {
      console.log(err);
    });

},[])


function BuscaInfo(){
  setInfo([])
  setSelectedColor("")
  setSelectedTiposTela("")
  setSelectedMes("")
  setSelectedArticulo("")
  setSelectedProveedor("")
  setCargando(true)
  axios
  .get(`${URL_DASHBOARDS}Compras/${selectedFechaInicio}/${selectedFechaFin}`, {
    headers: {
      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    },
  })
  .then((response) => {
    let allDisponible = response.data;

      setInfo(allDisponible)

      let unicosCol = allDisponible.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.idColor === value.idColor)
      );
      setColores(unicosCol);

      let unicosArt = allDisponible.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.idArticulo === value.idArticulo)
      );
      setArticulos(unicosArt);

      let unicosLin = allDisponible.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.idTiposTela === value.idTiposTela)
      );
      setTiposTelas(unicosLin);

      let unicosProv = allDisponible.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.idProveedor === value.idProveedor)
      );
      setProveedores(unicosProv);

      let unicosMes = allDisponible.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.Mes === value.Mes)
      );
      setMeses(unicosMes);
      
      setCargando(false);
      Toast.close();
    })
    .catch((err) => {
      console.log(err);
    });
}

  useMemo(() => {
      let temp = info

          if (selectedMes) {
            temp = temp.filter((e) =>
              e.Mes.includes(selectedMes)
            );
          }
          if (selectedColor) {
            temp = temp.filter((e) =>
              e.idColor.includes(selectedColor)
            );
          }
          if (selectedTiposTela) {
            temp = temp.filter((e) =>
              e.idTiposTela.includes(selectedTiposTela)
            );
          }
          if (selectedProveedor) {
            temp = temp.filter((e) =>
              e.idProveedor.includes(selectedProveedor)
            );
          }
          if (selectedArticulo) {
            temp = temp.filter((e) =>
              e.idArticulo.includes(selectedArticulo)
            );
          }

        let agrupadoColor = temp.reduce(function (groups, item) {
          const val = item["idColor"];
          groups[val] = groups[val] || {
            idColor: item.idColor,
            cantidad: 0,
          };
          groups[val].cantidad += item.cantidad;
          groups[val].color = item.color;
          groups[val].idColor = item.idColor;
          return groups;
        }, []);

        let otraColor = Object.values(agrupadoColor).sort((a, b) =>
          a.cantidad < b.cantidad ? 1 : -1
        );

        setGraficasColores(otraColor);

        let agrupadoArticulo = temp.reduce(function (groups, item) {
          const val = item["idArticulo"];
          groups[val] = groups[val] || {
            idArticulo: item.idArticulo,
            cantidad: 0,
          };
          groups[val].cantidad += item.cantidad;
          groups[val].articulo = item.articulo;
          groups[val].idArticulo = item.idArticulo;
          return groups;
        }, []);

        let otraArticulo = Object.values(agrupadoArticulo).sort((a, b) =>
          a.cantidad < b.cantidad ? 1 : -1
        );

        setGraficasArticulos(otraArticulo);

        let agrupadoTiposTela = temp.reduce(function (groups, item) {
          const val = item["idTiposTela"];
          groups[val] = groups[val] || {
            idTiposTela: item.idTiposTela,
            cantidad: 0,
          };
          groups[val].cantidad += item.cantidad;
          groups[val].tiposTela = item.tiposTela;
          groups[val].idTiposTela = item.idTiposTela;
          return groups;
        }, []);

        let otraTiposTela = Object.values(agrupadoTiposTela).sort((a, b) =>
          a.cantidad < b.cantidad ? 1 : -1
        );

        setGraficasTiposTelas(otraTiposTela);

        let agrupadoMes = temp.reduce(function (groups, item) {
          const val = item["Mes"];
          groups[val] = groups[val] || {
            Mes: item.Mes,
            cantidad: 0,
          };
          groups[val].cantidad += item.cantidad;
          groups[val].Mes = item.Mes;
          return groups;
        }, []);

        let otraMes = Object.values(agrupadoMes).sort((a, b) =>
          a.cantidad < b.cantidad ? 1 : -1
        );

        setGraficasMeses(otraMes);

        let agrupadoProveedor = temp.reduce(function (groups, item) {
          const val = item["idProveedor"];
          groups[val] = groups[val] || {
            idProveedor: item.idProveedor,
            cantidad: 0,
          };
          groups[val].cantidad += item.cantidad;
          groups[val].proveedor = item.proveedor;
          groups[val].idProveedor = item.idProveedor;
          return groups;
        }, []);

        let otraProveedor = Object.values(agrupadoProveedor).sort((a, b) =>
          a.cantidad < b.cantidad ? 1 : -1
        );

        setGraficasProveedores(otraProveedor);
      

        let TVal = temp.map((c) => parseFloat(c.total));
        let TV = TVal.reduce((t, total, index) => t + total, 0);
        setValorComprado(TV);

        let TCan = temp.map((c) => parseFloat(c.cantidad));
        let TC = TCan.reduce((t, total, index) => t + total, 0);
        setcantidadComprado(TC);

  }, [info, selectedMes, selectedColor, selectedTiposTela, selectedProveedor, selectedArticulo]);


  useLayoutEffect(() => {
    
    // Grafica Colores
    let root = am5.Root.new("chartdiv");

    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        endAngle: 270,
        layout: root.verticalLayout,
        tooltip: am5.Tooltip.new(root, {
          labelText: "{color} {value}",
        }),
      })
    );

    let series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: "cantidad",
        categoryField: "color",
        endAngle: 270,
      })
    );

    series.states.create("hidden", {
      endAngle: -90,
    });

    chart.children.unshift(
      am5.Label.new(root, {
        text: "Colores",
        fontSize: 25,
        fontWeight: "500",
        textAlign: "center",
        x: am5.percent(50),
        centerX: am5.percent(50),
        paddingTop: 0,
        paddingBottom: 0,
      })
    );

    series.labels.template.set("forceHidden", true);
    series.ticks.template.setAll({
      visible: false,
      stroke: am5.color("rgb(252, 252, 252)"),
    });
    series.data.setAll(graficasColores);
    series.appear(1000, 100);

    // let legend = chart.children.push(
    //   am5.Legend.new(root, {
    //     nameField: "color",
    //   })
    // );

    // legend.data.setAll(series.dataItems);

// Grafica Articulos
let rootArticulos = am5.Root.new("chartdivArticulos");

rootArticulos.setThemes([am5themes_Animated.new(rootArticulos)]);

let chartArticulos = rootArticulos.container.children.push(
  am5percent.PieChart.new(rootArticulos, {
    endAngle: 270,
    layout: rootArticulos.verticalLayout,
    tooltip: am5.Tooltip.new(rootArticulos, {
      labelText: "{articulo} {value}",
    }),
  })
);

let seriesArticulos = chartArticulos.series.push(
  am5percent.PieSeries.new(rootArticulos, {
    valueField: "cantidad",
    categoryField: "articulo",
    endAngle: 270,
  })
);

seriesArticulos.states.create("hidden", {
  endAngle: -90,
});

chartArticulos.children.unshift(
  am5.Label.new(rootArticulos, {
    text: "Articulos",
    fontSize: 25,
    fontWeight: "500",
    textAlign: "center",
    x: am5.percent(50),
    centerX: am5.percent(50),
    paddingTop: 0,
    paddingBottom: 0,
  })
);

seriesArticulos.labels.template.set("forceHidden", true);
seriesArticulos.ticks.template.setAll({
  visible: false,
  stroke: am5.color("rgb(252, 252, 252)"),
});
seriesArticulos.data.setAll(graficasArticulos);
seriesArticulos.appear(1000, 100);

// let legendArticulos = chartArticulos.children.push(
//   am5.Legend.new(rootArticulos, {
//     nameField: "articulo",
//   })
// );

// legendArticulos.data.setAll(seriesArticulos.dataItems);


    // Grafica TiposTelas
    let rootTiposTelas = am5.Root.new("chartdivTiposTelas");

    rootTiposTelas.setThemes([am5themes_Animated.new(rootTiposTelas)]);

    let chartTiposTelas = rootTiposTelas.container.children.push(
      am5percent.PieChart.new(rootTiposTelas, {
        endAngle: 270,
        layout: rootTiposTelas.verticalLayout,
        tooltip: am5.Tooltip.new(rootTiposTelas, {
          labelText: "{tiposTela} {value}",
        }),
      })
    );

    let seriesTiposTelas = chartTiposTelas.series.push(
      am5percent.PieSeries.new(rootTiposTelas, {
        valueField: "cantidad",
        categoryField: "tiposTela",
        endAngle: 270,
      })
    );

    seriesTiposTelas.states.create("hidden", {
      endAngle: -90,
    });

    chartTiposTelas.children.unshift(
      am5.Label.new(rootTiposTelas, {
        text: "Tipos de Telas",
        fontSize: 25,
        fontWeight: "500",
        textAlign: "center",
        x: am5.percent(50),
        centerX: am5.percent(50),
        paddingTop: 0,
        paddingBottom: 0,
      })
    );

    seriesTiposTelas.labels.template.set("forceHidden", true);
    seriesTiposTelas.ticks.template.setAll({
      visible: false,
      stroke: am5.color("rgb(252, 252, 252)"),
    });
    seriesTiposTelas.data.setAll(graficasTiposTelas);
    seriesTiposTelas.appear(1000, 100);

    let legendTiposTelas = chartTiposTelas.children.push(
      am5.Legend.new(rootTiposTelas, {
        nameField: "tiposTela",
      })
    );

    legendTiposTelas.data.setAll(seriesTiposTelas.dataItems);

  

    // Grafica Mes
     let rootMeses = am5.Root.new("chartdivMeses");

     rootMeses.setThemes([am5themes_Animated.new(rootMeses)]);
 
     let chartMeses = rootMeses.container.children.push(
       am5percent.PieChart.new(rootMeses, {
         endAngle: 270,
         layout: rootMeses.verticalLayout,
         tooltip: am5.Tooltip.new(rootMeses, {
           labelText: "{Mes} {value}",
         }),
       })
     );
 
     let seriesMeses = chartMeses.series.push(
       am5percent.PieSeries.new(rootMeses, {
         valueField: "cantidad",
         categoryField: "Mes",
         endAngle: 270,
       })
     );
 
     seriesMeses.states.create("hidden", {
       endAngle: -90,
     });
 
     chartMeses.children.unshift(
       am5.Label.new(rootMeses, {
         text: "Mes",
         fontSize: 25,
         fontWeight: "500",
         textAlign: "center",
         x: am5.percent(50),
         centerX: am5.percent(50),
         paddingTop: 0,
         paddingBottom: 0,
       })
     );
 
     seriesMeses.labels.template.set("forceHidden", true);
     seriesMeses.ticks.template.setAll({
       visible: false,
       stroke: am5.color("rgb(252, 252, 252)"),
     });
     seriesMeses.data.setAll(graficasMeses);
     seriesMeses.appear(1000, 100);
 
     let legendMeses = chartMeses.children.push(
       am5.Legend.new(rootMeses, {
         nameField: "Mes",
       })
     );
 
     legendMeses.data.setAll(seriesMeses.dataItems);

     
      // Grafica Proveedores
    let rootProveedores = am5.Root.new("chartdivProveedores");

    rootProveedores.setThemes([am5themes_Animated.new(rootProveedores)]);

    let chartProveedores = rootProveedores.container.children.push(
      am5percent.PieChart.new(rootProveedores, {
        endAngle: 270,
        layout: rootProveedores.verticalLayout,
        tooltip: am5.Tooltip.new(rootProveedores, {
          labelText: "{proveedor} {value}",
        }),
      })
    );

    let seriesProveedores = chartProveedores.series.push(
      am5percent.PieSeries.new(rootProveedores, {
        valueField: "cantidad",
        categoryField: "proveedor",
        endAngle: 270,
      })
    );

    seriesProveedores.states.create("hidden", {
      endAngle: -90,
    });

    chartProveedores.children.unshift(
      am5.Label.new(rootProveedores, {
        text: "Proveedores",
        fontSize: 25,
        fontWeight: "500",
        textAlign: "center",
        x: am5.percent(50),
        centerX: am5.percent(50),
        paddingTop: 0,
        paddingBottom: 0,
      })
    );

    seriesProveedores.labels.template.set("forceHidden", true);
    seriesProveedores.ticks.template.setAll({
      visible: false,
      stroke: am5.color("rgb(252, 252, 252)"),
    });
    seriesProveedores.data.setAll(graficasProveedores);
    seriesProveedores.appear(1000, 100);

    let legendProveedores = chartProveedores.children.push(
      am5.Legend.new(rootProveedores, {
        nameField: "proveedor",
      })
    );

    legendProveedores.data.setAll(seriesProveedores.dataItems);



    return () => {
      root.dispose();
      rootTiposTelas.dispose();
      rootMeses.dispose();
      rootArticulos.dispose();
      rootProveedores.dispose();
    };
  }, [graficasColores, graficasTiposTelas, graficasMeses, graficasProveedores]);

  return (
    <>
      <Header />
      <br />
      <br />
      {/* {user.menu_cantidad ? ( */}
      <div className="card container col-12">
        <Row>
          <Col md={8}>
            <Button
              size="sm"
              href="/MenuReportes"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Col>
        </Row>
        <h3 align="center">Dashboard Compras</h3>
        <Row>
        <Col md={2}>
            <Label className="mr-sm-2">Fecha Inicio</Label>
            <Input
              bsSize="sm"
              type="date"
              value={selectedFechaInicio}
              required
              onChange={(e) => {
                setSelectedFechaInicio(e.target.value)
              }}
            />
          </Col>
          <Col md={2}>
            <Label className="mr-sm-2">Fecha Fin</Label>
            <Input
              bsSize="sm"
              type="date"
              value={selectedFechaFin}
              required
              onChange={(e) => {
                setSelectedFechaFin(e.target.value)
              }}
            />
          </Col>
          <Col md={2}>
            <h4></h4>
            <Button
              size="sm"
              className="btn"
              id="botonListado"
              onClick={BuscaInfo}
              color="info"
            >
              Actualizar
            </Button>
          </Col>
        </Row>
        <br />
          <Row>
          <Col md={2}>
              <Label className="mr-sm-2">Articulos</Label>
              <Input
                
                bsSize="sm"
                type="select"
                value={selectedArticulo}
                required
                onChange={(e) => {
                  setSelectedArticulo(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                {articulos
                  .sort((a, b) => (a.articulo > b.articulo ? 1 : -1))
                  .map((a) => {
                    return <option value={a.idArticulo}>{a.articulo}</option>;
                  })}
              </Input>
            </Col>

            <Col md={2}>
              <Label className="mr-sm-2">Proveedores</Label>
              <Input
                
                bsSize="sm"
                type="select"
                value={selectedProveedor}
                required
                onChange={(e) => {
                  setSelectedProveedor(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                {proveedores
                  .sort((a, b) => (a.proveedor > b.proveedor ? 1 : -1))
                  .map((a) => {
                    return <option value={a.idProveedor}>{a.proveedor}</option>;
                  })}
              </Input>
            </Col>

          <Col md={2}>
              <Label className="mr-sm-2">Meses</Label>
              <Input
                
                bsSize="sm"
                type="select"
                value={selectedMes}
                required
                onChange={(e) => {
                  setSelectedMes(e.target.value);
                }}
              >
                <option value="">Seleciona</option>
                {meses
                  // .sort((a, b) => (a.Mes > b.Mes ? 1 : -1))
                  .map((a) => {
                    return <option value={a.Mes}>{a.Mes}</option>;
                  })}
              </Input>
            </Col>

                <Col md={2}>
              <Label className="mr-sm-2">Colores</Label>
              <Input
                
                bsSize="sm"
                type="select"
                value={selectedColor}
                required
                onChange={(e) => {
                  setSelectedColor(e.target.value);
                }}
              >
                <option value="">Seleciona</option>
                {colores
                  .sort((a, b) => (a.color > b.color ? 1 : -1))
                  .map((a) => {
                    return <option value={a.idColor}>{a.color}</option>;
                  })}
              </Input>
            </Col>


            <Col md={2}>
              <Label className="mr-sm-2">TiposTelas</Label>
              <Input
                
                bsSize="sm"
                type="select"
                value={selectedTiposTela}
                required
                onChange={(e) => {
                  setSelectedTiposTela(e.target.value);
                }}
              >
                <option value="">Seleciona</option>
                {tiposTelas
                  .sort((a, b) => (a.tiposTela > b.tiposTela ? 1 : -1))
                  .map((a) => {
                    return <option value={a.idTiposTela}>{a.tiposTela}</option>;
                  })}
              </Input>
            </Col>


        </Row> 

        <div
        className="container"
        style={{ display: "flex", justifyContent: "center" }}
        a
      >
        <Col md={3}>
          <Card>
            <CardHeader tag="h3" align="center">
              PIEZAS
            </CardHeader>
            <CardBody>
              <CardText tag="h4" align="center">
                {new Intl.NumberFormat("en-US", {}).format(cantidadComprado)}
              </CardText>
            </CardBody>
          </Card>
        </Col>

        <Col md={3}>
          <Card>
            <CardHeader tag="h3" align="center">
              COMPRAS $
            </CardHeader>
            <CardBody>
              <CardText tag="h4" align="center">
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 2,
                }).format(valorComprado)}
              </CardText>
            </CardBody>
          </Card>
        </Col>
      </div>


      <div
          // className="container"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div id="chartdivArticulos" style={{ width: "40%", height: "500px" }}></div>
        </div>
<br />
<br />

      
        <div
          // className="container"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div id="chartdiv" style={{ width: "40%", height: "500px" }}></div>
          <div
            id="chartdivTiposTelas"
            style={{ width: "40%", height: "500px" }}
          ></div>
        </div>

        <br />
        <br />
        <div
          // className="container"
          style={{ display: "flex", justifyContent: "center" }}
        >

        <div
            id="chartdivProveedores"
            style={{ width: "40%", height: "500px" }}
          ></div>

          <div
            id="chartdivMeses"
            style={{ width: "40%", height: "500px" }}
          ></div>
        </div>

      </div>


      {/* ) : undefined}  */}
    </>
  );
}
export default DashboardCompras;
