import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";

function ListadoMaquinas() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_MAQUINAS = process.env.REACT_APP_URL_MAQUINAS;
  const URL_TIPOS_MAQUINAS = process.env.REACT_APP_URL_TIPOS_MAQUINAS;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;

  const [tiposMaquinas, setTiposMaquinas] = useState([]);
  const [selectedTiposMaquinasFiltro, setSelectedTiposMaquinasFiltro] = useState("");

  const [idEditMaquina, setIdEditMaquina] = useState("");
  const [selectedTipoEdit, setSelectedTipoEdit] = useState("");
  const [nombre, setNombre] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [observaciones, setObservaciones] = useState("");

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  const [activo, setActivo] = useState("");

  const [modalFoto, setModalFoto] = useState(false);
  const toggleFoto = () => setModalFoto(!modalFoto);
  const [idFoto, setIdFoto] = useState("");
  const [file, setFile] = useState();
  const [photo, setPhoto] = useState();
  const URL_FILEPOST = process.env.REACT_APP_URL_UPPROFILE;

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useEffect(() => {
    axios
      .get(URL_MAQUINAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allMaquinas = response.data;
        // Array para pagination
        let arrayTabla = allMaquinas
          .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              activo: a.is_active,
              nombre: a.nombre,
              descripcion: a.descripcion,
              idTiposMaquinas: a.tiposMaquinas[0]._id,
              tiposMaquinas: a.tiposMaquinas[0].name,
              observaciones: a.observaciones,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_TIPOS_MAQUINAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allTiposMaquinas = response.data;
        setTiposMaquinas(allTiposMaquinas);
      })
      .catch((err) => {
        console.log(err);
      });

   
  }, []);

  const ListaMaquinas = comments.sort((a, b) =>
    a.nombre > b.nombre ? 1 : -1
  );

  function PDFTabla() {
    const data = ListaMaquinas.map((a) => {
      if (
        // (selectedTiposMaquinasFiltro == 0 || selectedTiposMaquinasFiltro == a.grupos[0]._id) &&
        activo == 0 ||
        activo == a.is_active
      ) {
        return [
          a.nombre,
          a.tiposMaquinas,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado Maquinas`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Nombre",
          "Tipo",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    doc.save(`Maquinas.pdf`);
  }

  function excel() {
    const dataExcel = ListaMaquinas.map((a) => {
      if (activo == 0 || activo == a.is_active) {
        return {
          Nombre: a.nombre,
          Tipo: a.tiposMaquinas,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoMaquinas";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoMaquinas",
        sheetFilter: [
          "Nombre",
          "Tipo",
        ],
        sheetHeader: [
          "Nombre",
          "Tipo",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = ListaMaquinas.map((a) => {
      if (
        // (selectedTiposMaquinasFiltro == 0 || selectedTiposMaquinasFiltro == a.grupos[0]._id) &&
        activo == 0 ||
        activo == a.is_active
      ) {
        return [
          a.nombre,
          a.tiposMaquinas,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado Maquinas`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Nombre",
          "Tipo",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Maquinas",
          email: mailTo,
          fileName: "ListadoMaquinas.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Maquinas.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Enviado con Exito!",
          showConfirmButton: false,
          timer: 2000,
        });
        setMailTo("")
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function jalaFoto(
    idEdit
  ) {
    setPhoto("")
    const URL_GET_MEDIA = `${process.env.REACT_APP_URL_GETMEDIA}/${idEdit}`;
    axios
      .get(URL_GET_MEDIA, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((data) => {
        if (data.data.version) {
          setPhoto(data.data.version);
        }
      })
      .catch((err) => console.log(err));


    setIdFoto(idEdit);
    toggleFoto();
  }

  function jalaInfo(
    nombre,
    descripcion,
    idTiposMaquinas,
    observaciones,
    idEdit
  ) {

    setNombre(nombre);
    setDescripcion(descripcion);
    setSelectedTipoEdit(idTiposMaquinas);
    setObservaciones(observaciones);
    setIdEditMaquina(idEdit);
    toggleEdit();
  }

  function editMaquinas(event) {
    event.preventDefault();
    const URL_MAQUINAS_EDIT = `${process.env.REACT_APP_URL_MAQUINAS}/${idEditMaquina}`;
    if (file == null) {
          axios.patch(
            URL_MAQUINAS_EDIT,
            {
              nombre,
              tiposMaquinas: selectedTipoEdit,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
        .then(() => {
          axios.post(
            URL_LOGS,
            {
              tipo: "Editar Maquina",
              detalle: `${nombre}`,
              user: user.id,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          );

          toggleEdit();
          Swal.fire("Good job!", "Actualizado con exito", "success");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        });
      }else{
        axios.patch(
          URL_MAQUINAS_EDIT,
          {
            nombre,
            tiposMaquinas: selectedTipoEdit,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
      .then(() => {

        const formData = new FormData();
        formData.append("file", file);
        formData.append("articulo", idFoto);

        axios
          .post(URL_FILEPOST, formData, {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          })
          .then(() => {
            axios.post(
              URL_LOGS,
              {
                tipo: "Editar Maquina",
                detalle: `${nombre} `,
                user: user.id,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem(
                    "app_token"
                  )}`,
                },
              }
            );

            toggleEdit();
            Swal.fire("Good job!", "Actualizado con exito", "success");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });

      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
      }
  
  }

  const headers = [
    { name: "Activo", field: "activo", sortable: true },
    { name: "Nombre", field: "nombre", sortable: true },
    { name: "Tipo", field: "tiposMaquinas", sortable: true },
    { name: "Acciones", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
          comment.tiposMaquinas.toLowerCase().includes(search.toLowerCase()) 
      );
    }
    if (selectedTiposMaquinasFiltro) {
      computedComments = computedComments.filter((e) =>
        e.idTiposMaquinas.includes(selectedTiposMaquinasFiltro)
      );
    }
    if (activo) {
      computedComments = computedComments.filter((e) =>
        e.activo.includes(activo)
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedTiposMaquinasFiltro,
    activo,
  ]);

 


  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_articulos ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              {user.articulos_create ? (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/MaquinasCreate"
                >
                  Nuevo Maquina
                </Button>
              ) : (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  Nuevo Maquina
                </Button>
              )}
              <Button
                size="sm"
                href="/MenuMaquinas"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Maquinas</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Maquinas</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label>Tipo</Label>
              <Input
                bsSize="sm"
                type="select"
                value={selectedTiposMaquinasFiltro}
                onChange={(e) => {
                  setSelectedTiposMaquinasFiltro(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                {tiposMaquinas
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col>

            <Col md={2}>
              <Label>Activo</Label>
              <Input
                bsSize="sm"
                type="select"
                value={activo}
                required
                onChange={(e) => {
                  setActivo(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                <option value="Si">Activos</option>
                <option value="No">Inactivos</option>
              </Input>
            </Col>
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData.map((a) => {
                {
                  return (
                    <tr>
                      <td>{a.activo}</td>
                      <td>{a.nombre}</td>
                      <td>{a.tiposMaquinas} </td>
                      <td>
                        {user.articulos_create ? (
                          <div>
                           
                           <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                jalaFoto(
                                  a._id
                                )
                              }
                            >
                              <i class="fas fa-camera"></i>
                            </Button>
                            <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                jalaInfo(
                                  a.nombre,
                                  a.descripcion,
                                  a.idTiposMaquinas,
                                  a.observaciones,
                                  a._id
                                )
                              }
                            >
                              <i class="far fa-edit"></i>
                            </Button>
                            <Baja
                              idStatus={a._id}
                              is_active={a.activo}
                              URL_BAJA={process.env.REACT_APP_URL_MAQUINAS}
                            />
                          </div>
                        ) : (
                          <div>
                            <Button color="info" id="Editar" size="sm" disabled>
                              <i class="far fa-edit"></i>
                            </Button>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

      <Modal size="lg" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>
          <h4>
            Editar maquina / {nombre}
          </h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            
            <Col md={3}>
              <Label>Nombre</Label>
              <Input
                type="text"
                placeholder="Nombre"
                value={nombre}
                required
                onChange={(e) => {
                  setNombre(e.target.value);
                }}
              />
            </Col>


          </Row>

          <Row>
            <Col md={3}>
              <Label>Descripcion</Label>
              <Input
                type="text"
                placeholder="Descripcion"
                value={descripcion}
                required
                onChange={(e) => {
                  setDescripcion(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Label className="mr-sm-2">Tipo</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedTipoEdit}
                required
                onChange={(e) => {
                  setSelectedTipoEdit(e.target.value);
                }}
              >
                <option value="0">Selecciona</option>
                {tiposMaquinas
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Label>
                Observaciones
              </Label>
              <Input
                type="textarea"
                placeholder="Observaciones"
                value={observaciones}
                required
                onChange={(e) => {
                  setObservaciones(e.target.value);
                }}
              />
            </Col>
            <Col md={6}>
              <Label>
                Foto
              </Label>
              <Input
                type="file"
                onChange={(e) => {
                  setFile(e.target.files[0]);
                }}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editMaquinas}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="sm" isOpen={modalFoto} toggle={toggleFoto}>
        <ModalHeader toggle={toggleFoto}>
          <h4>
            Ficha Tecnica
          </h4>
        </ModalHeader>
        <ModalBody>
        <Row>
            {photo ? (
              <h6 style={{ textAlign: "center" }}>
                <img
                  // loading="lazy"
                  src={`${process.env.REACT_APP_URL_FOTO_CLOUDINARY}/image/upload/v${photo}/bloomArticulos/${idFoto}.jpg`}
                  alt=""
                />
              </h6>
            ) : <h4>No se ha subido una ficha Tecnica....</h4>}
          </Row>
        </ModalBody>
      </Modal>

   
      {loader}
    </>
  );
}

export default ListadoMaquinas;
