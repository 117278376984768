import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useLayoutEffect,
  useMemo,
} from "react";
import { AuthContext } from "../../contexts/AuthContext";
import axios from "axios";
import Header from "../../layout/Header/Header";

import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Form,
  Card,
  CardHeader,
  CardText,
  CardBody,
  CardFooter,
  CardTitle,
} from "reactstrap";
import moment from "moment";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import * as am5percent from "@amcharts/amcharts5/percent";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

function DashboardCobranza() {
  const { user } = useContext(AuthContext);
  const URL_DASHBOARDS = process.env.REACT_APP_URL_DASHBOARDS;

  let hoy = new Date();
  let inicio = new Date();
  inicio.setDate(inicio.getDate() - 90);

  let endDate = moment(hoy).format("YYYY-MM-DD");
  let startDate = moment(inicio).format("YYYY-MM-DD");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  const [graficasArea, setGraficasArea] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [vendedores, setVendedores] = useState([]);
  const [selectedVendedor, setSelectedVendedor] = useState("");

  const [info, setInfo] = useState([]);
  const [cargando, setCargando] = useState(true);
  const [totalTotal, setTotalTotal] = useState(0);
  const [totalAbonos, setTotalAbonos] = useState(0);
  const [totalBonificaciones, setTotalBonificaciones] = useState(0);
  const [totalDevoluciones, setTotalDevoluciones] = useState(0);
  const [totalAnticipos, setTotalAnticipos] = useState(0);

  const Toast = Swal.mixin({
    toast: true,
    position: "center",
    showConfirmButton: false,
    // timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  if (cargando) {
    Toast.fire({
      icon: "success",
      title: "Danos unos segundos....",
    });
  }

  useEffect(() => {
    axios
      .get(
        `${URL_DASHBOARDS}Cobranza/${selectedFechaInicio}/${selectedFechaFin}`,
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then((response) => {
        let totalTotal = 0;

        let allGraficas = response.data;
        let arrayTabla = allGraficas
          .map((a) => {
            totalTotal = totalTotal + parseFloat(a.Abonos);
            if (a.Concepto == "Abono") {
              return {
                Concepto: "Abono",
                idCliente: a.idCliente,
                Cliente: a.Cliente,
                idVendedor: a.idVendedor,
                Vendedor: a.Vendedor,
                Importe: parseFloat(a.Abonos),
                Abonos: parseFloat(a.Abonos),
                Bonificaciones: 0,
                Devoluciones: 0,
                Anticipos: 0,
              };
            } else if (a.Concepto == "Bonificacion") {
              return {
                Concepto: "Bonificacion",
                idCliente: a.idCliente,
                Cliente: a.Cliente,
                idVendedor: a.idVendedor,
                Vendedor: a.Vendedor,
                Importe: parseFloat(a.Abonos),
                Abonos: 0,
                Bonificaciones: parseFloat(a.Abonos),
                Devoluciones: 0,
                Anticipos: 0,
              };
            } else if (a.Concepto == "Devolucion") {
              return {
                Concepto: "Devolucion",
                idCliente: a.idCliente,
                Cliente: a.Cliente,
                idVendedor: a.idVendedor,
                Vendedor: a.Vendedor,
                Importe: parseFloat(a.Abonos),
                Abonos: 0,
                Bonificaciones: 0,
                Devoluciones: parseFloat(a.Abonos),
                Anticipos: 0,
              };
            } else if (a.Concepto.includes("Anticipo")) {
              return {
                Concepto: "Anticipo",
                idCliente: a.idCliente,
                Cliente: a.Cliente,
                idVendedor: a.idVendedor,
                Vendedor: a.Vendedor,
                Importe: parseFloat(a.Abonos),
                Abonos: 0,
                Bonificaciones: 0,
                Devoluciones: 0,
                Anticipos: parseFloat(a.Abonos),
              };
            }
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        setInfo(dataFinal);
        setTotalTotal(totalTotal);

        let unicosCli = dataFinal.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.idCliente === value.idCliente)
        );
        setClientes(unicosCli);

        let unicosVend = dataFinal.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.idVendedor === value.idVendedor)
        );
        setVendedores(unicosVend);

        setCargando(false);
        Toast.close();
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function BuscaInfo() {
    setInfo([]);
    setSelectedCliente("");
    setSelectedVendedor("");
    setCargando(true);
    axios
      .get(
        `${URL_DASHBOARDS}Cobranza/${selectedFechaInicio}/${selectedFechaFin}`,
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then((response) => {
        let totalTotal = 0;

        let allGraficas = response.data;
        let arrayTabla = allGraficas
          .map((a) => {
            totalTotal = totalTotal + parseFloat(a.Abonos);
            if (a.Concepto == "Abono") {
              return {
                Concepto: "Abono",
                idCliente: a.idCliente,
                Cliente: a.Cliente,
                idVendedor: a.idVendedor,
                Vendedor: a.Vendedor,
                Importe: parseFloat(a.Abonos),
                Abonos: parseFloat(a.Abonos),
                Bonificaciones: 0,
                Devoluciones: 0,
                Anticipos: 0,
              };
            } else if (a.Concepto == "Bonificacion") {
              return {
                Concepto: "Bonificacion",
                idCliente: a.idCliente,
                Cliente: a.Cliente,
                idVendedor: a.idVendedor,
                Vendedor: a.Vendedor,
                Importe: parseFloat(a.Abonos),
                Abonos: 0,
                Bonificaciones: parseFloat(a.Abonos),
                Devoluciones: 0,
                Anticipos: 0,
              };
            } else if (a.Concepto == "Devolucion") {
              return {
                Concepto: "Devolucion",
                idCliente: a.idCliente,
                Cliente: a.Cliente,
                idVendedor: a.idVendedor,
                Vendedor: a.Vendedor,
                Importe: parseFloat(a.Abonos),
                Abonos: 0,
                Bonificaciones: 0,
                Devoluciones: parseFloat(a.Abonos),
                Anticipos: 0,
              };
            } else if (a.Concepto.includes("Anticipo")) {
              return {
                Concepto: "Anticipo",
                idCliente: a.idCliente,
                Cliente: a.Cliente,
                idVendedor: a.idVendedor,
                Vendedor: a.Vendedor,
                Importe: parseFloat(a.Abonos),
                Abonos: 0,
                Bonificaciones: 0,
                Devoluciones: 0,
                Anticipos: parseFloat(a.Abonos),
              };
            }
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        setInfo(dataFinal);
        setTotalTotal(totalTotal);

        let unicosCli = dataFinal.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.idCliente === value.idCliente)
        );
        setClientes(unicosCli);

        let unicosVend = dataFinal.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.idVendedor === value.idVendedor)
        );
        setVendedores(unicosVend);

        setCargando(false);
        Toast.close();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useMemo(() => {
    let temp = info;
    if (selectedCliente) {
      temp = temp.filter((e) => e.idCliente.includes(selectedCliente));
    }

    if (selectedVendedor) {
      temp = temp.filter((e) => e.idVendedor.includes(selectedVendedor));
    }

    let agrupadoArea = temp.reduce(function (groups, item) {
      const val =  item["Concepto"];
      groups[val] = groups[val] || {
        Concepto: item.Concepto,
        Importe: 0,
        Abonos: 0,
        Bonificaciones: 0,
        Devoluciones: 0,
        Anticipos: 0,
      };
      groups[val].Importe += item.Importe;
      groups[val].Abonos += item.Abonos;
      groups[val].Bonificaciones += item.Bonificaciones;
      groups[val].Devoluciones += item.Devoluciones;
      groups[val].Anticipos += item.Anticipos;
      groups[val].Concepto = item.Concepto;
      groups[val].Concepto = item.Concepto;
      return groups;
    }, []);

    let otraArea = Object.values(agrupadoArea);

    setGraficasArea(otraArea);


    let TTot = temp.map((c) => parseFloat(c.Importe));
    let TT = TTot.reduce((t, total, index) => t + total, 0);
    setTotalTotal(TT);

    let TAbo = temp.map((c) => parseFloat(c.Abonos));
    let TA = TAbo.reduce((t, total, index) => t + total, 0);
    setTotalAbonos(TA);

    let Tbon = temp.map((c) => parseFloat(c.Bonificaciones));
    let TB = Tbon.reduce((t, total, index) => t + total, 0);
    setTotalBonificaciones(TB);

    let Tdev = temp.map((c) => parseFloat(c.Devoluciones));
    let TD = Tdev.reduce((t, total, index) => t + total, 0);
    setTotalDevoluciones(TD);

    let TAnt = temp.map((c) => parseFloat(c.Anticipos));
    let TAn = TAnt.reduce((t, total, index) => t + total, 0);
    setTotalAnticipos(TAn);
  }, [info, selectedCliente, selectedVendedor]);

  useLayoutEffect(() => {
    // let root = am5.Root.new("chartdiv");

    // root.setThemes([am5themes_Animated.new(root)]);

    // let chart = root.container.children.push(
    //   am5xy.XYChart.new(root, {
    //     panY: false,
    //     layout: root.verticalLayout,
    //   })
    // );

    // let data = graficasArea;

    // // Create Y-axis
    // let yAxis = chart.yAxes.push(
    //   am5xy.ValueAxis.new(root, {
    //     min:0,
    //     renderer: am5xy.AxisRendererY.new(root, {}),
    //   })
    // );

    // // Create X-Axis
    // let xAxis = chart.xAxes.push(
    //   am5xy.CategoryAxis.new(root, {
    //     renderer: am5xy.AxisRendererX.new(root, {}),
    //     categoryField: "Area",
    //   })
    // );
    // xAxis.data.setAll(data);

    // // Create series
    // let seriesAbo = chart.series.push(
    //   am5xy.ColumnSeries.new(root, {
    //     name: "Abonos",
    //     xAxis: xAxis,
    //     yAxis: yAxis,
    //     valueYField: "Abonos",
    //     categoryXField: "Area",
    //     tooltip: am5.Tooltip.new(root, {
    //       labelText: "{Area} Abonos / ${valueY}",
    //     }),
    //   })
    // );
    // seriesAbo.data.setAll(data);

    // let seriesBon = chart.series.push(
    //   am5xy.ColumnSeries.new(root, {
    //     name: "Bonificaciones",
    //     xAxis: xAxis,
    //     yAxis: yAxis,
    //     valueYField: "Bonificaciones",
    //     categoryXField: "Area",
    //     tooltip: am5.Tooltip.new(root, {
    //       labelText: "{Area} Bonificaciones / ${valueY}",
    //     }),
    //   })
    // );
    // seriesBon.data.setAll(data);

    // let seriesDev = chart.series.push(
    //   am5xy.ColumnSeries.new(root, {
    //     name: "Devoluciones",
    //     xAxis: xAxis,
    //     yAxis: yAxis,
    //     valueYField: "Devoluciones",
    //     categoryXField: "Area",
    //     tooltip: am5.Tooltip.new(root, {
    //       labelText: "{Area} Devoluciones / ${valueY}",
    //     }),
    //   })
    // );
    // seriesDev.data.setAll(data);

    // let seriesAnt = chart.series.push(
    //   am5xy.ColumnSeries.new(root, {
    //     name: "Anticipos",
    //     xAxis: xAxis,
    //     yAxis: yAxis,
    //     valueYField: "Anticipos",
    //     categoryXField: "Area",
    //     tooltip: am5.Tooltip.new(root, {
    //       labelText: "{Area} Anticipos / ${valueY}",
    //     }),
    //   })
    // );
    // seriesAnt.data.setAll(data);

    // chart.set("cursor", am5xy.XYCursor.new(root, {}));

    // let exporting = am5plugins_exporting.Exporting.new(root, {
    //   menu: am5plugins_exporting.ExportingMenu.new(root, {}),
    // });

    // Grafica Areas
    let rootAreas = am5.Root.new("chartdivAreas");

    // Set themes
    rootAreas.setThemes([am5themes_Animated.new(rootAreas)]);

    // Create chart
    let chartAreas = rootAreas.container.children.push(
      am5percent.PieChart.new(rootAreas, {
        layout: rootAreas.verticalLayout,
        innerRadius: am5.percent(50),
        tooltip: am5.Tooltip.new(rootAreas, {
          labelText: "{Concepto} {value}",
        }),
      })
    );

    // Create series
    let seriesAreas = chartAreas.series.push(
      am5percent.PieSeries.new(rootAreas, {
        valueField: "Importe",
        categoryField: "Concepto",
        alignLabels: false,
      })
    );

    seriesAreas.labels.template.setAll({
      textType: "circular",
      centerX: 0,
      centerY: 0,
    });

    seriesAreas.labels.template.set("forceHidden", true);
    seriesAreas.ticks.template.setAll({
      visible: false,
      stroke: am5.color("rgb(252, 252, 252)"),
    });

    // Set data
    seriesAreas.data.setAll(graficasArea);

    chartAreas.children.unshift(
      am5.Label.new(rootAreas, {
        text: "Cobranza",
        fontSize: 25,
        fontWeight: "500",
        textAlign: "center",
        x: am5.percent(50),
        centerX: am5.percent(50),
        paddingTop: 0,
        paddingBottom: 0,
      })
    );

    // Create legend
    let legendAreas = chartAreas.children.push(
      am5.Legend.new(rootAreas, {
        centerX: am5.percent(50),
        x: am5.percent(50),
        marginTop: 15,
        marginBottom: 15,
      })
    );

    legendAreas.data.setAll(seriesAreas.dataItems);

    // Play initial series animation
    seriesAreas.appear(1000, 100);

    


    return () => {
      rootAreas.dispose();
    };
  }, [graficasArea]);

  return (
    <>
      <Header />
      <br />
      <br />
      {/* {user.menu_reportes ? ( */}
      <div className="card container col-12">
        <Row>
          <Col md={8}>
            <Button
              size="sm"
              href="/MenuReportes"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Col>
        </Row>
        <h3 align="center">Dashboard Cobranza</h3>
        <Row>
          <Col md={2}>
            <Label className="mr-sm-2">Fecha Inicio</Label>
            <Input
              bsSize="sm"
              type="date"
              value={selectedFechaInicio}
              required
              onChange={(e) => {
                setSelectedFechaInicio(e.target.value);
              }}
            />
          </Col>
          <Col md={2}>
            <Label className="mr-sm-2">Fecha Fin</Label>
            <Input
              bsSize="sm"
              type="date"
              value={selectedFechaFin}
              required
              onChange={(e) => {
                setSelectedFechaFin(e.target.value);
              }}
            />
          </Col>
          <Col md={2}>
            <h4></h4>
            <Button
              size="sm"
              className="btn"
              id="botonListado"
              onClick={BuscaInfo}
              color="info"
            >
              Actualizar
            </Button>
          </Col>
        </Row>
        <br />
        <Row>
          <Col md={2}>
            <Label className="mr-sm-2">Clientes</Label>
            <Input
              bsSize="sm"
              type="select"
              value={selectedCliente}
              required
              onChange={(e) => {
                setSelectedCliente(e.target.value);
              }}
            >
              <option value="">Selecciona</option>
              {clientes
                .sort((a, b) => (a.Cliente > b.Cliente ? 1 : -1))
                .map((a) => {
                  return <option value={a.idCliente}>{a.Cliente}</option>;
                })}
            </Input>
          </Col>

          <Col md={2}>
            <Label className="mr-sm-2">Vendedores</Label>
            <Input
              bsSize="sm"
              type="select"
              value={selectedVendedor}
              required
              onChange={(e) => {
                setSelectedVendedor(e.target.value);
              }}
            >
              <option value="">Selecciona</option>
              {vendedores
                .sort((a, b) => (a.Vendedor > b.Vendedor ? 1 : -1))
                .map((a) => {
                  return <option value={a.idVendedor}>{a.Vendedor}</option>;
                })}
            </Input>
          </Col>
        </Row>

        <div
          className="container"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Col md={3}>
            <Card>
              <CardHeader tag="h3" align="center">
                TOTAL GENERAL
              </CardHeader>
              <CardBody>
                <CardText tag="h4" align="center">
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 2,
                  }).format(totalTotal)}
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </div>

        <div
          className="container"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Col md={3}>
            <Card>
              <CardHeader tag="h4" align="center">
                TOTAL ABONOS
              </CardHeader>
              <CardBody>
                <CardText tag="h4" align="center">
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 2,
                  }).format(totalAbonos)}
                </CardText>
              </CardBody>
            </Card>
          </Col>

          <Col md={3}>
            <Card>
              <CardHeader tag="h4" align="center">
                TOTAL BONIFICACIONES
              </CardHeader>
              <CardBody>
                <CardText tag="h4" align="center">
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 2,
                  }).format(totalBonificaciones)}
                </CardText>
              </CardBody>
            </Card>
          </Col>

          <Col md={3}>
            <Card>
              <CardHeader tag="h4" align="center">
                TOTAL DEVOLUCIONES
              </CardHeader>
              <CardBody>
                <CardText tag="h4" align="center">
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 2,
                  }).format(totalDevoluciones)}
                </CardText>
              </CardBody>
            </Card>
          </Col>

          <Col md={3}>
            <Card>
              <CardHeader tag="h4" align="center">
                TOTAL ANTICIPOS
              </CardHeader>
              <CardBody>
                <CardText tag="h4" align="center">
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 2,
                  }).format(totalAnticipos)}
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </div>
        <div
          // className="container"
          style={{ display: "flex", justifyContent: "center" }}
        >
        <div id="chartdivAreas" style={{ width: "40%", height: "500px" }}></div>
        </div>
      </div>

      {/*  ) : undefined} */}
    </>
  );
}
export default DashboardCobranza;
