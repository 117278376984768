import React, { useState, useEffect, useContext, useMemo } from "react";
import { useParams } from 'react-router-dom';
import {
  Row,
  Table,
  Input,
  Label,
  Col,
} from "reactstrap";
import axios from "axios";

function ListadoSeguimientoExterno() {
  const { id } = useParams();
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  const URL_PRODUCCION = process.env.REACT_APP_URL_PRODUCCION;
  const URL_INSUMOS_PRODUCCION = process.env.REACT_APP_URL_INSUMOS_PRODUCCION;

  const [selectedRollo, setSelectedRollo] = useState("");
  const [info, setInfo] = useState([]);
  const [insumos, setInsumos] = useState([]);
  const [procesos, setProcesos] = useState([]);
  const [presentarInfo, setPresentarInfo] = useState(false);


  useEffect(() => {
    // Set the selectedRollo when the component mounts
    setSelectedRollo(id);
  }, []);

  useMemo(() => {
    if (selectedRollo) {
     axios.get(`${URL_INVENTARIOS}Historial/${selectedRollo}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
     })
     .then((res) => {
      setInfo(res.data)

      let pedido = res.data.pedidos[0]._id

      axios.get(`${URL_PRODUCCION}Pedido/${pedido}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        setProcesos(res.data)
        setPresentarInfo(true)

        const produccionesIds = res.data.map((proceso) => proceso._id);

        axios.get(`${URL_INSUMOS_PRODUCCION}Producciones`, {
          params: {
            produccionesIds: produccionesIds.join(','),
          },
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((res) => {
          setInsumos(res.data)  
        })
        .catch((err) => console.log(err))

      })
      .catch((err) => console.log(err))
    })
     .catch((err) => console.log(err))
    }
    
  }, [selectedRollo]);





  return (
    <>
      <br />
      <br />
        <div className="card container">
         
          

          <h3 align="center">Historial de Rollo</h3>

          <Row>
            <Col md={3}>
            <Label>ID del Rollo a Buscar</Label>
              <Input
              type="text"
              value={selectedRollo}
              onChange={(e) => {
                setSelectedRollo(e.target.value);
              }} />
            </Col>
          </Row>
          

          <br />
          {presentarInfo ? (
  <div >
  <h4>Informacion del Rollo</h4>
  <h4>Vendido: {info.vendido}</h4>
    <br />
    <Table responsive>
      <tbody>
        <tr>
          <th>Articulo</th>
          <td>{info.articulos[0].nombre}</td>
        </tr>
        <tr>
          <th>Color</th>
          <td>{info.colores}</td>
        </tr>
        <tr>
          <th>Kilos</th>
          <td>{info.cantidad}</td>
        </tr>
</tbody>
        </Table>
        <Table>
          <tbody>
        <br />
        <tr>
          <th>Proceso</th>
          <th>Maquina</th>
          <th>Operador</th>
          <th>Inicio</th>
          <th>Fin</th>
        </tr>
        {procesos.map((p) => (
          <tr key={p._id}> {/* Added key prop for React performance */}
            <td>{p.procesos[0].name}</td>
            <td>{p.maquinas[0].nombre}</td>
            <td>{p.operador[0].nombre} {p.operador[0].apellido}</td>
            <td>{p.fechaInicio}</td>
            <td>{p.fechaFin}</td>
          </tr>
        ))}
        </tbody>
</Table>
        <br />

        <Table>
          <tbody>
        <tr>
          <th>Insumo</th>
        </tr>
        {insumos.map((p) => (
          <tr key={p._id}> {/* Added key prop for React performance */}
            <td>{p.insumos[0].nombre} {p.insumos[0].descripcion}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  </div>
) : undefined}

        </div>
    </>
  );
}

export default ListadoSeguimientoExterno;
