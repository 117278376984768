import React, { useContext } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import { Col, Row, Button } from "reactstrap";
import Footer from "../../layout/Footer/Footer";
import Header from "../../layout/Header/Header";

function BotonesInventarios() {
  const { user } = useContext(AuthContext);
  return (
    <>
          <Header />
          {user.menu_inventarios ?(
      <div className="container">
        <br />
        <br />
          <Row>
          <Col md={4} align="center">
          {user.inventarios ?(
            <Button href="/ListadoInventarios" className="botonesMenu" color="success">
              <i class="fas fa-warehouse fa-7x"></i>
              <br />
              <br />
              Inventario
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-warehouse fa-7x"></i>
          <br />
          <br />
          Inventario
        </Button> }
          </Col>
        
          </Row>
          <br />
          <Row>
         
          <Col md={4} align="center">
          {user.inventarios ?(
            <Button href="/ListadoSeguimiento" className="botonesMenu" color="success">
              <i class="fas fa-search fa-7x"></i>
              <br />
              <br />
              Seguimiento
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-search fa-7x"></i>
          <br />
          <br />
          Seguimiento
        </Button> }
          </Col>

        
          </Row>
          <br />

      </div>
      ): undefined } 
      
    </>
  );
}

export default BotonesInventarios;
