import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from '../../../contexts/AuthContext'
import TablaProveedores from "./TablaProveedores";
import {   Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col, } from "reactstrap";
import Header from "../../../layout/Header/Header";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from "uuid";

function ListadoProveedores() {
  const { user } = useContext(AuthContext);
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_CUENTAS_PROVEEDORES = process.env.REACT_APP_URL_CUENTAS_PROVEEDORES;
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const [proveedores, setProveedores] = useState([]);

  
  const [nombre_comercial, setNombreComercial] = useState("");
  const [cuentas, setCuentas] = useState([]);
  const [idProveedorCuenta, setIdProveedorCuenta] = useState("");
  const [modalCuenta, setModalCuenta] = useState(false);
  const toggleCuenta = () => setModalCuenta(!modalCuenta);

  const [inputFieldsCuentas, setInputFieldsCuentas] = useState([
    {
      id: uuidv4(),
      banco: "",
      cuenta:"",
    },
  ]);

  useEffect(() => {
    axios
      .get(URL_PROVEEDORES, { 
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allProveedores = response.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });
  },[]);

  function cue(id, nombre_comercial) {
    setIdProveedorCuenta(id);
    setNombreComercial(nombre_comercial);
    axios
      .get(`${URL_CUENTAS_PROVEEDORES}/proveedor/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allCuentas = res.data;
        setCuentas(allCuentas);
      })
      .catch((err) => {
        console.log(err);
      });
    toggleCuenta();
  }
  
  const handleChangeInputCuentasExistentes = (id, event) => {
    const newCuentas = cuentas.map((i) => {
      if (id === i._id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setCuentas(newCuentas);
  };
  
  const handleChangeInputCuentas = (id, event) => {
    const newInputFieldsCuentas = inputFieldsCuentas.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFieldsCuentas(newInputFieldsCuentas);
  };
  
  function saveCuenta() {
    let totalCuen = cuentas.length + inputFieldsCuentas.length;
    cuentas.map((a) => {
      if (a.cuenta != "") {
        axios
          .patch(
            `${URL_CUENTAS_PROVEEDORES}/${a._id}`,
            {
              cuenta: a.cuenta,
              banco: a.banco,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then(() => {
            totalCuen = totalCuen - 1;
            if (totalCuen == 0) {
              axios.post(
                URL_LOGS,
                {
                  tipo: "Editar Cuentas Proveedores",
                  detalle: `Proveedor ${idProveedorCuenta} / Cuenta ${a.cuenta}`,
                  user: user.id,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              );
              Swal.fire("Good job!", "Creado con exito", `success`);
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            }
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response}`,
            });
            console.log(error);
          });
      } else {
        totalCuen = totalCuen - 1
        if (totalCuen == 0) {
          axios.post(
            URL_LOGS,
            {
              tipo: "Editar Cuenta Proveedores",
              detalle: `Proveedor ${idProveedorCuenta} / Cuenta ${a.cuenta}`,
              user: user.id,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem(
                  "app_token"
                )}`,
              },
            }
          );
          Swal.fire("Good job!", "Creado con exito", `success`);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      }
    });
  
    // agregar inputFields
    inputFieldsCuentas.map((a) => {
      if (a.cuenta != "") {
        axios
          .post(
            `${URL_CUENTAS_PROVEEDORES}`,
            {
              proveedores: idProveedorCuenta,
              cuenta: a.cuenta,
              banco: a.banco,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then(() => {
            totalCuen = totalCuen - 1;
            if (totalCuen == 0) {
              axios.post(
                URL_LOGS,
                {
                  tipo: "Editar Cuenta Proveedores",
                  detalle: `Proveedor ${idProveedorCuenta} / Cuenta ${a.cuenta}`,
                  user: user.id,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              );
              Swal.fire("Good job!", "Creado con exito", `success`);
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            }
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response}`,
            });
            console.log(error);
          });
      } else {
        totalCuen = totalCuen - 1
        if (totalCuen == 0) {
          axios.post(
            URL_LOGS,
            {
              tipo: "Editar Cuenta Proveedores",
              detalle: `Proveedor ${idProveedorCuenta} / Cuenta ${a.cuenta}`,
              user: user.id,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem(
                  "app_token"
                )}`,
              },
            }
          );
          Swal.fire("Good job!", "Creado con exito", `success`);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      }
    });
  }
  
  const handleAddFieldsCuentas = () => {
    setInputFieldsCuentas([
      ...inputFieldsCuentas,
      {
        id: uuidv4(),
        banco: "",
        cuenta:"",
      },
    ]);
  };
  
  const handleRemoveFieldsCuentas = (id) => {
    const values = [...inputFieldsCuentas];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFieldsCuentas(values);
  };
  
  function altaCuenta(id) {
    axios
      .patch(
        `${URL_CUENTAS_PROVEEDORES}/${id}`,
        {
          is_active: "Si"
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem(
              "app_token"
            )}`,
          },
        }
      )
      .then((data) => {
            Swal.fire("Good job!", "Creado con exito", "success");
  
            const newCuentas = cuentas.map((i) => {
              if (id === i._id) {
                i.is_active = "Si";
              }
              return i;
            });
            setCuentas(newCuentas);
         
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          // footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }
  
  function bajaCuenta(id) {
  axios
  .patch(
  `${URL_CUENTAS_PROVEEDORES}/${id}`,
  {
  is_active: "No"
  },
  {
  headers: {
    Authorization: `Bearer: ${localStorage.getItem(
      "app_token"
    )}`,
  },
  }
  )
  .then((data) => {
    Swal.fire("Good job!", "Creado con exito", "success");
    const newCuentas = cuentas.map((i) => {
      if (id === i._id) {
        i.is_active = "No";
      }
      return i;
    });
    setCuentas(newCuentas);
  })
  .catch((error) => {
  Swal.fire({
  icon: "error",
  title: "Oops...",
  text: "Something went wrong!",
  // footer: `${error.response.data}`,
  });
  console.log(error);
  });
  }

  return (
    <>     
     <Header />
    <br />
    <br />
    {user.menu_proveedores  ?(
        <div className="card col-12">
          <Row>
              {user.proveedores_create  ?(
              <Button size="sm" type="submit" className="btn btn-success" href="/ProveedoresCreate">Nuevo Proveedor</Button>
              ):(
                <Button size="sm" type="submit" className="btn btn-success" disabled>Nuevo Proveedor</Button>
              )}
              <Button
              size="sm"
              href="/MenuCompras"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
            </Row>
                <h3 align="center">Proveedores</h3>
                <Table size="sm" striped borderless className="table-responsive-xl">
                  <thead>
                    <tr>
                      <th className="tituloTabla">
                        Nombre Comercial
                      </th>
                      <th className="tituloTabla">
                        Razon Social
                      </th>
                      <th className="tituloTabla">
                        RFC
                      </th>
                      <th className="tituloTabla">
                        Contacto
                      </th>
                      <th className="tituloTabla">
                        Telefono
                      </th>
                      <th className="tituloTabla">
                        Email
                      </th>
                      {/* <th className="tituloTabla">
                        Grupo
                      </th> */}
                      <th className="tituloTabla">
                        Editar
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    {proveedores.map((p) => {
                      return (
                        <tr>
                          <TablaProveedores
                            nomcom={p.nombre_comercial}
                            razsoc={p.razon_social}
                            RFC={p.RFC}
                            contacto={p.contacto}
                            telefono={p.telefono}
                            email={p.email}
                            _id={p._id} is_active={p.is_active}
                            calle={p.calle}
                            numero_ext={p.numero_ext}
                            numero_int={p.numero_int}
                            delegacion={p.delegacion}
                            estado={p.estado}
                            pais={p.pais}
                            cp={p.cp}
                            colonia={p.colonia}
                            ciudad={p.ciudad}
                            idProveedor={p._id}
                            tipo={p.tipo}
                          />
                          <td>
                          <Button
                                  color="info"
                                  id="Editar"
                                  size="sm"
                                  onClick={(e) =>
                                    cue(p._id, p.nombre_comercial)
                                  }
                                >
                                  <i class="fas fa-dollar-sign"></i>
                                </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>

                <Modal size="xl" isOpen={modalCuenta} toggle={toggleCuenta}>
        <ModalHeader toggle={toggleCuenta}>
          <h4>Cuentas {nombre_comercial}</h4>
        </ModalHeader>
        <ModalBody>
        <Table striped borderless>
                  <thead>
                    <tr>
                      <th>Banco</th>
                      <th>Cuentas</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cuentas.map((a) => (
                      <tr>
                         <td>
                          <Input
                            type="text"
                            name="banco"
                            value={a.banco}
                            key={a._id}
                            onChange={(e) => {
                              handleChangeInputCuentasExistentes(a._id, e);
                            }}
                          />
                        </td>
                        <td>
                          <Input
                            name="cuenta"
                            type="text"
                            value={a.cuenta}
                            required
                            // maxLength="4" // Limit to 4 digits
                            // pattern="\d{1,4}" 
                            onChange={(event) => {
                              handleChangeInputCuentasExistentes(a._id, event);
                            }}
                          />
                        </td>
                        <td>
                          {a.is_active == "Si"?(
                            <Button
                            size="sm"
                            className="btn"
                            color="danger"
                            onClick={() => bajaCuenta(a._id)}
                            tabindex="-1"
                          >
                            <i class="far fa-times-circle"></i>
                          </Button>
                          ):(
                            <Button
                            size="sm"
                            className="btn"
                            color="primary"
                            onClick={() => altaCuenta(a._id)}
                            tabindex="-1"
                          >
                            <i class="far fa-check-circle"></i>
                          </Button>
                          )}
                         
                        </td>
                      </tr>
                      // </div>
                    ))}

              {inputFieldsCuentas.map((a) => (
                      <tr>
                         <td>
                          <Input
                            type="text"
                            name="banco"
                            value={a.banco}
                            key={a.id}
                            onChange={(e) => {
                              handleChangeInputCuentas(a.id, e);
                            }}
                          />
                        </td>
                        <td>
                          <Input
                            name="cuenta"
                            type="text"
                            value={a.cuenta}
                            required
                            // maxLength="4" // Limit to 4 digits
                            // pattern="\d{1,4}" 
                            onChange={(event) => {
                              handleChangeInputCuentas(a.id, event);
                            }}
                          />
                        </td>
                        <td>
                          <Button
                            size="sm"
                            className="btn"
                            color="danger"
                            disabled={a.length === 1}
                            onClick={() => handleRemoveFieldsCuentas(a.id)}
                            tabindex="-1"
                          >
                            <i class="fas fa-minus"></i>
                          </Button>
                          <Button
                            size="sm"
                            className="btn"
                            color="info"
                            onClick={handleAddFieldsCuentas}
                            tabindex="-1"
                          >
                            <i class="fas fa-plus"></i>
                          </Button>
                        </td>
                      </tr>
                      // </div>
                    ))}

                  </tbody>
                  <br />
                </Table>
                <br />
                <Row>
                <Button className="btn btn-success" onClick={saveCuenta}>
                Registrar
              </Button>
                </Row>

        </ModalBody>
      </Modal>
        </div>

        
      ): undefined } 
    </>
  );
}

export default ListadoProveedores;
