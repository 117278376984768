import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useLayoutEffect,
  useMemo,
} from "react";
import { AuthContext } from "../../contexts/AuthContext";
import axios from "axios";
import Header from "../../layout/Header/Header";

import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Form,
  Card,
  CardHeader,
  CardText,
  CardBody,
  CardFooter,
  CardTitle,
} from "reactstrap";
import moment from "moment";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

function DashboardCXP() {
  const { user } = useContext(AuthContext);
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;


  const [graficas, setGraficas] = useState([]);
  const [proveedores, setProveedores] = useState([]); 
  const [selectedProveedor, setSelectedProveedor] = useState("");
 
  const [info, setInfo] = useState([]);
  const [cargando, setCargando] = useState(true);
  const [cargos, setCargos] = useState(0);
  const [abonos, setAbonos] = useState(0);
  const [saldo, setSaldo] = useState(0);


  const Toast = Swal.mixin({
    toast: true,
    position: "center",
    showConfirmButton: false,
    // timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  if (cargando) {
    Toast.fire({
      icon: "success",
      title: "Danos unos segundos....",
    });
  }
  
  useEffect(() => {
    axios
      .get(`${URL_PROVEEDORES}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let totalCargos = 0;
        let totalAbonos = 0;
        let totalSaldo = 0;

        let allGraficas = response.data;
        let arrayTabla = allGraficas
          .sort((a, b) => (a.saldo < b.saldo ? 1 : -1))
          .map((a) => {
            if(a.cargos != 0 || a.abonos != 0 || a.saldo != 0){
            totalCargos = totalCargos + parseFloat(a.cargos);
            totalAbonos = totalAbonos + parseFloat(a.abonos);
            totalSaldo = totalSaldo + parseFloat(a.saldo);
            return {
              idProveedor: a._id,
              Proveedor: a.nombre_comercial,
              Cargos: parseFloat(a.cargos),
              Abonos: parseFloat(a.abonos),
              Saldo: parseFloat(a.saldo),
            }
          }
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        setInfo(dataFinal);
        setCargos(totalCargos);
        setAbonos(totalAbonos);
        setSaldo(totalSaldo);

        let unicosProv = dataFinal.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.idProveedor === value.idProveedor)
        );
        setProveedores(unicosProv);

        setCargando(false);
        Toast.close();
      })
      .catch((err) => {
        console.log(err);
      });

     

  }, []);

  useMemo(()=>{
    let temp = info
    if (selectedProveedor) {
      temp = temp.filter((e) =>
        e.idProveedor.includes(selectedProveedor)
      );
    }


    setGraficas(temp)

    let TCar = temp.map((c) => parseFloat(c.Cargos));
    let TC = TCar.reduce((t, total, index) => t + total, 0);
    setCargos(TC);

    let TAbo = temp.map((c) => parseFloat(c.Abonos));
    let TA = TAbo.reduce((t, total, index) => t + total, 0);
    setAbonos(TA);

    let TSal = temp.map((c) => parseFloat(c.Saldo));
    let TS = TSal.reduce((t, total, index) => t + total, 0);
    setSaldo(TS);


  },[info, selectedProveedor])

  useLayoutEffect(() => {
    let root = am5.Root.new("chartdiv");

    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panY: false,
        layout: root.verticalLayout,
      })
    );

    let data = graficas;

    // Create Y-axis
    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        // min:0,
        renderer: am5xy.AxisRendererY.new(root, {}),
      })
    );

    // Create X-Axis
    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        renderer: am5xy.AxisRendererX.new(root, {}),
        categoryField: "Proveedor",
      })
    );
    xAxis.data.setAll(data);

    // Create series
    let series1 = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Saldo",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "Saldo",
        categoryXField: "Proveedor",
        tooltip: am5.Tooltip.new(root, {
          labelText: "{Proveedor} Saldo / ${valueY}",
        }),
      })
    );
    series1.data.setAll(data);

    // let series2 = chart.series.push(
    //   am5xy.ColumnSeries.new(root, {
    //     name: "Abonos",
    //     xAxis: xAxis,
    //     yAxis: yAxis,
    //     valueYField: "Abonos",
    //     categoryXField: "Proveedor",
    //     tooltip: am5.Tooltip.new(root, {
    //       labelText: "{Proveedor} Abonos / ${valueY}",
    //     }),
    //   })
    // );
    // series2.data.setAll(data);

    chart.set("cursor", am5xy.XYCursor.new(root, {}));

    let exporting = am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
    });

    // chart.set(
    //   "scrollbarX",
    //   am5.Scrollbar.new(root, {
    //     orientation: "horizontal",
    //   })
    // );

    return () => {
      root.dispose();
    };
  }, [graficas]);

  return (
    <>
      <Header />
      <br />
      <br />
      {/* {user.menu_reportes ? ( */}
      <div className="card container col-12">
        <Row>
        <Col md={8}>
              <Button
                size="sm"
                href="/MenuReportes"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
              
            </Col>
        </Row>
        <h3 align="center">Dashboard CXP</h3>
        <Row>
        <Col md={2}>
              <Label className="mr-sm-2">Proveedores</Label>
              <Input
                
                bsSize="sm"
                type="select"
                value={selectedProveedor}
                required
                onChange={(e) => {
                  setSelectedProveedor(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                {proveedores
                  .sort((a, b) => (a.Proveedor > b.Proveedor ? 1 : -1))
                  .map((a) => {
                    return <option value={a.idProveedor}>{a.Proveedor}</option>;
                  })}
              </Input>
            </Col>
        </Row>

      <div
        className="container"
        style={{ display: "flex", justifyContent: "center" }}
      >
        {/* <Col md={3}>
          <Card>
            <CardHeader tag="h3" align="center">
              TOTAL COMPRAS
            </CardHeader>
            <CardBody>
              <CardText tag="h4" align="center">
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 2,
                }).format(cargos)}
              </CardText>
            </CardBody>
          </Card>
        </Col>

        <Col md={3}>
          <Card>
            <CardHeader tag="h3" align="center">
              TOTAL ABONOS
            </CardHeader>
            <CardBody>
              <CardText tag="h4" align="center">
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 2,
                }).format(abonos)}
              </CardText>
            </CardBody>
          </Card>
        </Col> */}

        <Col md={3}>
          <Card>
            <CardHeader tag="h3" align="center">
              SALDO
            </CardHeader>
            <CardBody>
              <CardText tag="h4" align="center">
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 2,
                }).format(saldo)}
              </CardText>
            </CardBody>
          </Card>
        </Col>
      </div>

      
      <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>
      
      </div>

      {/*  ) : undefined} */}
    </>
  );
}
export default DashboardCXP;
