import React, { useContext } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import { Col, Row, Button } from "reactstrap";
import Footer from "../../layout/Footer/Footer";
import Header from "../../layout/Header/Header";

function BotonesProduccion() {
  const { user } = useContext(AuthContext);
  return (
    <>
          <Header />
          {user.menu_produccion ?(
      <div className="container">
        <br />
        <br />
          <Row>
          <Col md={4} align="center">
          {user.menu_produccion ?(
            <Button href="/ListadoProduccion" className="botonesMenu" color="success">
              <i class="fas fa-industry fa-7x"></i>
              <br />
              <br />
              Produccion
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-industry fa-7x"></i>
          <br />
          <br />
          Produccion
        </Button> }
          </Col>
          <Col md={4} align="center">
          {user.menu_produccion ?(
            <Button href="/ListadoEntradas" className="botonesMenu" color="success">
              <i class="fas fa-warehouse fa-7x"></i>
              <br />
              <br />
              Entradas
              <br />
              Almacen
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-warehouse fa-7x"></i>
          <br />
          <br />
          Entradas
          <br />
          Almacen
        </Button> }
          </Col>
         
        </Row>
      </div>
      ): undefined } 
      
    </>
  );
}

export default BotonesProduccion;
