import React, { useContext } from "react";
import { AuthContext } from '../../../../contexts/AuthContext'
import { Col, Row, Button } from "reactstrap";
import Header from "../../../../layout/Header/Header";

function BotonesCatalogosFacturas() {
  const { user } = useContext(AuthContext);
    return (
        <>
      <Header />
      <br />
      <br />
      {user.menu_catalogos ?(
      <div className="container">
        <Row>
          <Col md={4}>
            <Button href="/ConceptosDon" className="botonesMenu" color="success">
            <i class="fas fa-star-of-david fa-7x"></i>
              <br />
              <br />
              Conceptos
            </Button>
            </Col>
            <Col md={4}>
            <Button href="/EventosDon" className="botonesMenu" color="success">
            <i class="fas fa-torah fa-7x"></i>
              <br />
              <br />
              Eventos
            </Button>
          </Col>
          <Col md={4}>
            <Button href="/SalasDon" className="botonesMenu" color="success">
            <i class="fas fa-synagogue fa-7x"></i>
              <br />
              <br />
              Salas
            </Button>
            </Col>
            </Row>
            <br />
            <Row>
            <Col md={4}>
            <Button href="/InstitucionesDon" className="botonesMenu" color="success">
            <i class="fas fa-hotel fa-7x"></i>
              <br />
              <br />
              Instituciones
            </Button>
            </Col>
            <Col md={4}>
            <Button href="/Zonas" className="botonesMenu" color="success">
            <i class="fas fa-map-marked-alt fa-7x"></i>
              <br />
              <br />
              Zonas
            </Button>
            </Col>
        </Row>
      </div>
       ): undefined } 
    </>
    )
}

export default BotonesCatalogosFacturas
