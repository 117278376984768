import React, { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Col, Row, Button } from "reactstrap";
import Header from "../../layout/Header/Header";

function BotonesClientes() {
  const { user } = useContext(AuthContext);
  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_facturas ? (
      <div className="container">
          <Row>
            <Col md={4} align="center">
              {user.menu_clientes ? (
                <Button
                  href="/Clientes"
                  className="botonesMenu"
                  color="success"
                >
                  <i class="fas fa-user-tie fa-7x"></i>
                  <br />
                  <br />
                  Clientes
                </Button>
              ) : (
                <Button disabled className="botonesMenu" color="success">
                  <i class="fas fa-user-tie fa-7x"></i>
                  <br />
                  <br />
                  Clientes
                </Button>
              )}
            </Col>
            <Col md={4} align="center">
              {user.facturas_edo_cta ? (
                <Button
                  href="/EdoCtaClientes"
                  className="botonesMenu"
                  color="success"
                >
                  <i class="fas fa-file-invoice fa-7x"></i>
                  <br />
                  <br />
                  Estado de Cuenta
                </Button>
              ) : (
                <Button disabled className="botonesMenu" color="success">
                  <i class="fas fa-file-invoice fa-7x"></i>
                  <br />
                  <br />
                  Estado de Cuenta
                </Button>
              )}
            </Col>
             <Col md={4} align="center">
              {user.facturas_edo_cta ? (
                <Button
                  href="/ListadoCobranza"
                  className="botonesMenu"
                  color="success"
                >
                  <i class="far fa-money-bill-alt fa-7x"></i>
                  <br />
                  <br />
                  Cobranza
                </Button>
              ) : (
                <Button disabled className="botonesMenu" color="success">
                  <i class="far fa-money-bill-alt fa-7x"></i>
                  <br />
                  <br />
                  Cobranza
                </Button>
              )}
            </Col>
            </Row>
            <br />
            <Row>
              <Col md={4} align="center">
              {user.facturas_cargos ? (
                <Button
                  href="/DetalleNotas"
                  className="botonesMenu"
                  color="success"
                >
                  <i class="fas fa-file-invoice-dollar fa-7x"></i>
                  <br />
                  <br />
                  Notas
                </Button>
              ) : (
                <Button disabled className="botonesMenu" color="success">
                  <i class="fas fa-file-invoice-dollar fa-7x"></i>
                  <br />
                  <br />
                  Notas
                </Button>
              )}
            </Col>
            <Col md={4} align="center">
              {user.facturas_abonos ? (
                <Button
                  href="/AbonosClientes"
                  className="botonesMenu"
                  color="success"
                >
                  <i class="fas fa-donate fa-7x"></i>
                  <br />
                  <br />
                  Abonos
                </Button>
              ) : (
                <Button disabled className="botonesMenu" color="success">
                  <i class="fas fa-donate fa-7x"></i>
                  <br />
                  <br />
                  Abonos
                </Button>
              )}
            </Col>
             <Col md={4} align="center">
            <Button href="/Zonas" className="botonesMenu" color="success">
            <i class="fas fa-map-marked-alt fa-7x"></i>
              <br />
              <br />
              Zonas
            </Button>
            </Col>
          </Row>
          <br />
      </div>
      ) : undefined}
    </>
  );
}

export default BotonesClientes;
