import React, { useState, useEffect, useContext, useMemo } from "react";
import { useParams } from 'react-router-dom';
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";

function ListadoSeguimiento() {
  const { user } = useContext(AuthContext);
  const { id } = useParams();
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  const URL_PRODUCCION = process.env.REACT_APP_URL_PRODUCCION;
  const URL_INSUMOS_PRODUCCION = process.env.REACT_APP_URL_INSUMOS_PRODUCCION;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;

  const [selectedRollo, setSelectedRollo] = useState("");
  const [info, setInfo] = useState([]);
  const [insumos, setInsumos] = useState([]);
  const [procesos, setProcesos] = useState([]);
  const [presentarInfo, setPresentarInfo] = useState(false);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  useEffect(() => {
    // Set the selectedRollo when the component mounts
    setSelectedRollo(id);
  }, []);

  useMemo(() => {
    if (selectedRollo) {
     axios.get(`${URL_INVENTARIOS}Historial/${selectedRollo}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
     })
     .then((res) => {
      setInfo(res.data)

      let pedido = res.data.pedidos[0]._id

      axios.get(`${URL_PRODUCCION}Pedido/${pedido}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        setProcesos(res.data)
        setPresentarInfo(true)

        const produccionesIds = res.data.map((proceso) => proceso._id);

        axios.get(`${URL_INSUMOS_PRODUCCION}Producciones`, {
          params: {
            produccionesIds: produccionesIds.join(','),
          },
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((res) => {
          setInsumos(res.data)  
        })
        .catch((err) => console.log(err))

      })
      .catch((err) => console.log(err))
    })
     .catch((err) => console.log(err))
    }
    
  }, [selectedRollo]);


  // function PDFTabla() {

  //   const data2 = comments
  //   .filter(
  //     (comment) =>
  //       comment.destino.toLowerCase().includes(search.toLowerCase()) ||
  //       comment.color.toLowerCase().includes(search.toLowerCase()) ||
  //       comment.tipoMov.toLowerCase().includes(search.toLowerCase())
  //   )
  //   .map((a) => {
  //     if (
  //       (selectedColor == 0 || selectedColor == a.color) &&
  //       (selectedPantone == 0 || selectedPantone == a.pantone) &&
  //       (selectedArticulo == 0 || selectedArticulo == a.idArticulo)
  //     ){
  //     return [
  //       a.fecha,
  //       a.tipoMov,
  //       a.numMov,
  //       a.destino,
  //       a.color,
  //       new Intl.NumberFormat("en-US").format(a.cantidad),
  //     ];}
  //   });
  //   const dataPDF2 = Object.values(data2);
  //   const dataPDFLimpia2 = dataPDF2.filter(function (el) {
  //     return el != null;
  //   });
  
  //   const doc = new jsPDF("landscape");
  //   var img = new Image();
  //   img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
  //   doc.addImage(img, "png", 230, 30, 45, 15);
  //   var img2 = new Image();
  //   img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
  //   doc.addImage(img2, "png", 120, 15, 60, 25);
  //   doc.text(`Seguimiento`, 20, 40);
   
  //   doc.autoTable({
  //     head: [
  //       ["Fecha", "Movimiento", "Numero", "Cliente/Prov", "Color", "Cantidad"],
  //     ],
  //     body: dataPDFLimpia2,
  //     startY: 50,
  //     foot: [["", "", "", "", "", ""]],
  //     showFoot: "lastPage",
  //   });

  //   //////
  //   doc.save(`Seguimiento.pdf`);
  // }

  // function excel() {
  //   const dataExcel = comments
  //     .filter(
  //       (comment) =>
  //         comment.destino.toLowerCase().includes(search.toLowerCase()) ||
  //         comment.color.toLowerCase().includes(search.toLowerCase()) ||
  //         comment.tipoMov.toLowerCase().includes(search.toLowerCase())
  //     )
  //     .map((a) => {
  //       if (
  //         (selectedColor == 0 || selectedColor == a.color) &&
  //         (selectedPantone == 0 || selectedPantone == a.pantone) &&
  //         (selectedArticulo == 0 || selectedArticulo == a.idArticulo)
  //       ) {
  //         return {
  //           Fecha: a.fecha,
  //           Movimiento: a.tipoMov,
  //           Numero: a.numMov,
  //           Destino: a.destino,
  //           Color: a.color,
  //           Cantidad: a.cantidad,
  //         };
  //       }
  //     });
  //   const dataExportExcel = Object.values(dataExcel);
  //   const dataExcelLimpia = dataExportExcel.filter(function (el) {
  //     return el != null;
  //   });

  //   var option = {};

  //   option.fileName = "ListadoSeguimiento";

  //   option.datas = [
  //     {
  //       sheetData: dataExcelLimpia,
  //       sheetName: "ListadoSeguimiento",
  //       sheetFilter: [
  //         "Fecha",
  //         "Movimiento",
  //         "Numero",
  //         "Destino",
  //         "Color",
  //         "Cantidad",
  //       ],
  //       sheetHeader: [
  //         "Fecha",
  //         "Movimiento",
  //         "Numero",
  //         "Destino",
  //         "Color",
  //         "Cantidad",
  //       ],
  //     },
  //   ];

  //   var toExcel = new ExportJsonExcel(option);
  //   toExcel.saveExcel();
  // }

  // function enviaMail() {
  //   const data2 = comments
  //   .filter(
  //     (comment) =>
  //       comment.destino.toLowerCase().includes(search.toLowerCase()) ||
  //       comment.color.toLowerCase().includes(search.toLowerCase()) ||
  //       comment.tipoMov.toLowerCase().includes(search.toLowerCase())
  //   )
  //   .map((a) => {
  //     if (
  //       (selectedColor == 0 || selectedColor == a.color) &&
  //       (selectedPantone == 0 || selectedPantone == a.pantone) &&
  //       (selectedArticulo == 0 || selectedArticulo == a.idArticulo)
  //     ){
  //     return [
  //       a.fecha,
  //       a.tipoMov,
  //       a.numMov,
  //       a.destino,
  //       a.color,
  //       new Intl.NumberFormat("en-US").format(a.cantidad),
  //     ]}
  //   });
  //   const dataPDF2 = Object.values(data2);
  //   const dataPDFLimpia2 = dataPDF2.filter(function (el) {
  //     return el != null;
  //   });
  
  //   const doc = new jsPDF("landscape");
  //   var img = new Image();
  //   img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
  //   doc.addImage(img, "png", 230, 30, 45, 15);
  //   var img2 = new Image();
  //   img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
  //   doc.addImage(img2, "png", 120, 15, 60, 25);
  //   doc.text(`Seguimiento`, 20, 40);
   
  //   doc.autoTable({
  //     head: [
  //       ["Fecha", "Movimiento", "Numero", "Cliente/Prov", "Color", "Cantidad"],
  //     ],
  //     body: dataPDFLimpia2,
  //     startY: 50,
  //     foot: [["", "", "", "", "", ""]],
  //     showFoot: "lastPage",
  //   });
  //   //////

  //   var att = doc.output("arraybuffer");
  //   var base64File = encode(att);

  //   // Envia el Mail
  //   axios
  //     .post(
  //       URL_SEND_MAIL_ATT,
  //       {
  //         subject: "Listado Seguimiento",
  //         email: mailTo,
  //         fileName: "ListadoSeguimiento.pdf",
  //         att: base64File,
  //         text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
  //        <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
  //        <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
  //        <tbody>
  //        <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
  //        <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Disponibles.</tr>
  //        </tbody>
  //        <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
  //        <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
  //        </table>`,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
  //         },
  //       }
  //     )
  //     .then(() => {
  //       toggleMail();
  //       Swal.fire({
  //         position: "center",
  //         icon: "success",
  //         title: "Enviado con Exito!",
  //         showConfirmButton: false,
  //         timer: 2000,
  //       });
  //       setMailTo("")
  //     })
  //     .catch((error) => {
  //       Swal.fire({
  //         icon: "error",
  //         title: "Oops...",
  //         text: "Something went wrong!",
  //         footer: `${error.response.data}`,
  //       });
  //       console.log(error);
  //     });
  // }


  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_inventarios ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              {/* <Button
                size="sm"
                href="/MenuInventarios"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button> */}
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  // onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  // onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  // onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Seguimiento</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" 
              // onClick={enviaMail}
              >
                Enviar
              </Button>
            </ModalFooter>
          </Modal>

          <h3 align="center">Historial de Rollo</h3>

          <Row>
            <Col md={3}>
            <Label>ID del Rollo a Buscar</Label>
              <Input
              type="text"
              value={selectedRollo}
              onChange={(e) => {
                setSelectedRollo(e.target.value);
              }} />
            </Col>
          </Row>
          

          <br />
          {presentarInfo ? (
  <div className="card container col-8">
    <h4>Informacion del Rollo</h4>
    <h4>Vendido: {info.vendido}</h4>
    <br />
    <Table>
      <tbody>
        <tr>
          <th>Articulo</th>
          <td>{info.articulos[0].nombre}</td>
        </tr>
        <tr>
          <th>Color</th>
          <td>{info.colores}</td>
        </tr>
        <tr>
          <th>Kilos</th>
          <td>{info.cantidad}</td>
        </tr>

        <br />
        <tr>
          <th>Proceso</th>
          <th>Maquina</th>
          <th>Operador</th>
          <th>Inicio</th>
          <th>Fin</th>
        </tr>
        {procesos.map((p) => (
          <tr key={p._id}> {/* Added key prop for React performance */}
            <td>{p.procesos[0].name}</td>
            <td>{p.maquinas[0].nombre}</td>
            <td>{p.operador[0].nombre} {p.operador[0].apellido}</td>
            <td>{p.fechaInicio}</td>
            <td>{p.fechaFin}</td>
          </tr>
        ))}

        <br />
        <tr>
          <th>Insumo</th>
        </tr>
        {insumos.map((p) => (
          <tr key={p._id}> {/* Added key prop for React performance */}
            <td>{p.insumos[0].nombre} {p.insumos[0].descripcion}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  </div>
) : undefined}

        </div>
      ) : undefined}
    </>
  );
}

export default ListadoSeguimiento;
