import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import ClientesInfo from "../Clientes/ClientesInfo";
import FacturasInfo from "./FacturasInfo";

function TablaFacturas(props) {
  const { user } = useContext(AuthContext);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [modalSaldo, setModalSaldo] = useState(false);
  const toggleSaldo = () => setModalSaldo(!modalSaldo);

  let idCliente = props.idCliente
  

  return (
    <>
      <td>{props.nombre} {props.apellido} </td>
      <td align="center">{props.ultimo_pago}</td>
      <td align="center">{props.fecha_visita}</td>
      <td>{props.cobrador}</td>
      <td align="center">
        {"$" + new Intl.NumberFormat("en-US").format(props.saldo)}{" "}
      </td>
      <td align="center">{props.forma_pago}</td>
      <td align="center">
        {user.clientes_edo_cta ?(
        <Button color="info" size="sm" onClick={toggleSaldo}>
          <i class="fas fa-file-alt"></i>
        </Button>
        ):(
          <Button color="info" size="sm" disabled>
          <i class="fas fa-file-alt"></i>
        </Button>
        )}
      </td>
      <Modal size="xl" isOpen={modalSaldo} toggle={toggleSaldo}>
  <ModalHeader toggle={toggleSaldo}><h4>Estado de Cuenta de {props.nombre} {props.apellido} </h4></ModalHeader>
        <ModalBody>
          <FacturasInfo idCliente={idCliente} email={props.email} nombre={props.nombre} apellido={props.apellido}/>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={toggleSaldo}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>


      <td align="center">
        <Button color="info" size="sm" onClick={toggle}>
          <i class="fas fa-file-alt"></i>
        </Button>
      </td>
      <Modal size="sm" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}><h4>Informacion del Cliente</h4></ModalHeader>
        <ModalBody>
          <ClientesInfo idCliente={idCliente} />
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={toggle}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default TablaFacturas;

