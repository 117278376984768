import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useLayoutEffect,
  useMemo,
} from "react";
import { AuthContext } from "../../contexts/AuthContext";
import axios from "axios";
import Header from "../../layout/Header/Header";

import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Form,
  Card,
  CardHeader,
  CardText,
  CardBody,
} from "reactstrap";
import moment from "moment";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import * as am5percent from "@amcharts/amcharts5/percent";

function DashboardInventarios() {
  const { user } = useContext(AuthContext);
  const URL_DASHBOARDS = process.env.REACT_APP_URL_DASHBOARDS;


  const [info, setInfo] = useState([]);

  const [graficasColores, setGraficasColores] = useState([]);
  const [graficasArticulos, setGraficasArticulos] = useState([]);
  const [graficasTiposTelas, setGraficasTiposTelas] = useState([]);
  const [valorInventario, setValorInventario] = useState(0);
  const [cantidadInventario, setCantidadInventario] = useState(0);

  const [colores, setColores] = useState([]);
  const [articulos, setArticulos] = useState([]);
  const [tiposTelas, setTiposTelas] = useState([]);

  const [selectedColor, setSelectedColor] = useState("");
  const [selectedArticulo, setSelectedArticulo] = useState("");
  const [selectedTiposTela, setSelectedTiposTela] = useState("");
  

  const [cargando, setCargando] = useState(true);

  const Toast = Swal.mixin({
    toast: true,
    position: "center",
    showConfirmButton: false,
    // timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  if (cargando) {
    Toast.fire({
      icon: "success",
      title: "Danos unos segundos....",
    });
  }

useEffect(()=>{
  axios
  .get(`${URL_DASHBOARDS}Inventarios`, {
    headers: {
      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    },
  })
  .then((response) => {
    let allDisponible = response.data;

      setInfo(allDisponible)
      
    

    let unicosCol = allDisponible.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.idColor === value.idColor)
    );
    setColores(unicosCol);

    let unicosArt = allDisponible.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.idArticulo === value.idArticulo)
    );
    setArticulos(unicosArt);

    let unicosLin = allDisponible.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.idTiposTela === value.idTiposTela)
    );
    setTiposTelas(unicosLin);

    setCargando(false);
    Toast.close();
  })
  .catch((err) => {
    console.log(err);
  });

},[])


  useMemo(() => {
      let temp = info
      if (selectedColor) {
        temp = temp.filter((e) =>
          e.idColor.includes(selectedColor)
        );
      }
      if (selectedTiposTela) {
        temp = temp.filter((e) =>
          e.idTiposTela.includes(selectedTiposTela)
        );
      }
      if (selectedArticulo) {
        temp = temp.filter((e) =>
          e.idArticulo.includes(selectedArticulo)
        );
      }

      let agrupadoColor = temp.reduce(function (groups, item) {
        const val = item["idColor"];
        groups[val] = groups[val] || {
          idColor: item.idColor,
          cantidad: 0,
        };
        groups[val].cantidad += item.cantidad;
        groups[val].color = item.color;
        groups[val].idColor = item.idColor;
        return groups;
      }, []);

      let otraColor = Object.values(agrupadoColor).sort((a, b) =>
        a.cantidad < b.cantidad ? 1 : -1
      );

      setGraficasColores(otraColor);

      let agrupadoArticulo = temp.reduce(function (groups, item) {
        const val = item["idArticulo"];
        groups[val] = groups[val] || {
          idArticulo: item.idArticulo,
          cantidad: 0,
        };
        groups[val].cantidad += item.cantidad;
        groups[val].articulo = item.articulo;
        groups[val].idArticulo = item.idArticulo;
        return groups;
      }, []);

      let otraArticulo = Object.values(agrupadoArticulo).sort((a, b) =>
        a.cantidad < b.cantidad ? 1 : -1
      );

      setGraficasArticulos(otraArticulo);

      let agrupadoTiposTela = temp.reduce(function (groups, item) {
        const val = item["idTiposTela"];
        groups[val] = groups[val] || {
          idTiposTela: item.idTiposTela,
          cantidad: 0,
        };
        groups[val].cantidad += item.cantidad;
        groups[val].tiposTela = item.tiposTela;
        groups[val].idTiposTela = item.idTiposTela;
        return groups;
      }, []);

      let otraTiposTela = Object.values(agrupadoTiposTela).sort((a, b) =>
        a.cantidad < b.cantidad ? 1 : -1
      );

      setGraficasTiposTelas(otraTiposTela);


        let TVal = temp.map((c) => parseFloat(c.valor));
        let TV = TVal.reduce((t, total, index) => t + total, 0);
        setValorInventario(TV);

        let TCan = temp.map((c) => parseFloat(c.cantidad));
        let TC = TCan.reduce((t, total, index) => t + total, 0);
        setCantidadInventario(TC);

  }, [info, selectedColor, selectedTiposTela, selectedArticulo]);


  useLayoutEffect(() => {
    
     // Grafica Colores
     let root = am5.Root.new("chartdiv");

     root.setThemes([am5themes_Animated.new(root)]);
 
     let chart = root.container.children.push(
       am5percent.PieChart.new(root, {
         endAngle: 270,
         layout: root.verticalLayout,
         tooltip: am5.Tooltip.new(root, {
           labelText: "{color} {value}",
         }),
       })
     );
 
     let series = chart.series.push(
       am5percent.PieSeries.new(root, {
         valueField: "cantidad",
         categoryField: "color",
         endAngle: 270,
       })
     );
 
     series.states.create("hidden", {
       endAngle: -90,
     });
 
     chart.children.unshift(
       am5.Label.new(root, {
         text: "Colores",
         fontSize: 25,
         fontWeight: "500",
         textAlign: "center",
         x: am5.percent(50),
         centerX: am5.percent(50),
         paddingTop: 0,
         paddingBottom: 0,
       })
     );
 
     series.labels.template.set("forceHidden", true);
     series.ticks.template.setAll({
       visible: false,
       stroke: am5.color("rgb(252, 252, 252)"),
     });
     series.data.setAll(graficasColores);
     series.appear(1000, 100);
 
     // let legend = chart.children.push(
     //   am5.Legend.new(root, {
     //     nameField: "color",
     //   })
     // );
 
     // legend.data.setAll(series.dataItems);
 
 // Grafica Articulos
 let rootArticulos = am5.Root.new("chartdivArticulos");
 
 rootArticulos.setThemes([am5themes_Animated.new(rootArticulos)]);
 
 let chartArticulos = rootArticulos.container.children.push(
   am5percent.PieChart.new(rootArticulos, {
     endAngle: 270,
     layout: rootArticulos.verticalLayout,
     tooltip: am5.Tooltip.new(rootArticulos, {
       labelText: "{articulo} {value}",
     }),
   })
 );
 
 let seriesArticulos = chartArticulos.series.push(
   am5percent.PieSeries.new(rootArticulos, {
     valueField: "cantidad",
     categoryField: "articulo",
     endAngle: 270,
   })
 );
 
 seriesArticulos.states.create("hidden", {
   endAngle: -90,
 });
 
 chartArticulos.children.unshift(
   am5.Label.new(rootArticulos, {
     text: "Articulos",
     fontSize: 25,
     fontWeight: "500",
     textAlign: "center",
     x: am5.percent(50),
     centerX: am5.percent(50),
     paddingTop: 0,
     paddingBottom: 0,
   })
 );
 
 seriesArticulos.labels.template.set("forceHidden", true);
 seriesArticulos.ticks.template.setAll({
   visible: false,
   stroke: am5.color("rgb(252, 252, 252)"),
 });
 seriesArticulos.data.setAll(graficasArticulos);
 seriesArticulos.appear(1000, 100);
 
 // let legendArticulos = chartArticulos.children.push(
 //   am5.Legend.new(rootArticulos, {
 //     nameField: "articulo",
 //   })
 // );
 
 // legendArticulos.data.setAll(seriesArticulos.dataItems);
 
 
     // Grafica TiposTelas
     let rootTiposTelas = am5.Root.new("chartdivTiposTelas");
 
     rootTiposTelas.setThemes([am5themes_Animated.new(rootTiposTelas)]);
 
     let chartTiposTelas = rootTiposTelas.container.children.push(
       am5percent.PieChart.new(rootTiposTelas, {
         endAngle: 270,
         layout: rootTiposTelas.verticalLayout,
         tooltip: am5.Tooltip.new(rootTiposTelas, {
           labelText: "{tiposTela} {value}",
         }),
       })
     );
 
     let seriesTiposTelas = chartTiposTelas.series.push(
       am5percent.PieSeries.new(rootTiposTelas, {
         valueField: "cantidad",
         categoryField: "tiposTela",
         endAngle: 270,
       })
     );
 
     seriesTiposTelas.states.create("hidden", {
       endAngle: -90,
     });
 
     chartTiposTelas.children.unshift(
       am5.Label.new(rootTiposTelas, {
         text: "Tipos de Telas",
         fontSize: 25,
         fontWeight: "500",
         textAlign: "center",
         x: am5.percent(50),
         centerX: am5.percent(50),
         paddingTop: 0,
         paddingBottom: 0,
       })
     );
 
     seriesTiposTelas.labels.template.set("forceHidden", true);
     seriesTiposTelas.ticks.template.setAll({
       visible: false,
       stroke: am5.color("rgb(252, 252, 252)"),
     });
     seriesTiposTelas.data.setAll(graficasTiposTelas);
     seriesTiposTelas.appear(1000, 100);
 
     let legendTiposTelas = chartTiposTelas.children.push(
       am5.Legend.new(rootTiposTelas, {
         nameField: "tiposTela",
       })
     );
 
     legendTiposTelas.data.setAll(seriesTiposTelas.dataItems);
 

    return () => {
      root.dispose();
      rootTiposTelas.dispose();
      rootArticulos.dispose();
    };
  }, [graficasColores, graficasTiposTelas,  graficasArticulos]);

  return (
    <>
      <Header />
      <br />
      <br />
      {/* {user.menu_cantidad ? ( */}
      <div className="card container col-12">
        <Row>
          <Col md={8}>
            <Button
              size="sm"
              href="/MenuReportes"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Col>
        </Row>
        <h3 align="center">Dashboard Inventarios</h3>
<Row>
          <Col md={2}>
              <Label className="mr-sm-2">Articulos</Label>
              <Input
                
                bsSize="sm"
                type="select"
                value={selectedArticulo}
                required
                onChange={(e) => {
                  setSelectedArticulo(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                {articulos
                  .sort((a, b) => (a.articulo > b.articulo ? 1 : -1))
                  .map((a) => {
                    return <option value={a.idArticulo}>{a.articulo}</option>;
                  })}
              </Input>
            </Col>

          
                <Col md={2}>
              <Label className="mr-sm-2">Colores</Label>
              <Input
                
                bsSize="sm"
                type="select"
                value={selectedColor}
                required
                onChange={(e) => {
                  setSelectedColor(e.target.value);
                }}
              >
                <option value="">Seleciona</option>
                {colores
                  .sort((a, b) => (a.color > b.color ? 1 : -1))
                  .map((a) => {
                    return <option value={a.idColor}>{a.color}</option>;
                  })}
              </Input>
            </Col>


            <Col md={2}>
              <Label className="mr-sm-2">TiposTelas</Label>
              <Input
                
                bsSize="sm"
                type="select"
                value={selectedTiposTela}
                required
                onChange={(e) => {
                  setSelectedTiposTela(e.target.value);
                }}
              >
                <option value="">Seleciona</option>
                {tiposTelas
                  .sort((a, b) => (a.tiposTela > b.tiposTela ? 1 : -1))
                  .map((a) => {
                    return <option value={a.idTiposTela}>{a.tiposTela}</option>;
                  })}
              </Input>
            </Col>


        </Row> 

        <div
        className="container"
        style={{ display: "flex", justifyContent: "center" }}
        a
      >
        <Col md={3}>
          <Card>
            <CardHeader tag="h3" align="center">
              Cantidad
            </CardHeader>
            <CardBody>
              <CardText tag="h4" align="center">
                {new Intl.NumberFormat("en-US", {}).format(cantidadInventario)}
              </CardText>
            </CardBody>
          </Card>
        </Col>

        <Col md={3}>
          <Card>
            <CardHeader tag="h3" align="center">
              Valor 
            </CardHeader>
            <CardBody>
              <CardText tag="h4" align="center">
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 2,
                }).format(valorInventario)}
              </CardText>
            </CardBody>
          </Card>
        </Col>
      </div>

      <div
          // className="container"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div id="chartdivArticulos" style={{ width: "40%", height: "500px" }}></div>
        </div>
<br />
<br />

      
        <div
          // className="container"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div id="chartdiv" style={{ width: "40%", height: "500px" }}></div>
          <div
            id="chartdivTiposTelas"
            style={{ width: "40%", height: "500px" }}
          ></div>
        </div>

      </div>


      {/* ) : undefined}  */}
    </>
  );
}
export default DashboardInventarios;
