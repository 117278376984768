import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import moment from 'moment';

function ListadoProduccion() {
  const { user } = useContext(AuthContext);
  const URL_PRODUCCION = process.env.REACT_APP_URL_PRODUCCION;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;

  let hoy = new Date()
  let inicio = new Date()
  inicio.setDate(inicio.getDate()-30);
 
  let endDate   = moment(hoy).format("YYYY-MM-DD"); 
  let startDate   = moment(inicio).format("YYYY-MM-DD");

  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

 
  const [validaBoton, setValidaBoton] = useState(true);
  const [text, setText] = useState(false);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modalCerrar, setModalCerrar] = useState(false);
  const toggleCerrar = () => setModalCerrar(!modalCerrar);
  const [idCerrar, setIdCerrar] = useState("");
  const [cantidadInicial, setCantidadInicial] = useState("");
  const [cantidadFinal, setCantidadFinal] = useState("");



  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(()=>{
    axios
      .get(`${URL_PRODUCCION}Fechas/${selectedFechaInicio}/${selectedFechaFin}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allVentas = response.data;
        console.log(allVentas);
        // Array para pagination
        let arrayTabla = allVentas
          .sort((a, b) => (a.idProduccion < b.idProduccion ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              numero: a.idProduccion,
              fecha: a.fecha,
              clientes: a.clientes[0].nombre_comercial,
              idClientes: a.clientes[0]._id,
              operador: a.operador[0].nombre + " " + a.operador[0].apellido,
              idOperador: a.operador[0]._id,
              procesos: a.procesos[0].name,
              idProcesos: a.procesos[0]._id,
              maquinas: a.maquinas[0].nombre,
              idMaquinas: a.maquinas[0]._id,
              observaciones: a.observaciones,
              fechaInicio: a.fechaInicio,
              fechaFin: a.fechaFin,
              cantidadInicial: a.cantidadInicial,
              cantidadFinal: a.cantidadFinal,
              status: a.status,
              pedido: a.pedidos[0].idPedido,
            }
          })

        let data = Object.values(arrayTabla);

        setComments(data);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });


  }, [ selectedFechaInicio, selectedFechaFin]);


  function PDFTabla() {
    let TotalImporte= 0
    const data = comments
    .filter(
      (comment) =>
        comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
        comment.bancos.toLowerCase().includes(search.toLowerCase()) ||
        comment.formaPago.toLowerCase().includes(search.toLowerCase()) ||
        comment.observaciones.toLowerCase().includes(search.toLowerCase()) ||
        comment.importe.toString().includes(search) ||
        comment.numero.toString().includes(search) 
    )
    .map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes) 
      ) {
        TotalImporte = TotalImporte + a.importe
        return [
          a.numero,
          a.fecha,
          a.clientes,
          a.bancos,
          a.formaPago,
          a.observaciones,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.importe),
        ];
      }
    });

    const dataPDF = Object.values(data);

    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });


    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Listado Produccion`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Numero",
          "Fecha",
          "Cliente",
          "Banco",
          "Forma Pago",
          "Observaciones",
          "Importe",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "",
          "",
          "",
          "Total",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(TotalImporte)
        ]
      ]
    });
    doc.save(`Produccion.pdf`);
  }

  function excel() {
    const dataExcel = comments
    .filter(
      (comment) =>
        comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
        comment.bancos.toLowerCase().includes(search.toLowerCase()) ||
        comment.formaPago.toLowerCase().includes(search.toLowerCase()) ||
        comment.importe.toString().includes(search) ||
        comment.numero.toString().includes(search) 
    )
    .map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes) 
      ) {
        return {
          Numero: a.numero,
          Fecha: a.fecha,
          Cliente: a.clientes,
          Bancos: a.bancos,
          FormaPago: a.formaPago,
          Observaciones: a.observaciones,
          Nota: a.nota,
          Importe: a.importe,
          // TotalNota: a.totalNota,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoProduccion";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoProduccion",
        sheetFilter: [
          "Numero",
          "Fecha",
          "Cliente",
          "Bancos",
          "FormaPago",
          "Observaciones",
          "Nota",
          "Importe",
          // "TotalNota"
          ],
        sheetHeader: [
          "Numero",
          "Fecha",
          "Cliente",
          "Bancos",
          "FormaPago",
          "Observaciones",
          "Nota",
          "Importe",
          // "TotalNota"
          ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    let TotalImporte= 0
    const data = comments
    .filter(
      (comment) =>
        comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
        comment.bancos.toLowerCase().includes(search.toLowerCase()) ||
        comment.formaPago.toLowerCase().includes(search.toLowerCase()) ||
        comment.observaciones.toLowerCase().includes(search.toLowerCase()) ||
        comment.importe.toString().includes(search) ||
        comment.numero.toString().includes(search) 
    )
    .map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes) 
      ) {
        TotalImporte = TotalImporte + a.importe
        return [
          a.numero,
          a.fecha,
          a.clientes,
          a.bancos,
          a.formaPago,
          a.observaciones,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.importe),
        ];
      }
    });

    const dataPDF = Object.values(data);

    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });


    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Listado Produccion `, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Numero",
          "Fecha",
          "Cliente",
          "Banco",
          "Forma Pago",
          "Observaciones",
          "Importe",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "",
          "",
          "",
          "Total",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(TotalImporte)
        ]
      ]
    });
    
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Produccion",
          email: mailTo,
          fileName: "ListadoProduccion.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Produccion.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Enviado con Exito!",
          showConfirmButton: false,
          timer: 2000,
        });
        setMailTo("")
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

 

  async function PDFOC(
    fecha,
    clientes,
    importe,
    nota,
    formaPago,
    observaciones,
    numero
  ) {

       
        const doc = new jsPDF();
        var img = new Image();
        img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        doc.addImage(img, "png", 150, 10, 35, 10);
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        doc.addImage(img2, "png", 95, 15, 30, 30);
        doc.setFontSize(16);
        doc.text(`${process.env.REACT_APP_NOMBRE_INSTITUCION}`, 90, 53);
        doc.text(`___________________`, 80, 100);
        doc.text(`Firma`, 100, 110);
      
        doc.autoTable({
          head: [
            [
              "No. Recibo",
              "Fecha",
              "Cliente",
              "Nota",
              "Forma Pago",
              "Observaciones",
              "Importe",
            ],
          ],
          body: [[
            numero,
            fecha,
            clientes,
            nota,
            formaPago,
            observaciones,
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(importe)
          ]],
          startY: 60,
        
        });

        doc.save(`Produccion-${numero}.pdf`);

  }



  const headers = [
    { name: "Numero", field: "numero", sortable: true },
    { name: "Fecha", field: "fecha", sortable: true },
    { name: "Cliente", field: "clientes", sortable: true },
    { name: "Pedido", field: "pedido", sortable: true },
    { name: "Operador", field: "operador", sortable: true },
    { name: "Proceso", field: "procesos", sortable: true },
    { name: "Maquinas", field: "maquinas", sortable: true },
    { name: "Cantidad Inicial", field: "Cantidad Inicinal", sortable: true },
    { name: "Cantidad Final", field: "Cantidad Final", sortable: true },
    { name: "Status", field: "status", sortable: true },
    { name: "Acciones", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
          comment.operador.toLowerCase().includes(search.toLowerCase()) ||
          comment.procesos.toLowerCase().includes(search.toLowerCase()) ||
          comment.maquinas.toLowerCase().includes(search.toLowerCase()) ||
          comment.pedido.toString().includes(search)  ||
          comment.cantidadInicial.toString().includes(search)  ||
          comment.cantidadFinal.toString().includes(search) 
      );
    }
    if (selectedCliente) {
      computedComments = computedComments.filter((e) =>
        e.idClientes.includes(selectedCliente)
      );
    }

  

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "pedido" &&
      sorting.field != "cantidadInicial" &&
      sorting.field != "cantidadFinal"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "cantidadInicial" ||
      sorting.field == "pedido" ||
        sorting.field == "cantidadFinal")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "cantidadInicial" ||
      sorting.field == "pedido" ||
      sorting.field == "cantidadFinal")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedCliente,
    selectedFechaInicio,
    selectedFechaFin,
  ]);

  function Cierre() {
    Swal.fire({
      title: "Estas seguro?",
      text: "Se cerrara el proceso!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Cerrar!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .patch(
            `${URL_PRODUCCION}/${idCerrar}`,
            {
              cantidadFinal,
              status:"Cerrado"
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then(() => {
            axios.post(
              URL_LOGS,
              {
                tipo: "Cerrar Produccion",
                detalle: `${idCerrar}`,
                user: user.id,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            );
            Swal.fire("Good job!", "Creado con exito", "success");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  };

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_produccion ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              {user.menu_produccion ? (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/ProduccionCreate"
                >
                  Nuevo
                </Button>
              ) : (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  Nuevo
                </Button>
              )}
              <Button
                size="sm"
                href="/MenuProduccion"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Produccion</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Reporte de Produccion</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>

          
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tr>
              <td></td>
              <td></td>
              <td style={{ paddingTop: "0px" }}>
              <Input
                bsSize="sm"
                type="select"
                value={selectedCliente}
                onChange={(e) => {
                  setSelectedCliente(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                {clientes
                  .sort((a, b) => (a.nombre_comercial > b.nombre_comercial ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.nombre_comercial}</option>;
                  })}
              </Input>
              </td>

             
            </tr>
            <tbody>
              {commentsData.map((a) => {
                    return (
                      <tr >
                        <td>{a.numero}</td>
                        <td style={{ minWidth: 100 }}>{a.fecha}</td>
                        <td>{a.clientes}</td>
                        <td>{a.pedido}</td>
                        <td>{a.operador}</td>
                        <td>{a.procesos}</td>
                        <td>{a.maquinas}</td>
                        <td>{new Intl.NumberFormat("en-US").format(a.cantidadInicial)}</td>
                        <td>{
                          a.status == "Cerrado" ? new Intl.NumberFormat("en-US").format(a.cantidadFinal) :
                        undefined
                        }</td>
                        <td>{a.status}</td>
                        <td>
                          {a.status == "Abierto" ? (
                            <Button
                            size="sm"
                            className="btn"
                            color="success"
                            onClick={(e) =>{
                              setIdCerrar(a._id);
                              setCantidadInicial(a.cantidadInicial);
                              setCantidadFinal(0)
                              toggleCerrar();
                            }}
                            >
                              <i class="fas fa-check"></i>
                            </Button>
                          ):undefined}
                              {/* <Button
                                size="sm"
                                className="btn"
                                color="danger"
                                onClick={(e) =>
                                  PDFOC(
                                    a.fecha,
                                  )
                                }
                              >
                                <i class="far fa-file-pdf"></i>
                              </Button> */}
                             
                        </td>
                      
                      </tr>
                    );
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />
      <Modal isOpen={modalCerrar} toggle={toggleCerrar} size="sm">
        <ModalHeader toggle={toggleCerrar}>
          <h3> Cierre de Produccion </h3>
          </ModalHeader>
        <ModalBody>
        <Row>
          <Col md={6}>
          <Label>Cantidad Final</Label>
          <Input
            bsSize="sm"
            type="number"
            value={cantidadFinal}
            required
            onChange={(e) =>{
              setCantidadFinal(e.target.value);
            }}
          />
          </Col>
        </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={(e) =>{
              Cierre();
            }}
          >
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>     
      {loader}
    </>
  );
}

export default ListadoProduccion;
