import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";

import { v4 as uuidv4 } from "uuid";

import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function DevolucionesProveedoresCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_COMPRAS = process.env.REACT_APP_URL_COMPRAS;
  const URL_COMPRAS_ACUMULADOS = process.env.REACT_APP_URL_COMPRAS_ACUMULADOS;
  const URL_DEVOLUCIONES_PROVEEDORES = process.env.REACT_APP_URL_DEVOLUCIONES_PROVEEDORES;

  const URL_ARTICULOS_DEVOLUCIONES_PROVEEDORES = process.env.REACT_APP_URL_ARTICULOS_DEVOLUCIONES_PROVEEDORES;

  const URL_DEVOLUCIONES_PROVEEDORES_ACUMULADOS =
    process.env.REACT_APP_URL_DEVOLUCIONES_PROVEEDORES_ACUMULADOS;

    let date = new Date();
    let hoy = moment(date).format("YYYY-MM-DD");

  const [compras, setCompras] = useState([]);
  const [selectedCompra, setSelectedCompra] = useState("");

  const [proveedor, setProveedor] = useState("");
  const [idProveedor, setIdProveedor] = useState("");
  const [fecha, setFecha] = useState(hoy);
  const [fecha_compromiso, setFechaCompromiso] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [total_kilos, setTotalKilos] = useState(0);
  const [total_metros, setTotalMetros] = useState(0);
  const [total_general, setTotalGeneral] = useState(0);


  const [insumosDevolucion, setInsumosDevolucion] = useState([]);
  const [fecha_compra, setFechaCompra] = useState("");
  const [proveedorPedido, setProveedorPedido] = useState("");
  const [total_kilos_pedido, setTotalKilosPedido] = useState(0);
  const [total_metros_pedido, setTotalMetrosPedido] = useState(0);
  const [total_general_pedido, setTotalGeneralPedido] = useState(0);

  const [cantidadAgrupado, setCantidadAgrupado] = useState([]);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      idCompAcumulado: "",
      insumos: "",
      idInsumo: "",
      unidad: "",
      cantidad: 0,
      costo:0,
      total: 0,
    },
  ]);

  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
      .get(URL_COMPRAS, {
        // .get(`${URL_COMPRAS}Tipo/Nacional`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allCompras = res.data;
        setCompras(allCompras);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);



  const saveDevolucion = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    toggleProgreso();


    let totalRollos =
      inputFields.length + cantidadAgrupado.length

    try {
      await axios
        .post(
          URL_DEVOLUCIONES_PROVEEDORES,
          {
            is_active:"Si",
            fecha,
            compras: selectedCompra,
            user: user.id,
            observaciones,
            total_general: total_general.toFixed(2),
            total_cantidad: parseFloat(total_metros) + parseFloat(total_kilos),
            aplicada:"No",
            proveedores:idProveedor
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((data) => {
          inputFields.map((a) => {
            if (a.insumos != "" && a.cantidad > 0) {
              axios
                .post(
                  `${URL_ARTICULOS_DEVOLUCIONES_PROVEEDORES}`,
                  {
                    devolucionesProveedores: data.data._id,
                    insumos:a.idInsumo,
                    cantidad: a.cantidad,
                    precio: a.costo,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalRollos = totalRollos - 1;
                  if (totalRollos == 0) {
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Crear Devolucion",
                          detalle: `Compra ${selectedCompra}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire("Good job!", "Creado con exito", "success");
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          footer: `${error.response.data}`,
                        });
                        console.log(error);
                        setValidaBoton(true);
                      });
                  }
                });
            } else {
              totalRollos = totalRollos - 1;
              if (totalRollos == 0) {
                axios
                  .post(
                    URL_LOGS,
                    {
                      tipo: "Crear Devolucion",
                      detalle: `Compra ${selectedCompra}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    Swal.fire("Good job!", "Creado con exito", "success");
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      footer: `${error.response.data}`,
                    });
                    console.log(error);
                    setValidaBoton(true);
                  });
              }
            }
          });

          
          // Pegamos a Devoluciones Acumulado

          cantidadAgrupado.map((w) => {
            if (w.insumos != "" && w.cantidad > 0) {
            axios
              .post(
                `${URL_DEVOLUCIONES_PROVEEDORES_ACUMULADOS}`,
                {
                  is_active:"Si",
                  devolucionesProveedores: data.data._id,
                  insumos: w.idInsumo,
                  cantidad: w.cantidad,
                  fecha,
                  proveedores: idProveedor,
                  compras: selectedCompra,
                  costo:w.total
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                totalRollos = totalRollos - 1;
                if (totalRollos == 0) {
                  axios
                    .post(
                      URL_LOGS,
                      {
                        tipo: "Crear Devolucion",
                        detalle: `Compra ${selectedCompra}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                      setValidaBoton(true);
                    });
                }
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
            } else {
              totalRollos = totalRollos - 1;
              if (totalRollos == 0) {
                axios
                  .post(
                    URL_LOGS,
                    {
                      tipo: "Crear Devolucion",
                      detalle: `Compra ${selectedCompra}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    Swal.fire("Good job!", "Creado con exito", "success");
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      footer: `${error.response.data}`,
                    });
                    console.log(error);
                    setValidaBoton(true);
                  });
              }
            }
          });

          // Terminamos de post a devoluciones acumulado
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };

  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        idCompAcumulado: "",
        insumos: "",
        idInsumo: "",
        unidad: "",
        cantidad: 0,
        costo:0,
        total: 0,
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let mts = values.filter((e) => e.unidad == "Metros");
    let totalMts = mts.map((c) => parseFloat(c.cantidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalMetros(TM);
    let KG = values.filter((e) => e.unidad == "Kilos");
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalKilos(TK);

    let totalDin = values.map((c) => parseFloat(c.costo));
    let TDin = totalDin.reduce((t, total, index) => t + total, 0);
    setTotalGeneral(TDin);
  };

  function BuscaInsumo(id, event) {
      
          insumosDevolucion.map((a) => {
            if (a._id == event.target.value) {
              let idCompAcumulado = a._id;
              let insumo = a.insumos[0].codigo;
              let idInsumo = a.insumos[0]._id;
              let unidadInsumo = a.insumos[0].unidad;
              let cantidad = 0;
              let costo = a.precio
              let total = 0
              handleChangeInputInsumo(
                idCompAcumulado,
                id,
                insumo,
                idInsumo,
                unidadInsumo,
                cantidad,
                costo,
                total
              );
            }
          });
   
  }

  const handleChangeInputInsumo = (
    idCompAcumulado,
    id,
    insumo,
    idInsumo,
    unidadInsumo,
    cantidad,
    costo,
    total
  ) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.idCompAcumulado = idCompAcumulado
        i.insumos = insumo;
        i.idInsumo = idInsumo;
        i.unidad = unidadInsumo;
        i.cantidad = cantidad;
        i.costo = costo
        i.total = total
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
    handleAddFields();
  };

  function ActualizaTotales() {
    let mts = inputFields.filter((e) => e.unidad == "Metros");
    let totalMts = mts.map((c) => parseFloat(c.cantidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalMetros(TM);
    let KG = inputFields.filter((e) => e.unidad == "Kilos");
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalKilos(TK);

    let totalDin = inputFields.map((c) => parseFloat(c.total));
    let TDin = totalDin.reduce((t, total, index) => t + total, 0);
    setTotalGeneral(TDin);

    let agrupado = inputFields.reduce(function (groups, item) {
      const val = item["idInsumo"]
      groups[val] = groups[val] || {
        idInsumo: item.idInsumo,
        cantidad: 0,
        total:0
      };
      groups[val].cantidad += item.cantidad;
      groups[val].total += item.total;
      groups[val].id = item.id;
      groups[val].insumos = item.insumos;
      groups[val].idInsumo = item.idInsumo;
      groups[val].unidad = item.unidad;
      return groups;
    }, []);

    let CA = Object.values(agrupado);
    setCantidadAgrupado(CA);
  }


  function jalaCompra(e) {
    setSelectedCompra(e);

    axios
      .get(`${URL_COMPRAS_ACUMULADOS}/compra/${e}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allInsumosDevolucion = res.data;
        setInsumosDevolucion(allInsumosDevolucion);

      })
      .catch((err) => {
        console.log(err);
      });



    compras.map((a) => {
      if (a._id == e) {
        setFechaCompra(a.fecha);
        setTotalKilosPedido(a.total_kilos);
        setTotalMetrosPedido(a.total_metros);
        setTotalGeneralPedido(a.total_general);
        setProveedor(a.proveedores[0].razon_social);
        setIdProveedor(a.proveedores[0]._id);

      }
    });
  }

  const handleChangeInputCantidad = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = parseFloat(event.target.value);
        i.total = parseFloat(event.target.value) * parseFloat(i.costo)
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales()
  };

  console.log("hola", cantidadAgrupado);

  return (
    <>
      <Header />
      <br />
      <br />
      {user.pedidos_create ? (
        <div className="card container col-10">
          <h3 align="center">Nueva Devolucion</h3>
          <Form>
            <Row>
              <Col md={3}>
                <Label className="mr-sm-2">Compra</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedCompra}
                  required
                  onChange={(e) => jalaCompra(e.target.value)}
                >
                  <option value="0">Selecciona un Compra</option>
                  {compras
                    .sort((a, b) => (a.idCompras < b.idCompras ? 1 : -1))
                    .map((a) => {
                      return (
                        <option value={a._id}>
                          # {a.idCompras}{" / "}
                          {a.proveedores[0].nombre_comercial + " " + a.proveedores[0].razon_social}
                        </option>
                      );
                    })}
                </Input>
              </Col>
              <Col md={3}>
                <Label>Fecha Compra</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fecha_compra}
                  required
                  disabled
                  onChange={(e) => {
                    setFechaCompra(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Proveedor</Label>
                <Input
                  type="text"
                  placeholder="Proveedor"
                  value={proveedor}
                  required
                  disabled
                  onChange={(e) => {
                    setProveedor(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Label>Fecha</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fecha}
                  required
                  onChange={(e) => {
                    setFecha(e.target.value);
                  }}
                />
              </Col>
              <Col md={6}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="text"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <h4 id="logoutBoton">
                  TOTALES {new Intl.NumberFormat("en-US").format(total_kilos)}{" "}
                  kg. / {new Intl.NumberFormat("en-US").format(total_metros)}{" "}
                  mts.
                </h4>
              </Col>
            </Row>

            {/* Tabla Insumos */}

            <Row>
              <Col md={3}>
                <Label className="mr-sm-2">Insumos</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Cantidad</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Total</Label>
              </Col>
            </Row>

            {inputFields.map((inputField) => (
              <div key={inputField.id}>
                <Row>
                  <Col md={3}>
                    <Input
                      bsSize="sm"
                      type="select"
                      value={inputField.idCompAcumulado}
                      onChange={(event) => {
                        BuscaInsumo(inputField.id, event);
                      }}
                    >
                      <option value="">Selecciona</option>
                      {insumosDevolucion.map((a) => {
                        return (
                          <option value={a._id}>
                            {a.insumos[0].nombre}
                          </option>
                        );
                      })}
                    </Input>
                  </Col>

                  <Col md={1}>
                      <Input
                        bsSize="sm"
                        name="cantidad"
                        type="number"
                        placeholder="Cantidad"
                        value={inputField.cantidad}
                        required
                        onChange={(event) =>
                          handleChangeInputCantidad(inputField.id, event)
                        }
                      />
                    </Col>

                    <Col md={1}>
                      <Input
                        bsSize="sm"
                        name="total"
                        type="number"
                        placeholder="total"
                        value={inputField.total}
                        required
                        disabled
                      />
                    </Col>

                  <Col>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      disabled={inputFields.length === 1}
                      onClick={() => handleRemoveFields(inputField.id)}
                      tabindex="-1"
                    >
                      <i class="fas fa-minus"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1"
                    >
                      <i class="fas fa-plus"></i>
                    </Button>
                  </Col>
                </Row>
              </div>
            ))}
            <Row>
              <Col md={12}>
                <h4 id="logoutBoton">
                  TOTALES {new Intl.NumberFormat("en-US").format(total_kilos)}{" "}
                  kg. / {new Intl.NumberFormat("en-US").format(total_metros)}{" "}
                  mts.
                </h4>
              </Col>
            </Row>
            {/* Termina Tabla Insumos */}

            <br />
          </Form>
          <Row>
            <Col>
              {validaBoton ? (
                <Button
                  type="submit"
                  className="btn btn-success"
                  onClick={saveDevolucion}
                >
                  Guardar
                </Button>
              ) : (
                <Button type="submit" className="btn btn-success" disabled>
                  Guardar
                </Button>
              )}
              <Button
                href="/ListadoDevolucionesProveedores"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
          </Row>
          <br />
           <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios minutos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default DevolucionesProveedoresCreate;
