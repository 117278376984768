import React, { useContext } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import { Col, Row, Button } from "reactstrap";
import Footer from "../../layout/Footer/Footer";
import Header from "../../layout/Header/Header";

function BotonesCXP() {
  const { user } = useContext(AuthContext);
  return (
    <>
          <Header />
          {user.menu_admin ?(
      <div className="container">
        <br />
        <br />
          <br />
          <Row>
          <Col md={4} align="center">
          {user.menu_admin ?(
            <Button href="/ListadoComprasCXP" className="botonesMenu" color="success">
              <i class="fas fa-money-check-alt fa-7x"></i>
              <br />
              <br />
              Compras Naionales
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-money-check-alt fa-7x"></i>
          <br />
          <br />
          Compras Naionales
        </Button> }
          </Col>


          <Col md={4} align="center">
          {user.menu_admin ?(
            <Button href="/ListadoAbonosProveedores" className="botonesMenu" color="success">
              <i class="fas fa-dollar-sign fa-7x"></i>
              <br />
              <br />
              Abonos
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-dollar-sign fa-7x"></i>
          <br />
          <br />
          Abonos
        </Button> }
          </Col>

        
          <Col md={4} align="center">
          {user.menu_admin ?(
            <Button href="/EstadoCuentaCXP" className="botonesMenu" color="success">
              <i class="fas fa-file-invoice-dollar fa-7x"></i>
              <br />
              <br />
              Estado Cuenta
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-file-invoice-dollar fa-7x"></i>
          <br />
          <br />
          Estado Cuenta
        </Button> }
          </Col>
          </Row>
          <br/>
          <Row>
          <Col md={4} align="center">
          {user.menu_admin ?(
            <Button href="/ListadoDevolucionesProveedoresAdmin" className="botonesMenu" color="success">
              <i class="fas fa-undo-alt fa-7x"></i>
              <br />
              <br />
              Devoluciones
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-undo-alt fa-7x"></i>
          <br />
          <br />
          Devoluciones
        </Button> }
          </Col>
          </Row>

      </div>
      ): undefined } 
      
    </>
  );
}

export default BotonesCXP;
