import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col, Spinner
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import BajaAltaMultiple from "../../BajaAltaMultiple";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import { v4 as uuidv4 } from "uuid";
import qrcode from "qrcode-js";

function ListadoComprasCXPInter() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_ORDENES_COMPRA = process.env.REACT_APP_URL_ORDENES_COMPRA;
  const URL_NOTAS_CXP = process.env.REACT_APP_URL_NOTAS_CXP;
  const URL_COMPRAS_ACUMULADOS = process.env.REACT_APP_URL_COMPRAS_ACUMULADOS;
  const URL_ARTICULOS_OC = process.env.REACT_APP_URL_ARTICULOS_OC;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;

  const [compras, setCompras] = useState([]);
  const [articulosOC, setArticulosOC] = useState([]);
  const [ordenesCompra, setOrdenesCompra] = useState([]);
  const [selectedOrden, setSelectedOrden] = useState("");
  const [articulos, setArticulos] = useState([]);
  
  const [proveedores, setProveedores] = useState([]);
  const [selectedProveedor, setSelectedProveedor] = useState("");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState("");
  const [selectedFechaFin, setSelectedFechaFin] = useState("");

  const [fecha, setFecha] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [contenedor, setContenedor] = useState("");
  const [numero_compra, setNumeroCompra] = useState("");
  const [piezas, setPiezas] = useState("");
  const [idEdit, setIdEdit] = useState("");
  const [selectedProveedorEdit, setSelectedProveedorEdit] = useState("");

  const [articulosCompra, setArticulosCompra] = useState([]);
 

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  const [activo, setActivo] = useState("");

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useEffect(() => {
    axios
      .get(`${URL_NOTAS_CXP}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allCompras = response.data;
        // Array para pagination
        let arrayTabla = allCompras
          .sort((a, b) => (a.idCompras > b.idCompras ? 1 : -1))
          .map((a) => {
            return {
              activo: a.is_active,
              _id: a._id,
              numero: a.idNotasCxP,
              fecha: a.fecha,
              contenedor: a.embarques[0].contenedor,
              totalCantidad: a.total_cantidad,
              idOrdenesCompra: a.embarques[0]._id,
              numeroOrden: a.embarques[0].idEmbarques,
              contenedor: a.embarques[0].contenedor,
              proveedores: a.proveedores[0].nombre_comercial + " " + a.proveedores[0].razon_social,
              idProveedores: a.proveedores[0]._id,
              total_costo: a.total_costo,
              saldo: a.saldo,
              abonos: parseFloat(a.total_costo) - parseFloat(a.saldo)
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setCompras(allCompras);
        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_PROVEEDORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allProveedores = response.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulos = response.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const ListaCompras = comments.sort((a, b) =>
    a.idCompras > b.idCompras ? 1 : -1
  );

  function PDFTabla() {
    let totalCantidadPDF = 0;
    let totalCostoPDF = 0;
    let totalAbonosPDF = 0;
    let totalSaldoPDF = 0;
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedProveedor == 0 || selectedProveedor == a.idProveedores)
      ) {
      totalCantidadPDF = totalCantidadPDF + a.totalCantidad;
      totalCostoPDF = totalCostoPDF + a.total_costo;
      totalAbonosPDF = totalAbonosPDF + a.abonos;
      totalSaldoPDF = totalSaldoPDF + a.saldo;
        return [
          a.numero,
          a.fecha,
          a.numeroOrden,
          a.contenedor,
          a.proveedores,
          new Intl.NumberFormat("en-US").format(a.totalCantidad),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.total_costo),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.abonos),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.saldo),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Listado de Compras`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Numero",
          "Fecha",
          "Embarque",
          "Contendor",
          "Proveedor",
          "Cantidad",
          "Costo Total",
          "Abonos",
          "Saldo"
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "",
          "",
          "Totales",
          new Intl.NumberFormat("en-US").format(totalCantidadPDF),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalCostoPDF),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalAbonosPDF),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalSaldoPDF),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save(`Compras.pdf`);
  }

  function excel() {
    const dataExcel = ListaCompras.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedProveedor == 0 || selectedProveedor == a.idProveedores)
      ) {
        return {
          Numero: a.numero,
          Fecha: a.fecha,
          Embarque: a.numeroOrden,
          Contenedor: a.contenedor,
          Proveedores: a.proveedores,
          Cantidad: a.totalCantidad,
          Costo: a.total_costo,
          Abonos: a.abonos,
          Saldo: a.saldo
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoCompras";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoCompras",
        sheetFilter: [
          "Numero",
          "Fecha",
          "Embarque",
          "Contendor",
          "Proveedores",
          "Cantidad",
          "Costo",
          "Abonos",
          "Saldo"
        ],
        sheetHeader: [
          "Numero",
          "Fecha",
          "Embarque",
          "Contendor",
          "Proveedores",
          "Cantidad",
          "Costo",
          "Abonos",
          "Saldo"
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    let totalCantidadPDF = 0;
    let totalCostoPDF = 0;
    let totalAbonosPDF = 0;
    let totalSaldoPDF = 0;
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedProveedor == 0 || selectedProveedor == a.idProveedores)
      ) {
      totalCantidadPDF = totalCantidadPDF + a.totalCantidad;
      totalCostoPDF = totalCostoPDF + a.total_costo;
      totalAbonosPDF = totalAbonosPDF + a.abonos;
      totalSaldoPDF = totalSaldoPDF + a.saldo;
        return [
          a.numero,
          a.fecha,
          a.numeroOrden,
          a.contenedor,
          a.proveedores,
          new Intl.NumberFormat("en-US").format(a.totalCantidad),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.total_costo),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.abonos),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.saldo),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Listado de Compras`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Numero",
          "Fecha",
          "Embarque",
          "Contendor",
          "Proveedor",
          "Cantidad",
          "Costo Total",
          "Abonos",
          "Saldo"
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "",
          "",
          "Totales",
          new Intl.NumberFormat("en-US").format(totalCantidadPDF),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalCostoPDF),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalAbonosPDF),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalSaldoPDF),
        ],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado de Compras",
          email: mailTo,
          fileName: "ListadoCompras.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Compras.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Enviado con Exito!",
          showConfirmButton: false,
          timer: 2000,
        });
        setMailTo("")
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function jalaInfo(
    fecha,
    observaciones,
    contenedor,
    numero,
    idEdit
  ) {
    setFecha(fecha);
    setObservaciones(observaciones);
    setContenedor(contenedor)
    setNumeroCompra(numero);
    setIdEdit(idEdit);
    toggleEdit();
  }

  async function PDFOC(
    fecha,
    proveedores,
    totalCantidad,
    // observaciones,
    contenedor,
    numero,
    idPDFOC
  ) {
    await axios
      .get(`${URL_INVENTARIOS}/compras/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosCompra = response.data;
        let arrayTabla = allArticulosCompra
        .sort((a, b) => (a.captura > b.captura ? 1 : -1))
          .map((a) => {
            return {
              id: a._id,
              articulos: a.articulos[0].codigo + " " + a.articulos[0].nombre,
              unidad: a.articulos[0].unidad,
              colores: a.colores,
              cantidad: a.cantidad,
              precio: a.costo,
              pantone: a.pantone
            };
          })
          .filter(function (el) {
            return el != null;
          });

          let dataFinal = Object.values(arrayTabla);
          let rollos = dataFinal.length
  
          let agrupado = dataFinal.reduce(function (groups, item) {
            const val = item["idArticulo"]  + item["pantone"]
            groups[val] = groups[val] || {
              idArticulo: item.idArticulo,
              cantidad: 0,
              rollos: 0,
              cantidadString: "",
            };
            groups[val].cantidad += item.cantidad;
            groups[val].cantidadString += item.cantidadString + " / ";
            groups[val].articulos = item.articulos;
            groups[val].unidad = item.unidad;
            groups[val].precio = item.precio;
            groups[val].pantone = item.pantone;
            groups[val].rollos += 1;
            return groups;
          }, []);
  
          let agrupadoValues = Object.values(agrupado);
  
  
          let mts = agrupadoValues.filter((e) => e.unidad == "Metros");
          let totalMts = mts.map((c) => parseFloat(c.cantidad));
          let TM = totalMts.reduce((t, total, index) => t + total, 0);
          // setTotalMetrosEdit(TM);
          let KG = agrupadoValues.filter((e) => e.unidad == "Kilos");
          let totalKG = KG.map((c) => parseFloat(c.cantidad));
          let TK = totalKG.reduce((t, total, index) => t + total, 0);
          // setTotalKilosEdit(TK);
  
          let total_importe = 0;
          const data = agrupadoValues.map((a) => {
              total_importe = total_importe + a.precio * a.cantidad;
            return [
              a.articulos,
              a.unidad,
              a.colores,
              a.pantone,
              new Intl.NumberFormat("en-US").format(a.cantidad),
              new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.precio),
              new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.precio * a.cantidad),
            ]
        });
        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });
        const doc = new jsPDF("landscape");
        var img = new Image();
        img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        doc.addImage(img, "png", 230, 30, 45, 15);
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        doc.addImage(img2, "png", 140, 15, 25, 25);
        doc.text(`Compra # ${numero}`, 20, 20);
        doc.setFontSize(12);
        doc.text(`Fecha ${fecha}`, 20, 25);
        doc.text(`Proveedor ${proveedores} / Contenedor ${contenedor}`, 20, 30);
        doc.text(
          `Piezas ${new Intl.NumberFormat("en-US").format(totalCantidad)} / Modelos ${piezas}`,
          20,
          35
        );
        doc.autoTable({
          head: [
            ["Articulo", "Unidad", "Pantone",  "Cantidad", "Precio", "Total"],
          ],
          body: dataPDFLimpia,
          startY: 45,
          foot: [
            [
              "",
              "",
              "Totales",
              new Intl.NumberFormat("en-US").format(totalCantidad),
              "",
              new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(total_importe),
            ],
          ],
          showFoot: "lastPage",
        });
        doc.save(`Compra-${numero}.pdf`);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function editCompras(event) {
    event.preventDefault();
    const URL_COMPRA_EDIT = `${process.env.REACT_APP_URL_COMPRAS}/${idEdit}`;
    axios
      .all([
        axios.patch(
          URL_COMPRA_EDIT,
          {
            fecha,
            observaciones,
            contenedor,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        ),
      ])
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Editar Compra",
            detalle: `${numero_compra} ${fecha}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );

        toggleEdit();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  const headers = [
    { name: "Numero", field: "numero", sortable: true },
    { name: "Fecha", field: "fecha", sortable: false },
    { name: "Embarque", field: "numeroOrden", sortable: true },
    { name: "Contenedor", field: "contenedor", sortable: true },
    { name: "Proveedor", field: "proveedores", sortable: true },
    { name: "Cantidad", field: "totalCantidad", sortable: true },
    { name: "Costo Total", field: "total_costo", sortable: true },
    { name: "Abonos", field: "abonos", sortable: true },
    { name: "Saldo", field: "saldo", sortable: true },
    { name: "PDF", field: "PDF", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.proveedores.toLowerCase().includes(search.toLowerCase()) ||
          comment.numeroOrden.toString().includes(search)||
          comment.contenedor.toString().includes(search)||
          comment.totalCantidad.toString().includes(search)||
          comment.numero.toString().includes(search) ||
          comment.total_costo.toString().includes(search)||
          comment.abonos.toString().includes(search)||
          comment.saldo.toString().includes(search)
      );
    }
    if (selectedProveedor) {
      computedComments = computedComments.filter((e) =>
        e.idProveedores.includes(selectedProveedor)
      );
    }
    if (activo) {
      computedComments = computedComments.filter((e) =>
        e.activo.includes(activo)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field && sorting.field != "totalCantidad" && sorting.field != "numeroOrden" && sorting.field != "numero" && sorting.field != "total_costo" && sorting.field != "abonos" && sorting.field != "saldo") {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (sorting.field && sorting.order === "asc" && (sorting.field == "totalCantidad" || sorting.field == "numeroOrden" || sorting.field == "numero" || sorting.field != "total_costo" || sorting.field != "abonos" || sorting.field != "saldo")) {
      computedComments = computedComments.sort(
        (a, b) => parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (sorting.field && sorting.order === "desc" && (sorting.field == "totalCantidad" || sorting.field == "numeroOrden" || sorting.field == "numero" || sorting.field != "total_costo" || sorting.field != "abonos" || sorting.field != "saldo")) {
      computedComments = computedComments.sort(
        (a, b) => parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedProveedor,
    selectedFechaInicio,
    selectedFechaFin,
  ]);

  let totalCantidadTabla = 0;
  let totalCostoTabla = 0;
  let totalAbonosTabla = 0;
  let totalSaldoTabla = 0;

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_admin ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/MenuCXP"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado de Compras</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Compras Internacionales</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label>Proveedores</Label>
              <Input
                bsSize="sm"
                type="select"
                value={selectedProveedor}
                onChange={(e) => {
                  setSelectedProveedor(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="0">Selecciona</option>
                {proveedores
                  .sort((a, b) => (a.razon_social > b.razon_social ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.nombre_comercial} {a.razon_social}</option>;
                  })}
              </Input>
            </Col>

          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData.map((a) => {
                totalCantidadTabla = totalCantidadTabla + a.totalCantidad;
                totalCostoTabla = totalCostoTabla + a.total_costo;
                totalAbonosTabla = totalAbonosTabla + a.abonos;
                totalSaldoTabla = totalSaldoTabla + a.saldo;
                {
                  return (
                    <tr>
                      <td>{a.numero}</td>
                      <td>{a.fecha}</td>
                      <td>{a.numeroOrden}</td>
                      <td>{a.contenedor}</td>
                      <td>{a.proveedores}</td>
                      <td>{new Intl.NumberFormat("en-US").format(a.totalCantidad)}</td>
                      <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.total_costo)}</td>
                      <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.abonos)}</td>
                      <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.saldo)}</td>
                      <td>
                          <div>
                            <Button
                              size="sm"
                              className="btn"
                              color="danger"
                              onClick={(e) =>
                                PDFOC(
                                  a.fecha,
                                  a.proveedores,
                                  a.totalCantidad,
                                  // a.observaciones,
                                  a.contenedor,
                                  a.numero,
                                  a._id
                                )
                              }
                            >
                              <i class="far fa-file-pdf"></i>
                            </Button>
                          </div>
                      </td>
                    </tr>
                  );
                }
              })}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="negrita" align="center">
                  TOTALES
                </td><td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalCantidadTabla)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalCostoTabla)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalAbonosTabla)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalSaldoTabla)}
                </td>
                <td></td>
              </tr>
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

     
      {loader}
    </>
  );
}

export default ListadoComprasCXPInter;
