import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from '../../../contexts/AuthContext'
import PuestosCreate from "./PuestosCreate";
import axios from "axios";
import { Table, 
  Input, 
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label, } from "reactstrap";
import Header from "../../../layout/Header/Header";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable"
import useFullPageLoader from "../../../hooks/useFullPageLoader"

function ListadoPuestos() {
  const { user } = useContext(AuthContext);
  const URL_PUESTOS = process.env.REACT_APP_URL_PUESTOS;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState('');
  const [puestos, setPuestos] = useState([]);
  const [selectedPuesto, setSelectedPuesto] = useState('');
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");
  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);
  const [text, setText] = useState(false);
  const [idPuesto, setIdPuesto] = useState("")
  const [nombrePuesto, setNombrePuesto] = useState("")
  const [idColaboradores, setIdColaboradores] = useState("")
  const [idArea, setIdArea] = useState("")

  //Edit 
  const [idColaboradoresEdit, setIdColaboradoresEdit] = useState("")
  const [idAreaEdit, setIdAreaEdit] = useState("")

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const [puesto, setPuesto] = useState("");
  
const ITEMS_PER_PAGE = 50

  useEffect(() => {
    axios
      .get(URL_PUESTOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allPuestos = response.data;
        let arrayTabla = allPuestos
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((a) => {
          return {
            _id:a._id,
            name: a.name,
            nombreColaboradores: a.colaboradores[0].nombre + " " + a.colaboradores[0].apellido,
            idColaboradores: a.colaboradores[0]._id,
            nombreArea: a.areas[0].name,
            idArea: a.areas[0]._id,
            is_active: a.is_active

          };
        }).filter(function (el) {
          return el != null;
        });

        let dataFinal = Object.values(arrayTabla);
        setPuestos(allPuestos)
        setComments(dataFinal)
      })
      .catch((err) => {
        console.log(err);
      });
      
      axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allColaboradores = response.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function Edit(idPuesto, nombrePuesto, idColaboradores, idArea){
      setIdPuesto(idPuesto)
      setNombrePuesto(nombrePuesto)
      setIdColaboradoresEdit(idColaboradores)
      setIdAreaEdit(idArea)
      toggleEdit()
    }

  function editPuesto(event) {
    event.preventDefault();
  const URL_PUESTOS_EDIT = `${process.env.REACT_APP_URL_PUESTOS}/${idPuesto}`;
    axios
      .patch(
        URL_PUESTOS_EDIT,
        {
          name: nombrePuesto,
          areas:idAreaEdit,
          colaboradores: idColaboradoresEdit,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios
        .post(
          URL_LOGS,
          {
            tipo:'Edito Puestos Admin',
            detalle: `Nombre: ${nombrePuesto}`,
            user: user.id
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        toggleEdit();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        window.location.reload();
      })
       .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

// pagination
const headers = [
  { name: "Puesto", field: "name", sortable: true },
  { name: "Jefe", field: "nombreColaboradores", sortable: true },
  { name: "Acciones", field: "acciones", sortable: false },
];

const commentsData = useMemo(() => {
  let computedComments = comments

  if (search) {
      computedComments = computedComments.filter(
          comment =>
              comment.name.toLowerCase().includes(search.toLowerCase()) ||
              comment.nombreColaboradores.toLowerCase().includes(search.toLowerCase()) 
      );
  }


  if(user.areas != AREAS_GENERAL){
    computedComments = computedComments.filter((e)=>
    e.idArea.includes(user.areas)
    ) 
  } 

  if(puesto){
    computedComments = computedComments.filter((e)=>
    e._id.includes(puesto)
    ) 
  } 

  if(idColaboradores){
    computedComments = computedComments.filter((e)=>
    e.idColaboradores.includes(idColaboradores)
    ) 
  } 
  



  setTotalItems(computedComments.length);

  //Sorting comments
  if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
          (a, b) =>
              reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
  }

  //Current Page slice
  return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
  );
}, [comments, currentPage, search, sorting, idArea, idColaboradores, puesto]);



// 

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_puestos  ?(
      // <div className="container">
        <div className="row">
          {
            <div className="col-8">
              <div className="card">
                <div className="card-body">
                  <h3 align="center">Puestos</h3>
                  <div className="row">
                        <div className="col-md-6">
                            <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
                        <div className="col-md-6 d-flex flex-row-reverse">
                            <Search
                                onSearch={value => {
                                    setSearch(value);
                                    setCurrentPage(1);
                                }}
                            />
                        </div>
                    </div>
            <Table size="sm" striped borderless className="table-responsive-xl">
                <TableHeader
                            headers={headers}
                            onSorting={(field, order) =>
                                setSorting({ field, order })
                            }
                        />
                <tr>
                      { user.areas == AREAS_GENERAL? 
                        <td style={{ paddingTop: "0px" }}>
                        <Input
                        bsSize="sm"
                        type="select"
                        value={puesto}
                        required
                        onChange={(e) => {
                          setPuesto(e.target.value)
                          setCurrentPage(1)
                        }}
                      >
                        <option value="">Selecciona</option>
                        {puestos
                          .sort((a, b) => a.name > b.name ? 1 : -1 )
                          .map((a) => {
                          return <option value={a._id}>{a.name} </option>;
                          })}
                      </Input>
                        </td> :
                      <td style={{ paddingTop: "0px" }}>
                      <Input
                      bsSize="sm"
                      type="select"
                      value={puesto}
                      required
                      onChange={(e) => {
                        setPuesto(e.target.value)
                        setCurrentPage(1)
                      }}
                    >
                      <option value="">Selecciona</option>
                      {puestos
                        .sort((a, b) => a.name > b.name ? 1 : -1 )
                        .map((a) => {
                          if( user.areas == a.areas[0]._id){
                        return <option value={a._id}>{a.name} </option>;}
                        })}
                    </Input>
                      </td>  
                      }
                       { user.areas == AREAS_GENERAL?
                      (<td style={{ paddingTop: "0px" }}>
                        <Input
                        bsSize="sm"
                        type="select"
                        value={idColaboradores}
                        required
                        onChange={(e) => setIdColaboradores(e.target.value)}
                      >
                        <option value="">Selecciona</option>
                        {colaboradores
                          .sort((a, b) => a.nombre > b.nombre ? 1 : -1 )
                          .map((a) => {
                          return <option value={a._id}>{a.nombre} {a.apellido}</option>;
                          })}
                      </Input>
                        </td>):(
                        <td style={{ paddingTop: "0px" }}>
                        <Input
                        bsSize="sm"
                        type="select"
                        value={idColaboradores}
                        required
                        onChange={(e) => setIdColaboradores(e.target.value)}
                      >
                        <option value="">Selecciona</option>
                        {colaboradores
                          .sort((a, b) => a.nombre > b.nombre ? 1 : -1 )
                          .map((a) => {
                            if( user.areas == a.areas[0]._id){
                              return (<option value={a._id}>{a.nombre} {a.apellido}</option>)}
                          })}
                      </Input>
                        </td>
                        )}
                      </tr>
                    <tbody>
                      {
                      commentsData.map((p) => {
                        return (
                          <tr>
                            <td>{p.name}</td>
                            <td>{p.nombreColaboradores}</td>
                            <td>         
                              {user.puestos_create  ?(
                                <Button color="info" id="Editar" size="sm" onClick={(e)=>Edit(p._id, p.name, p.idColaboradores,
                                  p.idArea)}>
                                    <i class="far fa-edit"></i>
                                  </Button>
                              ):(
                                <Button color="info" id="Editar" size="sm" disabled>
                                  <i class="far fa-edit"></i>
                                </Button>
                              )}
                                  <Baja idStatus={p._id} is_active={p.is_active} URL_BAJA={process.env.REACT_APP_URL_PUESTOS} />
                            
                              </td>
                          </tr>
                        );
                        }
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          }
          <div className="col-4">
            <div className="card">
              <div className="card-body">
                <PuestosCreate />
              </div>
            </div>
          </div>
        </div>
      // </div>
      ): undefined } 
      
      <Modal size="sm" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>Editar Puesto</ModalHeader>
        <ModalBody>
          <Label className="mr-sm-2">Nombre</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={nombrePuesto}
            required
            onChange={(e) => {
              setNombrePuesto(e.target.value);
            }}
          />
        
                {/* {user.areas == AREAS_GENERAL ? (
                <div>
                  <Label className="mr-sm-2">Areas</Label>
                  <Input
                    type="select"
                    value={idAreaEdit}
                    required
                    onChange={(e) => setIdAreaEdit(e.target.value)}
                  >
                    <option value="0">Selecciona una Area</option>
                    {areas
                      .sort((a, b) => a.name - b.name)
                      .map((a) => {
                          return <option value={a._id}>{a.name}</option>
                      })}
                  </Input>
                </div>
              ) : (
                <div>
                  <Label className="mr-sm-2">Areas</Label>
                  <Input
                    type="select"
                    value={idAreaEdit}
                    required
                    onChange={(e) => setIdAreaEdit(e.target.value)}
                  >
                    <option value="0">Selecciona una Area</option>
                    {areas
                      .sort((a, b) => a.name - b.name)
                      .map((a) => {
                        if (user.areas == a._id) {
                          return <option value={a._id}>{a.name}</option>;
                        }
                      })}
                  </Input>
                </div>
              )} */}
            <Label className="mr-sm-2">Jefe</Label>
            <Input
                className="col-sm-12"
                type="select"
                value={idColaboradoresEdit}
                required
                onChange={(e) => {
                  setIdColaboradoresEdit(e.target.value);
                }}
              >
                <option value="0">Selecciona una Area</option>
                {colaboradores
                  .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                  .map((a) => {
                      return <option value={a._id}>{a.nombre} {a.apellido}</option>;
                  })}
              </Input>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editPuesto}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>
      {loader}
    </>
  );
}

export default ListadoPuestos;
