import React, { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Col, Row, Button } from "reactstrap";
import Header from "../../layout/Header/Header";

function BotonesReportes() {
  const { user } = useContext(AuthContext);
  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_reportes ? (
      <div className="container">
            <Row>
            <Col md={4} align="center">
            {user.menu_reportes ?(
              <Button
                href="/TablaVentas"
                className="botonesMenu"
                color="success"
              >
                <i class="far fa-list-alt fa-7x"></i>
                <br />
                <br />
                Ventas
              </Button>
            ): <Button
            disabled
            className="botonesMenu"
            color="success"
          >
            <i class="far fa-list-alt fa-7x"></i>
            <br />
            <br />
            Ventas
          </Button> }
            </Col>

            <Col md={4} align="center">
            {user.menu_reportes ?(
              <Button
                href="/TablaDevoluciones"
                className="botonesMenu"
                color="success"
              >
                <i class="fas fa-undo fa-7x"></i>
                <br />
                <br />
                Devoluciones
              </Button>
            ): <Button
            disabled
            className="botonesMenu"
            color="success"
          >
            <i class="fas fa-undo fa-7x"></i>
            <br />
            <br />
            Devoluciones
          </Button> }
            </Col>
            <Col md={4} align="center">
            {user.menu_reportes ?(
              <Button
                href="/GraficaVentasMensualComparativa"
                className="botonesMenu"
                color="success"
              >
                <i class="fas fa-chart-line fa-7x"></i>
                <br />
                <br />
                Comparativa
                <br />
                Venta Mensual
              </Button>
            ): <Button
            disabled
            className="botonesMenu"
            color="success"
          >
            <i class="fas fa-chart-line fa-7x"></i>
            <br />
                <br />
                Comparativa
                <br />
                Venta Mensual
          </Button> }
            </Col>

            </Row>
            <br />
            <Row>

            <Col md={4} align="center">
            {user.menu_reportes ?(
              <Button
                href="/GraficaVentasMensualCliente"
                className="botonesMenu"
                color="success"
              >
                <i class="fas fa-user-tie fa-7x"></i>
                <br />
                <br />
                Venta Mensual <br/> Cliente
              </Button>
            ): <Button
            disabled
            className="botonesMenu"
            color="success"
          >
            <i class="fas fa-user-tie fa-7x"></i>
            <br />
            <br />
            Venta Mensual <br/> Cliente
          </Button> }
            </Col>

            <Col md={4} align="center">
            {user.menu_reportes ?(
              <Button
                href="/GraficaVentasDesgloce"
                className="botonesMenu"
                color="success"
              >
                <i class="fas fa-chart-bar fa-7x"></i>
                <br />
                <br />
                Desgloce Ventas
              </Button>
            ): <Button
            disabled
            className="botonesMenu"
            color="success"
          >
            <i class="fas fa-chart-bar fa-7x"></i>
            <br />
            <br />
            Desgloce Ventas
          </Button> }
            </Col>

            <Col md={4} align="center">
            {user.menu_reportes ?(
              <Button
                href="/GraficaVentasEstado"
                className="botonesMenu"
                color="success"
              >
                <i class="fas fa-globe-americas fa-7x"></i>
                <br />
                <br />
                Venta Estado
              </Button>
            ): <Button
            disabled
            className="botonesMenu"
            color="success"
          >
            <i class="fas fa-globe-americas fa-7x"></i>
            <br />
            <br />
            Venta Estado
          </Button> }
            </Col>

            </Row>
            <br/>
            <Row>
            <Col md={4} align="center">
            {user.menu_reportes ?(
              <Button
                href="/GraficaVentasVendedor"
                className="botonesMenu"
                color="success"
              >
                <i class="fas fa-address-card fa-7x"></i>
                <br />
                <br />
                Venta Vendedor
              </Button>
            ): <Button
            disabled
            className="botonesMenu"
            color="success"
          >
            <i class="fas fa-address-card fa-7x"></i>
            <br />
            <br />
            Venta Vendedor
          </Button> }
            </Col>

            <Col md={4} align="center">
            {user.menu_reportes ?(
              <Button
                href="/GraficaVentasMensualVendedor"
                className="botonesMenu"
                color="success"
              >
                <i class="fas fa-chart-area fa-7x"></i>
                <br />
                <br />
                Venta Mensual <br/> Vendedor
              </Button>
            ): <Button
            disabled
            className="botonesMenu"
            color="success"
          >
            <i class="fas fa-chart-area fa-7x"></i>
            <br />
            <br />
            Venta Mensual <br/> Vendedor
          </Button> }
            </Col>



            <Col md={4} align="center">
{user.menu_reportes ?(
  <Button href="/DashboardVentas" className="botonesMenu" color="success">
     <i class="fas fa-signal fa-7x"></i>
    <br />
    <br />
    Dashboard Ventas
  </Button>
): <Button disabled className="botonesMenu" color="success">
 <i class="fas fa-signal fa-7x"></i>
<br />
<br />
Dashboard Ventas
</Button> }
</Col>

</Row>
            <br />
            <Row>

  <Col md={4} align="center">
{user.menu_reportes ?(
  <Button href="/DashboardCobranza" className="botonesMenu" color="success">
     <i class="fas fa-dollar-sign fa-7x"></i>
    <br />
    <br />
    Dashboard Cobranza
  </Button>
): <Button disabled className="botonesMenu" color="success">
 <i class="fas fa-dollar-sign fa-7x"></i>
<br />
<br />
Dashboard Cobranza
</Button> }
</Col>



<Col md={4} align="center">
          {user.menu_reportes ?(
            <Button href="/DashboardCXP" className="botonesMenu" color="success">
              <i class="fas fa-chart-bar fa-7x"></i>
              <br />
              <br />
              Dashboard CXP
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-chart-bar fa-7x"></i>
          <br />
          <br />
          Dashboard CXP
        </Button> }
          </Col>

          <Col md={4} align="center">
{user.menu_reportes ?(
  <Button href="/DashboardInventarios" className="botonesMenu" color="success">
    <i class="fas fa-tshirt fa-7x"></i>
    <br />
    <br />
    Dashboard Inventarios
  </Button>
): <Button disabled className="botonesMenu" color="success">
<i class="fas fa-tshirt fa-7x"></i>
<br />
<br />
Dashboard Inventarios
</Button> }
</Col>

</Row>
      </div>
      ) : undefined}
    </>
  );
}

export default BotonesReportes;
