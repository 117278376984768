import React, { useState, useContext } from "react";
import { AuthContext } from '../../../contexts/AuthContext'
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import axios from "axios";

function Password(props) {
  const { user } = useContext(AuthContext);
  const URL_SIGNUP_EDIT = `${process.env.REACT_APP_URL_USERS}/passwordCliente/${props.idColaborador}`;
  const [passwordNuevo, setPasswordNuevo] = useState("");
  const [passwordVerificar, setPasswordVerificar] = useState("");

  function cambiarPassword() {
      if(passwordNuevo == passwordVerificar){
        axios
        .patch(
          URL_SIGNUP_EDIT,
          {
            password: passwordNuevo,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then(() => {
          clear();
          Swal.fire("Good job!", "Creado con exito", "success");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        });
    }else Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "La nueva contraseña no coincide",
      });
      }
    

  const clear = () => {
    setPasswordNuevo("");
    setPasswordVerificar("");
  };

  return (
    <>
      <Label>Password Nuevo</Label>
      <Input
      required
        value={passwordNuevo}
        onChange={(e) => setPasswordNuevo(e.target.value)}
        type="password"
        placeholder="Password Nuevo"
      />
      <Label>Ingresa Nuevamente</Label>
      <Input
        value={passwordVerificar}
        onChange={(e) => setPasswordVerificar(e.target.value)}
        type="password"
        placeholder="Verificar Password"
        required
      />
      <Button onClick={cambiarPassword}>Guardar</Button>
    </>
  );
}

export default Password;
