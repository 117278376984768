import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Col, Row, Button, Form, FormGroup, Label, Input, Modal, ModalHeader, ModalBody } from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";

function ArticulosCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_TIPOS_TELAS = process.env.REACT_APP_URL_TIPOS_TELAS;

  const [codigo, setCodigo] = useState("");
  const [nombre, setNombre] = useState("");
  const [composicion, setComposicion] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [unidad, setUnidad] = useState("Kilos");
  const [ancho, setAncho] = useState(0);
  const [peso, setPeso] = useState(0);
  const [rendimiento, setRendimiento] = useState(0);
  const [tipos_telas, setTiposTelas] = useState([]);
  const [selectedTipoTela, setselectedTipoTela] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [costo, setCosto] = useState("");
  const [venta, setVenta] = useState("");



  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
      .get(URL_TIPOS_TELAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allTiposTelas = res.data;
        setTiposTelas(allTiposTelas);
      })
      .catch((err) => {
        console.log(err);
      });
   
  }, []);




  const saveArticulos = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    try {
      await axios
        .post(
          URL_ARTICULOS,
          {
            codigo,
            nombre,
            composicion,
            descripcion,
            unidad,
            ancho,
            peso,
            rendimiento,
            costo,
            venta,
            tiposTelas: selectedTipoTela,
            observaciones,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )   .then(() => {
                  axios.post(
                    URL_LOGS,
                    {
                      tipo: "Crear Articulo",
                      detalle: `${nombre}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  );
                  Swal.fire("Good job!", "Creado con exito", `success`);
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                  setValidaBoton(true)
                });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };


  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_articulos ? (
        <div className="card container">
          <h3 align="center">Nuevo Articulo</h3>
          <Form onSubmit={saveArticulos}>
            <Row>
            <Col md={3}>
                <Label>Codigo</Label>
                <Input
                  type="text"
                  placeholder="Codigo"
                  value={codigo}
                  required
                  onChange={(e) => {
                    setCodigo(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Nombre</Label>
                <Input
                  type="text"
                  placeholder="Nombre"
                  value={nombre}
                  required
                  onChange={(e) => {
                    setNombre(e.target.value);
                  }}
                />
              </Col>
              
              <Col md={3}>
                <Label>Composicion</Label>
                <Input
                  type="text"
                  placeholder="Composicion"
                  value={composicion}
                  required
                  onChange={(e) => {
                    setComposicion(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Ancho</Label>
                <Input
                  type="number"
                  placeholder="Ancho"
                  value={ancho}
                  required
                  onChange={(e) => {
                    setAncho(e.target.value);
                    if(e.target.value > 0 && peso > 0){ 
                      let a1 = parseFloat(e.target.value) * parseFloat(peso)
                      let a = 1000/a1
                      setRendimiento(a.toFixed(2))
                    }else{
                      setRendimiento(0)
                    }
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col md={3}>
                <Label>Descripcion</Label>
                <Input
                  type="text"
                  placeholder="Descripcion"
                  value={descripcion}
                  required
                  onChange={(e) => {
                    setDescripcion(e.target.value);
                  }}
                />
              </Col>
              
              <Col md={3}>
                <Label>Peso por m2</Label>
                <Input
                  type="number"
                  placeholder="Peso por m2"
                  value={peso}
                  required
                  onChange={(e) => {
                    setPeso(e.target.value);
                    if(e.target.value > 0 && ancho > 0){
                      let a1 = parseFloat(e.target.value) * parseFloat(ancho)
                    let a = 1000/a1
                    setRendimiento(a.toFixed(2))
                    }else{
                      setRendimiento(0)
                    }
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Label>Rendimiento</Label>
                <Input
                  type="text"
                  placeholder="Rendimiento"
                  value={rendimiento}
                  required
                  onChange={(e) => {
                    setRendimiento(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Costo</Label>
                <Input
                  type="text"
                  placeholder="Costo"
                  value={costo}
                  required
                  onChange={(e) => {
                    setCosto(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Venta</Label>
                <Input
                  type="text"
                  placeholder="Venta"
                  value={venta}
                  required
                  onChange={(e) => {
                    setVenta(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Tipo de Tela</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedTipoTela}
                  required
                  onChange={(e) => {
                    setselectedTipoTela(e.target.value);
                  }}
                >
                  <option value="0">Selecciona un Tipo de Tela</option>
                  {tipos_telas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </Col>
             
             
            </Row>
            <Row>
              <Col md={6}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="textarea"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
             
            </Row>
            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <Button
              href="/ListadoArticulos"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Form>
          <br />

        </div>
      ) : undefined}
    </>
  );
}

export default ArticulosCreate;
