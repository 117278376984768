import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import axios from "axios";
import {
  Table,
  Button,
  Row,
  Col,
  ButtonGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Header from "../../layout/Header/Header";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import encode from "nodejs-base64-encode";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { TableHeader, Pagination, Search } from "../../DataTable";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import Baja from "../Baja";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

function ListadoTareas() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_TAREAS = process.env.REACT_APP_URL_TAREAS;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;

  const [tareas, setTareas] = useState([]);
  const [fecha, setFecha] = useState([]);
  const [clienteProv, setClienteProv] = useState([]);
  const [selectedFechaInicio, setSelectedFechaInicio] = useState("");
  const [selectedFechaFin, setSelectedFechaFin] = useState("");
  const [observaciones, setObservaciones] = useState("");
  const [accionFiltro, setAccionFiltro] = useState("");
  const [accion, setAccion] = useState("");
  const [realizado, setRealizado] = useState('');

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);
  const [idEdit, setIdEdit] = useState();
  const [accionEdit, setAccionEdit] = useState("");

  const [value, setValue] = useState("");

  const [text, setText] = useState(false);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const [pagadoAutorizado, setPagadoAutorizado] = useState("");

  const ITEMS_PER_PAGE = 50;

  useEffect(() => {
    axios
      .get(URL_TAREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allTareas = response.data;
        let arrayTabla = allTareas
          .sort((a, b) => (a.fecha < b.fecha ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              fecha: a.fecha,
              observaciones: a.observaciones,
              accion: a.accion,
              realizado: a.is_active,
              clienteProv: a.clienteProv,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setComments(dataFinal);
        setTareas(allTareas);
      })
      .catch((err) => {
        console.log(err);
      });
    
  }, []);

  function jalaInfo(idEdit, fecha, clienteProv, observaciones, accion) {
    setIdEdit(idEdit);
    setFecha(fecha);
    setClienteProv(clienteProv);
    setObservaciones(observaciones);
    setAccion(accion);
    toggleEdit();
  }

  const editTarea = (event) => {
    const URL_TAREAS_EDIT = `${process.env.REACT_APP_URL_TAREAS}/${idEdit}`;
    event.preventDefault();
    axios
      .all([
        axios.patch(
          URL_TAREAS_EDIT,
          {
            fecha,
            clienteProv,
            observaciones,
            accion,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        ),
      ])
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Edit Tarea",
            detalle: `Fecha: ${fecha}  / ClienteProv: ${clienteProv}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        toggleEdit();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  };


  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        (accionFiltro == 0 || accionFiltro == a.accion) &&
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (realizado == 0 || realizado == a.is_active)
      ) {
        return [
          a.fecha,
          a.clienteProv,
          a.accion,
          a.observaciones,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 17, 25);
    doc.text(`Listado Tareas`, 20, 40);
    doc.autoTable({
      head: [["Fecha", "ClienteProv", "Accion", "Observacion"] ],
      body: dataPDFLimpia,
      startY: 45,
    });
    doc.save(`Tareas.pdf`);
  }

  function enviaMail(){
    const logo = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
      const data = comments.map((a) => {
        if (
          (accionFiltro == 0 || accionFiltro == a.accion) &&
          (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
          (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
          (realizado == 0 || realizado == a.is_active)
        ) {
          return [
            a.fecha,
            a.clienteProv,
            a.accion,
            a.observaciones,
          ];
        }
      });
      const dataPDF = Object.values(data);
      const dataPDFLimpia = dataPDF.filter(function (el) {
        return el != null;
      });
      const doc = new jsPDF("landscape");
      var img = new Image();
      img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
      doc.addImage(img, "png", 230, 30, 45, 15);
      var img2 = new Image();
      img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
      doc.addImage(img2, "png", 140, 15, 17, 25);
      doc.text(`Listado Tareas`, 20, 40);
      doc.autoTable({
        head: [["Fecha", "ClienteProv", "Accion", "Observacion"] ],
        body: dataPDFLimpia,
        startY: 45,
      });
    var att = doc.output('arraybuffer');
    var base64File = encode.encode(att, 'base64')
// Envia el Mail
axios
.post(
  URL_SEND_MAIL_ATT,
  {
    subject:'Lista de Tareas',
    email: mailTo,
    fileName: 'ListaTareas.pdf',
    att: base64File,
    text: `<!DOCTYPE html>
    <html>
      <head>
        <style>                                
          .img-container {
                          display: block;
                          margin-left: auto;
                          margin-right: auto;
                          height: 90px;
                          width: auto;
                        }
        </style>
      </head>
      <body>
        <img class="img-container" alt="Logo" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" />              
        <h1>${process.env.REACT_APP_NOMBRE_INSTITUCION}</h1>
        <h3>Hola,</h3>
        <h3>Adjunto encontraras la lista de Gastos.</h3>
        <h4>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</h4>
        Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> 
      </body>
    </html>`
  },
  {
    headers: {
      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    },
  }
)
.then(() => {
 toggleMail()
  Swal.fire("Good job!", "Enviado con exito", "success");
})
.catch((error) => {
  Swal.fire({
    icon: "error",
    title: "Oops...",
    text: "Something went wrong!",
    footer: `${error.response.data}`,
  });
  console.log(error);
})
}
  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (accionFiltro == 0 || accionFiltro == a.accion) &&
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (realizado == 0 || realizado == a.is_active)
      ) {
        return {
          Fecha: a.fecha,
          ClienteProv: a.clienteProv,
          Accion: a.accion,
          Observaciones: a.observaciones,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoTareas";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoTareas",
        sheetFilter: ["Fecha", "ClienteProv", "Accion", "Observaciones"],
        sheetHeader: ["Fecha", "ClienteProv", "Accion", "Observaciones"],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }
  


  const headers = [
    { name: "Fecha", field: "fecha", sortable: false },
    { name: "Cliente Proveedor", field: "clienteProv", sortable: true },
    { name: "Accion", field: "accion", sortable: true },
    { name: "Observaciones", field: "observaciones", sortable: true },
    { name: "Status", field: "status", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.clienteProv.toLowerCase().includes(search.toLowerCase()) ||
          comment.observaciones.toLowerCase().includes(search.toLowerCase()) ||
          comment.accion.toLowerCase().includes(search.toLowerCase())
      );
    }

    if (accionFiltro) {
      computedComments = computedComments.filter((e) =>
        e.accion.includes(accionFiltro)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    if (realizado) {
      computedComments = computedComments.filter((e) =>
        e.realizado.includes(realizado)
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedFechaInicio,
    selectedFechaFin,
    accionFiltro,
    realizado
  ]);


  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_articulos ? (
        <div className="row">
          <div className="col-md-12">
            <div className="card style={{ width: 'auto' }}">
              <div className="card-body">
                <Row>
                  <Col md={7}>
                    {user.menu_articulos ? (
                      <Button
                        size="sm"
                        href="/TareasCreate"
                        className="btn btn-success"
                        id="botonListado"
                      >
                        Crear Tarea
                      </Button>
                    ) : (
                      <Button
                        size="sm"
                        disabled
                        className="btn btn-success"
                        id="botonListado"
                      >
                        Crear Tarea
                      </Button>
                    )}
                    
                  </Col>
                  <Col md={5}>
                      <ButtonGroup id="logoutBoton">
                        <Button
                          size="sm"
                          className="btn"
                          color="danger"
                          onClick={PDFTabla}
                        >
                          PDF <i class="far fa-file-pdf"></i>
                        </Button>
                        <Button size="sm" 
                        className="btn" 
                        color="info"
                        onClick={toggleMail}
                        >
                          eMail <i class="fas fa-at"></i>
                        </Button>
                        <Button
                          size="sm"
                          className="btn"
                          color="primary"
                          onClick={excel}
                        >
                          Excel <i class="far fa-file-excel"></i>
                        </Button>
                      </ButtonGroup>
                    </Col>
                </Row>
                <br />
                <h3 align="center">Tareas</h3>
                <Row>
                  <Col md={2}>
                    <Label>Fecha Inicio</Label>
                    <Input
                      bsSize="sm"
                      type="date"
                      value={selectedFechaInicio}
                      onChange={(e) => {
                        setSelectedFechaInicio(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={2}>
                    <Label>Fecha Fin</Label>
                    <Input
                      bsSize="sm"
                      type="date"
                      value={selectedFechaFin}
                      onChange={(e) => {
                        setSelectedFechaFin(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={2}>
              <Label>Realizado</Label>
              <Input
                bsSize="sm"
                type="select"
                value={realizado}
                required
                onChange={(e) => {
                  setRealizado(e.target.value)
                  setCurrentPage(1)
                }}
              >
                <option value="">Selecciona</option>
                <option value="Si">No</option>
                <option value="No">Si</option>
              </Input>
            </Col>
                </Row>
                <br />
                <div className="row">
                  <div className="col-md-6">
                    <Pagination
                      total={totalItems}
                      itemsPerPage={ITEMS_PER_PAGE}
                      currentPage={currentPage}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  </div>
                  <div className="col-md-6 d-flex flex-row-reverse">
                    <Search
                      onSearch={(value) => {
                        setSearch(value);
                        setCurrentPage(1);
                      }}
                    />
                  </div>
                </div>
                <Table
                  size="sm"
                  striped
                  borderless
                  className="table-responsive-xl"
                >
                  <TableHeader
                    headers={headers}
                    onSorting={(field, order) => setSorting({ field, order })}
                  />
                  <tr>
                    <td></td>
                    <td></td>
                    <td style={{ paddingTop: "0px" }}>
                      <Input
                        bsSize="sm"
                        type="select"
                        value={accionFiltro}
                        required
                        onChange={(e) => {
                          setAccionFiltro(e.target.value);
                          setCurrentPage(1);
                        }}
                      >
                        <option value="">Selecciona</option>
                        <option value="Apartar">Apartar</option>
                        <option value="Entregar">Entregar</option>
                        <option value="Devolver">Devolver</option>
                        <option value="Cortar Muetras">Cortar Muetras</option>
                        <option value="Mandar Muetras">Mandar Muetras</option>
                        <option value="Otro">Otro</option>
                      </Input>
                    </td>
                  </tr>
                  <tbody>
                    {commentsData.map((c) => {
                      return (
                        <tr>
                          <td>{c.fecha}</td>
                          <td>{c.clienteProv}</td>
                          <td>{c.accion}</td>
                          <td>{c.observaciones}</td>

                          <td>
                            {user.menu_articulos ? (
                              <div>
                                <Button
                                  color="info"
                                  id="Editar"
                                  size="sm"
                                  onClick={(e) =>
                                    jalaInfo(
                                      c._id,
                                      c.fecha,
                                      c.clienteProv,
                                      c.observaciones,
                                      c.accion
                                    )
                                  }
                                >
                                  <i class="far fa-edit"></i>
                                </Button>

                                <Baja
                                  idStatus={c._id}
                                  is_active={c.realizado}
                                  URL_BAJA={process.env.REACT_APP_URL_TAREAS}
                                />
                              </div>
                            ) : (
                              <div>
                                <Button
                                  color="info"
                                  id="Editar"
                                  size="sm"
                                  disabled
                                >
                                  <i class="far fa-edit"></i>
                                </Button>
                              </div>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <div className="col-md-6">
                  <Pagination
                    total={totalItems}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
              </div>
            </div>
          </div>

          <Modal size="lg" isOpen={modalEdit} toggle={toggleEdit}>
            <ModalHeader toggle={toggleEdit}>Editar Tarea</ModalHeader>
            <ModalBody>
              <Row>
                <Col sm={6}>
                  <Label for="exampleEmail" className="mr-sm-2">
                    Fecha
                  </Label>
                  <Input
                    type="date"
                    placeholder="Fecha"
                    value={fecha}
                    required
                    onChange={(e) => {
                      setFecha(e.target.value);
                    }}
                  />
                </Col>
                <Col sm={6}>
                  <Label className="mr-sm-2">Cliente Proveedor</Label>
                  <Input
                    type="text"
                    placeholder="clienteProv"
                    value={clienteProv}
                    required
                    onChange={(e) => {
                      setClienteProv(e.target.value);
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Label for="exampleEmail" className="mr-sm-2">
                    Accion
                  </Label>
                  <Input
                    type="select"
                    placeholder="Accion"
                    value={accion}
                    required
                    onChange={(e) => {
                      setAccion(e.target.value);
                    }}
                  >
                    <option value="0">Accion</option>
                    <option value="Llamar">Llamar</option>
                    <option value="Programar">Programar</option>
                    <option value="Revisar">Revisar</option>
                    <option value="Mensaje">Mensaje</option>
                    <option value="Otro">Otro</option>
                  </Input>
                </Col>
                <Col md={6}>
                  <Label for="exampleEmail" className="mr-sm-2">
                    Observaciones
                  </Label>
                  <Input
                    type="text"
                    placeholder="Observaciones"
                    value={observaciones}
                    required
                    onChange={(e) => {
                      setObservaciones(e.target.value);
                    }}
                  />
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button color="success" onClick={editTarea}>
                Guardar
              </Button>
            </ModalFooter>
          </Modal>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
                      <ModalHeader toggle={toggleMail}><h4>Enviar Lista de Tareas</h4></ModalHeader>
                      <ModalBody>
                        <Label className="mr-sm-2">Email</Label>
                        <Input
                          className="col-sm-12"
                          type="text"
                          value={mailTo}
                          required
                          onChange={(e) => {
                            setMailTo(e.target.value);
                          }}
                        />
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          size="sm"
                          color="success"
                          onClick={enviaMail}
                        >
                          Enviar
                        </Button>
                      </ModalFooter>
                    </Modal>
        </div>
      ) : undefined}
      {loader}
    </>
  );
}

export default ListadoTareas;
