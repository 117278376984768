import React, {useState} from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Col,
  Row,
  Table
} from "reactstrap";

function DetalleFacturaInfo(props) {
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    return (
        <div>
             <Button
            color="dany"
            size="sm"
            onClick={toggle}
            id="Detalle"
          >
            <i class="fas fa-file-alt"></i>
          </Button>
             <Modal size="sm" isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}><h4>Descripción de la Nota</h4></ModalHeader>
          <ModalBody>
          <div className="content">
        <div className="card container">
           <Table size="sm" borderless>
             <tbody>
             <tr><td>Nombre</td><td>{props.nombre}</td></tr>
             <tr><td>Telefono</td><td>{props.telefono}</td></tr>
             <tr><td>Mail</td><td>{props.email}</td></tr>
             <tr><td>Nota</td><td>{props.nota}</td></tr>
             <tr><td>Importe</td><td>{props.importe}</td></tr>
             <tr><td>Descripcion</td><td>{props.descripcion}</td></tr>
            </tbody> 
                </Table>
            </div>
            </div>
            </ModalBody>
          <ModalFooter>
            <Button color="success" onClick={toggle}>
              Cerrar
            </Button>
          </ModalFooter>
        </Modal>
            
        </div>
    )
}

export default DetalleFacturaInfo
