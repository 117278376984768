import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import { v4 as uuidv4 } from "uuid";

function ListadoArticulos() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_TIPOS_TELAS = process.env.REACT_APP_URL_TIPOS_TELAS;
  const URL_COLORES = process.env.REACT_APP_URL_COLORES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_FORMULAS = process.env.REACT_APP_URL_FORMULAS;
  const URL_INSUMOS = process.env.REACT_APP_URL_INSUMOS;

  const [tipos_telas, setTiposTelas] = useState([]);
  const [selectedTiposTelasFiltro, setSelectedTiposTelasFiltro] = useState("");
  const [colores, setColores] = useState([]);

  const [idEditArticulo, setIdEditArticulo] = useState("");
  const [selectedTipoTelaEdit, setSelectedTipoTelaEdit] = useState("");
  const [codigo, setCodigo] = useState("");
  const [nombre, setNombre] = useState("");
  const [composicion, setComposicion] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [unidad, setUnidad] = useState("");
  const [ancho, setAncho] = useState("");
  const [peso, setPeso] = useState("");
  const [rendimiento, setRendimiento] = useState("");
  const [observaciones, setObservaciones] = useState("");
  const [costo, setCosto] = useState("");
  const [venta, setVenta] = useState("");

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [idColoresArticulo, setIdColoresArticulo] = useState("");
  const [modalColores, setModalColores] = useState(false);
  const toggleColores = () => setModalColores(!modalColores);
  const [arrayColores, setArrayColores] = useState([]);

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);
  const [activo, setActivo] = useState("");

  const [modalFoto, setModalFoto] = useState(false);
  const toggleFoto = () => setModalFoto(!modalFoto);

  const [file, setFile] = useState();
  const [photo, setPhoto] = useState();
  const URL_FILEPOST = process.env.REACT_APP_URL_UPPROFILE;

  const [modalFormula, setModalFormula] = useState(false);
  const toggleFormula = () => setModalFormula(!modalFormula);
  const [formulaExistentes, setFormulaExistentes] = useState([]);
  const [codigoFormula, setCodigoFormula] = useState("");
  const [idFormula, setIdFormula] = useState("");
  const [insumos, setInsumos] = useState([]);

  const [validaBoton, setValidaBoton] = useState(true);
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      idInsumo: "",
      cantidad:0
    },
  ]);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useEffect(() => {
    axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulos = response.data;
        // Array para pagination
        let arrayTabla = allArticulos
          .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              activo: a.is_active,
              codigo: a.codigo,
              nombre: a.nombre,
              composicion: a.composicion,
              descripcion: a.descripcion,
              unidad: a.unidad,
              ancho: a.ancho,
              peso: a.peso,
              rendimiento: a.rendimiento,
              idTiposTelas: a.tiposTelas[0]._id,
              tiposTelas: a.tiposTelas[0].name,
              costo: a.costo,
              venta: a.venta,
              observaciones: a.observaciones,
              colores: a.colores,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_TIPOS_TELAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allTiposTelas = response.data;
        setTiposTelas(allTiposTelas);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(URL_INSUMOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allInsumos = response.data;
        setInsumos(allInsumos);
      })
      .catch((err) => {
        console.log(err);
      });


    axios
      .get(URL_COLORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allColores = response.data;
        setColores(allColores);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const ListaArticulos = comments.sort((a, b) =>
    a.nombre > b.nombre ? 1 : -1
  );

  function PDFTabla() {
    const data = ListaArticulos.map((a) => {
      if (
        // (selectedTiposTelasFiltro == 0 || selectedTiposTelasFiltro == a.grupos[0]._id) &&
        activo == 0 ||
        activo == a.is_active
      ) {
        return [
          a.codigo,
          a.nombre,
          a.composicion,
          a.unidad,
          a.ancho,
          a.peso,
          a.rendimiento,
          a.tiposTelas,
          "$" + new Intl.NumberFormat("en-US").format(a.costo),
          "$" + new Intl.NumberFormat("en-US").format(a.venta),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado Articulos`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Codigo",
          "Nombre",
          "Composicion",
          "Unidad",
          "Ancho",
          "Peso",
          "Rendimiento",
          "Tipo de Tela",
          "Costo",
          "Venta",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    doc.save(`Articulos.pdf`);
  }

  function excel() {
    const dataExcel = ListaArticulos.map((a) => {
      if (activo == 0 || activo == a.is_active) {
        return {
          Codigo: a.codigo,
          Nombre: a.nombre,
          Composicion: a.composicion,
          Unidad: a.unidad,
          Ancho: a.ancho,
          Peso: a.peso,
          Rendimiento: a.rendimiento,
          Tipo: a.tiposTelas,
          Costo: a.costo,
          Venta: a.venta,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoArticulos";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoArticulos",
        sheetFilter: [
          "Codigo",
          "Nombre",
          "Composicion",
          "Unidad",
          "Ancho",
          "Peso",
          "Rendimiento",
          "Tipo",
          "Costo",
          "Venta",
        ],
        sheetHeader: [
          "Codigo",
          "Nombre",
          "Composicion",
          "Unidad",
          "Ancho",
          "Peso",
          "Rendimiento",
          "Tipo",
          "Costo",
          "Venta",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = ListaArticulos.map((a) => {
      if (
        // (selectedTiposTelasFiltro == 0 || selectedTiposTelasFiltro == a.grupos[0]._id) &&
        activo == 0 ||
        activo == a.is_active
      ) {
        return [
          a.codigo,
          a.nombre,
          a.composicion,
          a.unidad,
          a.ancho,
          a.peso,
          a.rendimiento,
          a.tiposTelas,
          "$" + new Intl.NumberFormat("en-US").format(a.costo),
          "$" + new Intl.NumberFormat("en-US").format(a.venta),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado Articulos`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Codigo",
          "Nombre",
          "Composicion",
          "Unidad",
          "Ancho",
          "Peso",
          "Rendimiento",
          "Tipo de Tela",
          "Costo",
          "Venta",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Articulos",
          email: mailTo,
          fileName: "ListadoArticulos.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Articulos.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Enviado con Exito!",
          showConfirmButton: false,
          timer: 2000,
        });
        setMailTo("")
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function jalaFoto(
    idEdit
  ) {
    setPhoto("")
    const URL_GET_MEDIA = `${process.env.REACT_APP_URL_GETMEDIA}/${idEdit}`;
    axios
      .get(URL_GET_MEDIA, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((data) => {
        if (data.data.version) {
          setPhoto(data.data.version);
        }
      })
      .catch((err) => console.log(err));


    setIdEditArticulo(idEdit);
    toggleFoto();
  }


  function jalaInfo(
    codigo,
    nombre,
    composicion,
    unidad,
    ancho,
    descripcion,
    peso,
    rendimiento,
    idTiposTelas,
    costo,
    venta,
    observaciones,
    idEdit
  ) {

    setCodigo(codigo);
    setNombre(nombre);
    setComposicion(composicion);
    setUnidad(unidad);
    setAncho(ancho);
    setDescripcion(descripcion);
    setPeso(peso);
    setRendimiento(rendimiento);
    setSelectedTipoTelaEdit(idTiposTelas);
    setCosto(costo);
    setVenta(venta);
    setObservaciones(observaciones);
    setIdEditArticulo(idEdit);
    toggleEdit();
  }

  function editArticulos(event) {
    event.preventDefault();
    const URL_ARTICULOS_EDIT = `${process.env.REACT_APP_URL_ARTICULOS}/${idEditArticulo}`;
    if (file == null) {

          axios.patch(
            URL_ARTICULOS_EDIT,
            {
              codigo,
              nombre,
              composicion,
              unidad,
              ancho,
              peso,
              rendimiento,
              tiposTelas: selectedTipoTelaEdit,
              costo,
              venta,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
        .then(() => {
          axios.post(
            URL_LOGS,
            {
              tipo: "Editar Articulo",
              detalle: `${nombre} ${codigo}`,
              user: user.id,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          );

          toggleEdit();
          Swal.fire("Good job!", "Actualizado con exito", "success");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        });
    } else {
          axios.patch(
            URL_ARTICULOS_EDIT,
            {
              codigo,
              nombre,
              composicion,
              unidad,
              ancho,
              peso,
              rendimiento,
              tiposTelas: selectedTipoTelaEdit,
              costo,
              venta,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )

        .then(() => {
          const formData = new FormData();
          formData.append("file", file);
          formData.append("articulo", idEditArticulo);

          axios
            .post(URL_FILEPOST, formData, {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            })
            .then(() => {
              axios.post(
                URL_LOGS,
                {
                  tipo: "Editar Articulo",
                  detalle: `${nombre} ${codigo}`,
                  user: user.id,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              );

              toggleEdit();
              Swal.fire("Good job!", "Actualizado con exito", "success");
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `${error.response.data}`,
              });
              console.log(error);
            });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
          console.log(error);
        });
    }
  }

  const headers = [
    { name: "Activo", field: "activo", sortable: true },
    { name: "Codigo", field: "codigo", sortable: false },
    { name: "Nombre", field: "nombre", sortable: true },
    { name: "Composicion", field: "composicion", sortable: true },
    { name: "Unidad", field: "unidad", sortable: true },
    { name: "Ancho", field: "ancho", sortable: false },
    { name: "Peso", field: "peso", sortable: false },
    { name: "Tipo de Tela", field: "tiposTelas", sortable: true },
    { name: "Venta", field: "venta", sortable: false },
    { name: "Acciones", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
          comment.composicion.toLowerCase().includes(search.toLowerCase()) ||
          comment.unidad.toLowerCase().includes(search.toLowerCase()) ||
          comment.tiposTelas.toLowerCase().includes(search.toLowerCase()) ||
          comment.codigo.toString().includes(search)
      );
    }
    if (selectedTiposTelasFiltro) {
      computedComments = computedComments.filter((e) =>
        e.idTiposTelas.includes(selectedTiposTelasFiltro)
      );
    }
    if (activo) {
      computedComments = computedComments.filter((e) =>
        e.activo.includes(activo)
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedTiposTelasFiltro,
    activo,
  ]);

  function coloresInfo(idColores, codigo, nombre, coloresInfo) {
    let ids = coloresInfo.map(function (e) {
      return e._id;
    });
    let coloresDany = Object.values(ids);

    setIdColoresArticulo(idColores);
    setCodigo(codigo);
    setNombre(nombre);
    setArrayColores(coloresDany);
    toggleColores();
  }

  function coloresSeleccionados(checked, idColor) {
    if (checked) {
      arrayColores.push(idColor);
      console.log(arrayColores);
    } else {
      const index = arrayColores.indexOf(idColor);
      if (index > -1) {
        arrayColores.splice(index, 1);
        console.log(arrayColores);
      }
    }
  }

  function guardarColores() {
    const URL_ARTICULOS_COLORES = `${process.env.REACT_APP_URL_ARTICULOS}/${idColoresArticulo}`;
    axios
      .patch(
        URL_ARTICULOS_COLORES,
        {
          colores: arrayColores,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Colores Articulo",
            detalle: `${nombre} ${codigo}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        Swal.fire("Good job!", "Actualizado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  const handleChangeInputExistente = (id, event) => {
    const newFormulaExistentes = formulaExistentes.map((i) => {
      if (id === i._id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setFormulaExistentes(newFormulaExistentes);
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFields(newInputFields);
  };

  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        idInsumo: "",
        cantidad:0
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
  };

  function jalaFormula(id, codigo){
    setIdFormula(id)
    setCodigoFormula(codigo)

    axios
    .get(`${URL_FORMULAS}Articulo/${id}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((res) => {
      const allFormulaExistentes = res.data;
      setFormulaExistentes(allFormulaExistentes);
    })
    .catch((err) => {
      console.log(err);
    });

    toggleFormula()

  }

  function saveformula(){
    setValidaBoton(false);
    toggleProgreso();
  
  let totalFormula =  inputFields.length + formulaExistentes.length
  
    inputFields.map((a)=>{
      if(a.idInsumo!="" & a.cantidad > 0){
      axios.post(
        URL_FORMULAS,
        {
          articulos: idFormula,
          insumos: a.idInsumo,
          cantidad: a.cantidad,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      ).then(()=>{
        totalFormula = totalFormula -1
        if(totalFormula == 0 ){
          axios.post(
            URL_LOGS,
            {
              tipo: "Agregar Formula",
              detalle: `${idFormula} ${a.idInsumo}`,
              user: user.id,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
            .then(()=>{
              Swal.fire("Good job!", "Creado con exito", `success`);
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            })
        }
      })
    }else{
      totalFormula = totalFormula -1
    }
    })
  
  
    formulaExistentes.map((a)=>{
      axios.patch(
        `${URL_FORMULAS}/${a._id}`,
        {
          cantidad: a.cantidad,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      ).then(()=>{
        totalFormula = totalFormula -1
        if(totalFormula == 0 ){
          axios.post(
            URL_LOGS,
            {
              tipo: "Editar Formula",
              detalle: `${idFormula}`,
              user: user.id,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
            .then(()=>{
              Swal.fire("Good job!", "Creado con exito", `success`);
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            })
        }
      })
    })
  
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_articulos ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              {user.articulos_create ? (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/ArticulosCreate"
                >
                  Nuevo Articulo
                </Button>
              ) : (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  Nuevo Articulo
                </Button>
              )}
              <Button
                size="sm"
                href="/MenuArticulos"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Articulos</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Articulos</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label>Tipo de Tela</Label>
              <Input
                bsSize="sm"
                type="select"
                value={selectedTiposTelasFiltro}
                onChange={(e) => {
                  setSelectedTiposTelasFiltro(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                {tipos_telas
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col>

            <Col md={2}>
              <Label>Activo</Label>
              <Input
                bsSize="sm"
                type="select"
                value={activo}
                required
                onChange={(e) => {
                  setActivo(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                <option value="Si">Activos</option>
                <option value="No">Inactivos</option>
              </Input>
            </Col>
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData.map((a) => {
                {
                  return (
                    <tr>
                      <td>{a.activo}</td>
                      <td>{a.codigo}</td>
                      <td>{a.nombre}</td>
                      <td>{a.composicion}</td>
                      <td>{a.unidad}</td>
                      <td>{a.ancho}</td>
                      <td>{a.peso}</td>
                      <td>{a.tiposTelas} </td>
                      <td>
                        {"$" + new Intl.NumberFormat("en-US").format(a.venta)}{" "}
                      </td>
                      <td>
                        {user.articulos_create ? (
                          <div>
                            <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                jalaFoto(
                                  a._id
                                )
                              }
                            >
                              <i class="fas fa-camera"></i>
                            </Button>

                           
                            <Button
                              color="danger"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                jalaFormula(
                                  a._id,
                                  a.codigo
                                )
                              }
                            >
                              <i class="fas fa-vial"></i>
                            </Button>
                            <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                jalaInfo(
                                  a.codigo,
                                  a.nombre,
                                  a.composicion,
                                  a.unidad,
                                  a.ancho,
                                  a.descripcion,
                                  a.peso,
                                  a.rendimiento,
                                  a.idTiposTelas,
                                  a.costo,
                                  a.venta,
                                  a.observaciones,
                                  a._id
                                )
                              }
                            >
                              <i class="far fa-edit"></i>
                            </Button>
                            <Baja
                              idStatus={a._id}
                              is_active={a.activo}
                              URL_BAJA={process.env.REACT_APP_URL_ARTICULOS}
                            />
                          </div>
                        ) : (
                          <div>
                            <Button color="info" id="Editar" size="sm" disabled>
                              <i class="far fa-edit"></i>
                            </Button>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

      <Modal size="lg" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>
          <h4>
            Editar articulo {codigo} / {nombre}
          </h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={3}>
              <Label>Codigo</Label>
              <Input
                type="text"
                placeholder="Codigo"
                value={codigo}
                required
                onChange={(e) => {
                  setCodigo(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Nombre</Label>
              <Input
                type="text"
                placeholder="Nombre"
                value={nombre}
                required
                onChange={(e) => {
                  setNombre(e.target.value);
                }}
              />
            </Col>

            <Col md={3}>
              <Label>Composicion</Label>
              <Input
                type="text"
                placeholder="Composicion"
                value={composicion}
                required
                onChange={(e) => {
                  setComposicion(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Ancho</Label>
              <Input
                type="number"
                placeholder="Ancho"
                value={ancho}
                required
                onChange={(e) => {
                  setAncho(e.target.value);
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col md={3}>
              <Label>Descripcion</Label>
              <Input
                type="text"
                placeholder="Descripcion"
                value={descripcion}
                required
                onChange={(e) => {
                  setDescripcion(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Unidad</Label>
              <Input
                type="select"
                placeholder="Unidad"
                value={unidad}
                required
                onChange={(e) => {
                  setUnidad(e.target.value);
                }}
              >
                <option value="0">Unidad</option>
                <option value="Metros">Metros</option>
                <option value="Kilos">Kilos</option>
              </Input>
            </Col>
            <Col md={3}>
              <Label>Peso por m2</Label>
              <Input
                type="number"
                placeholder="Peso por m2"
                value={peso}
                required
                onChange={(e) => {
                  setPeso(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Label>Rendimiento</Label>
              <Input
                type="number"
                placeholder="Rendimiento"
                value={rendimiento}
                required
                onChange={(e) => {
                  setRendimiento(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Costo</Label>
              <Input
                type="number"
                placeholder="Costo"
                value={costo}
                required
                onChange={(e) => {
                  setCosto(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Venta</Label>
              <Input
                type="number"
                placeholder="Venta"
                value={venta}
                required
                onChange={(e) => {
                  setVenta(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label className="mr-sm-2">Tipo de Tela</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedTipoTelaEdit}
                required
                onChange={(e) => {
                  setSelectedTipoTelaEdit(e.target.value);
                }}
              >
                <option value="0">Selecciona un Tipo de Tela</option>
                {tipos_telas
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Label>
                Observaciones
              </Label>
              <Input
                type="textarea"
                placeholder="Observaciones"
                value={observaciones}
                required
                onChange={(e) => {
                  setObservaciones(e.target.value);
                }}
              />
            </Col>
            <Col md={6}>
              <Label>
                Foto
              </Label>
              <Input
                type="file"
                onChange={(e) => {
                  setFile(e.target.files[0]);
                }}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editArticulos}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>




          <Modal size="sm" isOpen={modalFoto} toggle={toggleFoto}>
        <ModalHeader toggle={toggleFoto}>
          <h4>
            Foto del Articulo
          </h4>
        </ModalHeader>
        <ModalBody>
        <Row>
            {photo ? (
              <h6 style={{ textAlign: "center" }}>
                <img
                  // loading="lazy"
                  src={`${process.env.REACT_APP_URL_FOTO_CLOUDINARY}/image/upload/v${photo}/bloomArticulos/${idEditArticulo}.jpg`}
                  alt=""
                />
              </h6>
            ) : <h4>No se ha subido una foto del Articulo....</h4>}
          </Row>
        </ModalBody>
      </Modal>

      <Modal size="lg" isOpen={modalColores} toggle={toggleColores}>
        <ModalHeader toggle={toggleColores}>
          <h4>
            Colores del articulo {codigo} / {nombre}
          </h4>
        </ModalHeader>
        <ModalBody>
          {colores
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((a, index) => {
              if (!arrayColores.includes(a._id)) {
                return (
                  <table>
                    <td>
                      <Input
                        type="checkbox"
                        key={index}
                        onChange={(e) => {
                          coloresSeleccionados(e.currentTarget.checked, a._id);
                        }}
                        className="subMenu"
                      />
                      <h5 className="subMenuTitulo">{a.name}</h5>
                    </td>
                  </table>
                );
              } else {
                return <h5 className="subMenuTitulo">{a.name}</h5>;
              }
            })}
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={guardarColores}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="sm" isOpen={modalFormula} toggle={toggleFormula}>
        <ModalHeader toggle={toggleFormula}>
          <h4>
            Formula Modelo {codigoFormula}
          </h4>
        </ModalHeader>
        <ModalBody>

        <Row>
            <Col md={6}>
            <Label>Insumo</Label>
            </Col>
            <Col md={3}>
            <Label>Cantidad</Label>
            </Col>
          </Row>

          {formulaExistentes.map((a)=>(
            <div key={a._id}>
              <Row>
              <Col md={6}>
                      <Input
                        bsSize="sm"
                        name="insumos"
                        type="select"
                        value={a.insumos[0]._id}
                        required
                        disabled
                      >
                        <option value="">Selecciona</option>
                        {insumos
                          .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                          .map((a) => {
                            return (
                              <option value={a._id}>
                                {a.codigo} / {a.nombre}
                              </option>
                            );
                          })}
                      </Input>
                    </Col>
                    <Col md={3}>
                        <Input
                         bsSize="sm"
                          name="cantidad"
                          type="number"
                          value={a.cantidad}
                          onChange={(event)=>{handleChangeInputExistente(a._id, event)}}
                        >
                        </Input>
                      </Col>
              </Row>
            </div>
          ))}
             {inputFields.map((a)=>(
            <div key={a.id}>
              <Row>
              <Col md={6}>
                      <Input
                        bsSize="sm"
                        name="idInsumo"
                        type="select"
                        value={a.idInsumo}
                        required
                        onChange={(event)=>{handleChangeInput(a.id, event)}}
                      >
                        <option value="">Selecciona</option>
                        {insumos
                          .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                          .map((a) => {
                            return (
                              <option value={a._id}>
                                {a.codigo} / {a.nombre}
                              </option>
                            );
                          })}
                      </Input>
                    </Col>
                    <Col md={3}>
                        <Input
                         bsSize="sm"
                          name="cantidad"
                          type="number"
                          value={a.cantidad}
                          onChange={(event)=>{handleChangeInput(a.id, event)}}
                        >
                        </Input>
                      </Col>
                      <Col>
                      <Button
                        size="sm"
                        className="btn"
                        color="danger"
                        disabled={inputFields.length === 1}
                        onClick={() => handleRemoveFields(a.id)}
                        tabindex="-1"
                      >
                        <i class="fas fa-minus"></i>
                      </Button>
                      <Button 
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1">
                      <i class="fas fa-plus"></i>
                      </Button>
                    </Col>
              </Row>
            </div>
          ))}

        </ModalBody>
        <ModalFooter>
        {validaBoton ? (
              <Button type="submit" className="btn btn-success"  onClick={saveformula}>
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
        </ModalFooter>
      </Modal>

      <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios minutos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>
      {loader}
    </>
  );
}

export default ListadoArticulos;
