import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Button, Form, FormGroup, Label, Input, Row } from "reactstrap";
import axios from "axios";

import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";

function DepartamentosCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_DEPARTAMENTOS = process.env.REACT_APP_URL_DEPARTAMENTOS;
  const [name, setName] = useState("");
  const [text, setText] = useState(false);
  const [validaBoton, setValidaBoton] = useState(true);

  const clear = () => {
    setName("");
    setValidaBoton(true);
  };

  const saveDepartamentos = (event) => {
    event.preventDefault();
    setValidaBoton(false);
    axios
      .post(
        URL_DEPARTAMENTOS,
        {
          name,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Crear Departamento",
            detalle: name,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        clear();
        Swal.fire("Good job!", "Creado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  };

  return (
    <>
      <div className="container">
        <h3 align="center">Crea un nuevo Departamento</h3>
        <Form inline onSubmit={saveDepartamentos}>
          <Label for="exampleEmail" className="mr-sm-2">
            Departamento
          </Label>
          <Input
            className="col-sm-6"
            type="text"
            placeholder="Nombre del departamento"
            value={name}
            required
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <br />
          <Row>
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <div>
              <SweetAlert
                show={text}
                title="Creado con Exito"
                // text="SweetAlert in React"
                onConfirm={() => {
                  setText(false);
                }}
              />
            </div>
            <Button
              href="/MenuCatalogos"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Row>
        </Form>
      </div>
    </>
  );
}

export default DepartamentosCreate;
